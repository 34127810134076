import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DateTime } from 'luxon';
import { BsDatepickerConfig, BsDatepickerViewMode } from 'ngx-bootstrap/datepicker';
import { storageKey } from 'libs/core/src/app.const';
import { compareDateTime } from 'libs/core/src/lib/date/date.helper';
import { StateService } from 'libs/core/src/lib/state/state.service';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
})
export class DatePickerComponent implements OnInit {
  @Input() date: DateTime = DateTime.local();
  @Input() daysAhead = false;
  @Input() format = 'DATE_SHORT_DMY';
  @Input() step = 1;
  @Input() label: string = null;
  @Input() relative = false; // if true it can show filter.date.toRelativeCalendar()
  @Input() minMode: BsDatepickerViewMode = 'day';
  @Input() withTimepicker = false;
  @Input() mode: 'div' | 'input' = 'div';
  @Input() allowChangeDate = true;
  @Output() public dateChanged = new EventEmitter<DateTime>();
  @Output() public dateIncreased = new EventEmitter<DateTime>();
  @Output() public dateDecreased = new EventEmitter<DateTime>();

  minDate: Date;
  maxDate: Date;
  now: DateTime;
  bsDatepickerValue: Date;
  language: string;
  bsConfig: Partial<BsDatepickerConfig>;

  constructor(private stateSetvice: StateService) {
    this.now = DateTime.local().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    this.minDate = new Date();
    this.minDate.setHours(0, 0, 0, 0);
  }

  ngOnInit(): void {
    this.bsConfig = Object.assign(
      new BsDatepickerConfig(),
      {
        containerClass: 'blue-datepicker',
        showWeekNumbers: false,
        customTodayClass: 'today',
        isAnimated: true,
        minMode: this.minMode,
        adaptivePosition: true,
        selectFromOtherMonth: true,
      },
      this.withTimepicker ? { withTimepicker: true, keepDatepickerOpened: true, dateInputFormat: 'DD.MM.YYYY, hh:mm' } : {}
    );

    this.bsDatepickerValue = this.date.toJSDate();

    if (this.daysAhead) {
      this.bsConfig = Object.assign(this.bsConfig, {
        minDate: this.minDate,
      });
    }

    this.language = this.stateSetvice.getItem(storageKey.lang);
  }

  dateIncrease() {
    let date: DateTime;
    switch (this.minMode) {
      case 'day':
        date = this.date.plus({ days: this.step });
        break;
      case 'month':
        date = this.date.plus({ months: 1 });
        break;
    }

    this.setDate(date);
    this.dateIncreased.emit(date);
  }

  dateDecrease() {
    let date: DateTime;
    switch (this.minMode) {
      case 'day':
        date = this.date.minus({ days: this.step });
        break;
      case 'month':
        date = this.date.minus({ months: 1 });
        break;
    }

    if (!this.canPrev(date)) {
      this.setDate(date);
      this.dateDecreased.emit(date);
    }
  }

  onDateChange(date: DateTime) {
    this.setDate(date);
    this.dateChanged.emit(date);
  }

  onDatepickerValueChange(event: Date) {
    if (event.getTime() !== this.date.toJSDate().getTime()) {
      const date = DateTime.fromJSDate(event);
      this.setDate(date);
      this.dateChanged.emit(date);
    }
  }

  private setDate(date: DateTime) {
    if (this.canPrev(date)) {
      return;
    }

    this.date = date;
    this.bsDatepickerValue = this.date.toJSDate();
  }

  public canPrev(date: DateTime): boolean {
    return this.minMode !== 'month' && compareDateTime(this.now, date) === 1;
  }

  get canShowRelative() {
    return this.relative && this.now > this.date.minus({ days: 2 });
  }
}
