<div class="row pay-method-container d-block">
  <div class="col-12 px-0 px-sm-3 d-flex flex-column">
    <div class="row d-block card-pay-method-container" *ngIf="getCardPayMethod() as cardPayMethod">
      <div class="col-12 pay-method-panel f-roboto">
        <app-payment-method [paymentProvider]="paymentProviderIdentifier" [paymentType]="paymentMethod.TYPE_CARD"
                            [paymentMethod]="cardPayMethod"
                            [selected]="selectedPayMethodTypeCustom === paymentMethod.TYPE_CARD"
                            [paymentImgUrl]="cardPayMethod.imageUrl">
          <div class="panel-body mt-4" *ngIf="selectedPayMethodTypeCustom === paymentMethod.TYPE_CARD">

            <div class="card-container d-flex flex-column gap-1">
              <ng-container *ngFor="let payMethod of cardMethodCollection">
                <div class="card active" [ngClass]="{ 'card-selected': selectedCardMethod === payMethod }"
                     (click)="onClickedCardMethod(payMethod)">
                  <img [src]="payMethod.imageUrl" />
                  <p>{{ payMethod.cardBrand }}</p>
                  <p>{{ payMethod.cardMask }}</p>
                  <p>{{ payMethod.cardExpirationMonth }}/{{ payMethod.cardExpirationYear }}</p>
                </div>
              </ng-container>
            </div>

            <div class="card-form mt-3" [class.hidden]="selectedCardMethod">
              <div [formGroup]="cardFormGroup" class="row flex-column d-block">
                <div class="col">
                  <input type="text" mask="0000 0000 0000 0000" placeholder="Numer karty" formControlName="cardNumber"
                         class="form-control mb-2">
                </div>
                <div class="col">
                  <div class="row">
                    <div class="col-7">
                      <input (change)="splitExpirationDate()" type="text" placeholder="Data ważności" mask="00/0099"
                             formControlName="cardExpirationDate" class="form-control payu-card-exp" />
                    </div>
                    <div class="col-5">
                      <input type="text" placeholder="CVV/CVV2" mask="0009" formControlName="cardCVV"
                             class="form-control" />
                    </div>
                  </div>
                </div>
                <div class="col" *ngIf="canDisplayErrorGroup">
                  <div *ngIf="canDisplayError('cardNumber')" class="invalid-feedback d-inline-block">Numer karty musi
                    zawierać 16 znaków</div>
                  <div *ngIf="canDisplayError('cardCVV')" class="invalid-feedback d-inline-block">CVV musi zawierać 3-4
                    znaki</div>
                  <div *ngIf="canDisplayError('cardExpirationDate')" class="invalid-feedback d-inline-block">
                    Wprowadzono błędną datę</div>
                </div>
              </div>
            </div>
          </div>
        </app-payment-method>
      </div>
    </div>
    <div style="visibility: hidden; height: 1px;">
      <input type="text" class="payu-card-number" [(ngModel)]="cardModel.cardNumber">
      <input type="text" class="payu-card-cvv" [(ngModel)]="cardModel.cardCVV">
      <input type="text" class="payu-card-expm" [(ngModel)]="cardModel.cardExpirationMonth">
      <input type="text" class="payu-card-expy" [(ngModel)]="cardModel.cardExpirationYear">
    </div>
    <div class="row d-block blick-pay-method-container" *ngIf="getBLIKPayMethod() as blikPayMethod">
      <div class="col-12 pay-method-panel f-roboto">
        <app-payment-method [paymentMethod]="blikPayMethod" [paymentType]="paymentMethod.TYPE_BLIK"
                            [paymentProvider]="paymentProviderIdentifier"
                            [selected]="selectedPayMethodTypeCustom === paymentMethod.TYPE_BLIK"
                            [paymentImgUrl]="blikPayMethod.imageUrl">
          <div class="panel-body" *ngIf="selectedPayMethodTypeCustom === paymentMethod.TYPE_BLIK">
            <div class="t6-container">

              <div class="form-group mb-0 required row d-block" [formGroup]="form">
                <div class="col-6 ml-3">
                  <input type="text" class="form-control" id="blik_t6" data-charset="_X_ X_X" placeholder="___ ___"
                         formControlName="blikCode" placeholder="KOD BLIK" matInput mask="000000" />
                  <ng-container *ngIf="(form.touched && form.dirty) || formSubmitAttempt">
                    <span *ngIf="!form.get('blikCode').valid && !form.get('blikCode')?.errors?.minLength === true"
                          class="invalid-feedback d-inline-block">BLIK musi mieć 6 znaków</span>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </app-payment-method>
      </div>
    </div>
    <div class="row d-block link-pay-method-container"
         *ngIf="payByLinkMethodCollection && payByLinkMethodCollection.length > 0">
      <div class="col-12 pay-method-panel f-roboto">
        <app-payment-method [paymentType]="paymentMethod.TYPE_PBL" [paymentProvider]="paymentProviderIdentifier"
                            [paymentImgUrl]="getDefaultPaymentImageUrl"
                            [selected]="selectedPayMethodTypeCustom === paymentMethod.TYPE_PBL">
          <div class="panel-body" *ngIf="selectedPayMethodTypeCustom === paymentMethod.TYPE_PBL">
            <div class="pay-by-link-container mt-3">
              <div class="pay-by-link" [ngClass]="{ 'selected': selectedPayByLinkMethod === payMethod }"
                   *ngFor="let payMethod of payByLinkMethodCollection" (click)="onClickedPayByLinkMethod(payMethod)">
                <img [src]="payMethod.imageUrl" />
              </div>
            </div>
          </div>
        </app-payment-method>
      </div>
    </div>
    <div class="row d-block google-pay-method-container" *ngIf="getGooglePayPayMethod() as googlePayMethod">
      <div class="col-12 pay-method-panel f-roboto">
        <app-payment-method [paymentMethod]="googlePayMethod" [paymentType]="paymentMethod.TYPE_GPAY"
                            [paymentProvider]="paymentProviderIdentifier"
                            [selected]="selectedPayMethodTypeCustom === paymentMethod.TYPE_GPAY"
                            [paymentImgUrl]="googlePayMethod.imageUrl">
          <div class="panel-body" *ngIf="selectedPayMethodTypeCustom === paymentMethod.TYPE_GPAY">
            <div class="t6-container">
            </div>
          </div>
        </app-payment-method>
      </div>
    </div>
    <div class="row d-block apple-pay-method-container" *ngIf="getApplePayPayMethod() as applePayMethod">
      <div class="col-12 pay-method-panel f-roboto">
        <app-payment-method [paymentMethod]="applePayMethod" [paymentProvider]="paymentProviderIdentifier"
                            [selected]="selectedPayMethodTypeCustom === paymentMethod.TYPE_APAY"
                            [paymentType]="paymentMethod.TYPE_APAY" [paymentImgUrl]="applePayMethod.imageUrl">
          <div class="panel-body" *ngIf="selectedPayMethodTypeCustom === paymentMethod.TYPE_APAY">
            <div class="t6-container">
            </div>
          </div>
        </app-payment-method>
      </div>
    </div>
  </div>
</div>