export class CreatePaymentRequestModel {
  channel: string;
  continueUrl: string;
  intPayMethodType: string;
  intPayMethodValue: string;
  saveToken: boolean;
  paymentToken: string;
  expiry: string;
  sdkVersion: string;
  regulationAccept: boolean;

  constructor(data?: Partial<CreatePaymentRequestModel>) {
    this.channel = data?.channel || '';
    this.continueUrl = data?.continueUrl || '';
    this.intPayMethodType = data?.intPayMethodType || '';
    this.intPayMethodValue = data?.intPayMethodValue || '';
    this.saveToken = data?.saveToken ?? false;
    this.paymentToken = data?.paymentToken || '';
    this.expiry = data?.expiry || '';
    this.sdkVersion = data?.sdkVersion || '';
    this.regulationAccept = data?.regulationAccept ?? false;
  }
}
