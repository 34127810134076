import { Inject, Injectable, inject } from '@angular/core';
import { ENVIRONMENT_TOKEN } from '../../injection.tokens';

declare let gtag: Function;

@Injectable({
  providedIn: 'root',
})
export class GTagServiceCore {
  constructor(@Inject(ENVIRONMENT_TOKEN) protected environment: any) {}

  protected isConfigured(): boolean {
    return (document.getElementById('gtag_script') ? true : false) && typeof gtag !== 'undefined';
  }
}
