import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { AppService } from '@lib/core';

@Directive({
  selector: '[appReplaceClass]',
})
export class ReplaceClassDirective implements OnInit {
  @Input() targetClass: string;
  @Input() newClass: string;
  @Input() forProjects: string[];

  constructor(private el: ElementRef, private renderer: Renderer2, protected appService: AppService) {}

  ngOnInit() {
    if (this.forProjects.some((p) => this.appService.isProject(p))) {
      this.updateClasses();
    }
  }

  private updateClasses() {
    const element = this.el.nativeElement;

    if (this.targetClass) {
      this.renderer.removeClass(element, this.targetClass);
    }

    if (this.newClass) {
      this.renderer.addClass(element, this.newClass);
    }
  }
}
