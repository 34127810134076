import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PaymentProviderPayMethodViewModel } from 'libs/core/src/lib/model/view-model/payment-provider-pay-method.view.model';
import { PaymentProviderStateService } from '../../../service/payment-provider-state.service';

@Component({
  selector: 'app-payment-method',
  templateUrl: './payment-method.component.html',
})
export class PaymentMethodComponent implements OnInit {
  @Input() public paymentMethod: PaymentProviderPayMethodViewModel;
  @Input() public paymentType: string;
  @Input() public paymentProvider: string;
  @Input() public paymentImgUrl: string;

  @Input()
  public set selected(value: boolean) {
    this._selected = value;
  }

  private _selected = false;

  constructor(private translateService: TranslateService, private selectedPaymentTypeService: PaymentProviderStateService) {}

  ngOnInit() {}

  public paymentProviderClick() {
    const model = this.selectedPaymentTypeService.getState();
    this.selectedPaymentTypeService.setState({ ...model, type: this.paymentType, provider: this.paymentProvider });
  }

  get properTranslation() {
    if (this.paymentMethod && this.paymentMethod.id.toLocaleLowerCase() === 'jp') {
      return this.translateService.instant('payment.method.applePay');
    }
    return this.translateService.instant('payment.method.' + this.paymentType);
  }

  public get selected() {
    return this._selected;
  }
}
