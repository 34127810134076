import { Component, OnInit } from '@angular/core';
import { BasketListComponent } from 'libs/shared/src/lib/component/basket/basket-list/basket-list.component';
import { TicketViewModel } from 'libs/core/src/lib/model/view-model/shared/ticket/ticket.view.model';

@Component({
  selector: 'app-basket-list',
  templateUrl: './basket-list.component.html',
})
export class OneilBasketListComponent extends BasketListComponent implements OnInit {
  public rebuildOptionalTickets(orderTickets: TicketViewModel[]) {}
  public afterOrderStateChanged() {}
}
