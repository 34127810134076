import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash-es';
import { ReplaySubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TotalizerService {
  private totalizer$: ReplaySubject<number> = new ReplaySubject<number>();
  public verifyTicket$ = new Subject<any>();

  private totalPrice = 0;
  private optionalExtraFeePrice = 0;

  public constructor() {}

  private emitTotalizer() {
    const total = this.totalPrice + this.optionalExtraFeePrice;
    this.totalizer$.next(total);
  }

  public clear() {
    this.totalPrice = 0;
    this.optionalExtraFeePrice = 0;
    this.emitTotalizer();
  }
  public setTotalPrice(value: number) {
    this.totalPrice = isNaN(value) ? 0 : value;
    this.emitTotalizer();
  }
  public setOptionalExtraFeePrice(value: number) {
    this.optionalExtraFeePrice = isNaN(value) ? 0 : value;
    this.emitTotalizer();
  }
  public setTotalPriceAndOptionalExtraFeePrice(totalPrice: number, optionalExtraFee: number) {
    this.totalPrice = isNaN(totalPrice) ? 0 : totalPrice;
    this.optionalExtraFeePrice = isNaN(optionalExtraFee) ? 0 : optionalExtraFee;
    this.emitTotalizer();
  }

  public getTotalPrice() {
    return this.totalizer$;
  }

  public get totalPriceValue() {
    return this.totalPrice;
  }

  public get totalPriceWithOptionalExtraFeeValue() {
    return this.totalPrice + this.optionalExtraFeePrice;
  }

  public changeTicketPriceEmit(orderTickets) {
    const tickets = cloneDeep(orderTickets);
    this.verifyTicket$.next(tickets);
  }
}
