import { IPaymentBaseModel } from '../interface/payment-base.model';

export class OrderPaymentRequestModel implements IPaymentBaseModel {
  public continueUrl: string;
  public paymentData: { [key: string]: any };
  public intPayMethodType: string;
  public intPayMethodValue: string;

  public cinemaId: string;
  public orderId: string;

  public paymentProviderIdentifier: string | null = null;

  public paymentChannel: string | null = null;

  public savePayment: boolean;
  public paymentToken: string | null = null;
  public expiry: string | null = null;

  public saveToken: boolean | null = null;
  public recaptchaResponse: string | null = null;
}
