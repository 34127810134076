import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { DateTime } from 'luxon';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { getLocaleFirstDayOfWeek, WeekDay } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { ENVIRONMENT_TOKEN } from 'libs/core/src/public-api';
import { IEnvironment } from 'libs/core/src/lib/_environment/interface';

@Component({
  template: '',
})
export class ScreeningSelectDateComponent implements OnInit, OnDestroy {
  @Output() onDateChangeEvent = new EventEmitter<DateTime>();
  @Input() daysAhead = false;

  public dateList: DateTime[] = [];
  public today = DateTime.local().startOf('day');
  private _dateSelected: DateTime;

  bsDatepickerValue: Date;
  bsConfig: Partial<BsDatepickerConfig>;
  firstDayOfWeek: WeekDay;

  constructor(@Inject(ENVIRONMENT_TOKEN) protected environment: IEnvironment, protected translateService: TranslateService) {
    const culture = this.environment.constants.language.cultureMap.find((o) => o.key === this.translateService.currentLang);
    this.firstDayOfWeek = this.getLocaleFirstDayOfWeek(culture?.value);
  }

  get dateSelected(): DateTime {
    return this._dateSelected;
  }

  @Input()
  set dateSelected(value: DateTime) {
    if (this.dateWasSelectedInOtherCalendar(value)) {
      this.prepareDates(value);
    }

    this._dateSelected = value;
  }

  private dateWasSelectedInOtherCalendar(value: DateTime): boolean {
    return this._dateSelected && this._dateSelected.toISODate() !== value.toISODate();
  }

  ngOnInit(): void {
    this.bsConfig = Object.assign(new BsDatepickerConfig(), {
      containerClass: `${this.environment.projectName}-datepicker`,
      showWeekNumbers: false,
      customTodayClass: 'today',
      isAnimated: true,
      minDate: new Date(),
      selectFromOtherMonth: true,
    });
    this.prepareDates(this._dateSelected);
    this.bsDatepickerValue = this._dateSelected.toJSDate();
  }

  private prepareDates(start: DateTime) {
    this.dateList = [];
    let daysDiff = 7 - Math.abs(start.weekday - this.firstDayOfWeek);
    if (daysDiff === 0) {
      daysDiff += 7;
    }

    for (let i = 7 - daysDiff; i > 0; i--) {
      this.dateList.push(start.minus({ days: i }));
    }

    for (let i = 0; i < daysDiff; i++) {
      this.dateList.push(start.plus({ days: i }));
    }
  }

  public onChange(date: DateTime) {
    if (this.canClick(date, true)) {
      this._dateSelected = date;
      this.bsDatepickerValue = this._dateSelected.toJSDate();
      this.onDateChangeEvent.emit(date);
    }
    this.prepareDates(this._dateSelected);
  }

  public onNextDate() {
    const el = this.dateList[this.dateList.length - 1];
    this.prepareDates(el.plus({ days: 1 }));
  }

  public onPrevDate() {
    const el = this.dateList[0];

    if (this.canClick(el)) {
      this.prepareDates(el.minus({ days: this.environment.pages.screening.days }));
    }
  }

  public canClick(date: DateTime, select = false): boolean {
    if (this.daysAhead) {
      const targetDate = date.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
      const currentDate = DateTime.local().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

      return select ? targetDate >= currentDate : targetDate > currentDate;
    }

    return true;
  }

  onDatepickerValueChange(event: Date) {
    if (event && event.getTime() !== this._dateSelected.toJSDate().getTime()) {
      this.onChange(DateTime.fromJSDate(event));
    }
  }

  ngOnDestroy(): void {
    this.dateList = [];
  }

  isCalendarDayVisible(shift: number, index: number) {
    const selectedIndex = this.dateList.map((m) => m.toISODate()).indexOf(this.dateSelected.toISODate());
    if (shift === 0) {
      return selectedIndex === index;
    } else {
      return this.dateList
        .map((c) => this.dateList.indexOf(c))
        .slice(selectedIndex - shift, selectedIndex + 1 + shift)
        .some((s) => s === index);
    }
  }

  private getLocaleFirstDayOfWeek(locale: string) {
    const effectiveLocale = locale || this.translateService.currentLang;
    try {
      return getLocaleFirstDayOfWeek(effectiveLocale);
    } catch (e) {
      const defaultLang = this.environment.constants.language.default;
      console.error(`Locale '${effectiveLocale}' not registered! The site will use the default language: '${defaultLang}' to ensure stable operation.`);
      return this.getLocaleFirstDayOfWeek(defaultLang);
    }
  }
}
