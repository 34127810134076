import { LoginProvider } from '../entities/login-provider';
import { SocialUser } from '../entities/social-user';
import { EventEmitter } from '@angular/core';
import { BehaviorSubject, filter, skip, take } from 'rxjs';
import { AuthHelper } from '../auth-helper';
import { loadScript } from '@lib/core';
import { SocialMediaProviderEnum } from '../social-media-provider.enum';

export interface GoogleInitOptions {
  /**
   * enables the One Tap mechanism, and makes auto-login possible
   */
  oneTapEnabled?: boolean;
  /**
   * list of permission scopes to grant in case we request an access token
   */
  scopes?: string | string[];
  /**
   * This attribute sets the DOM ID of the container element. If it's not set, the One Tap prompt is displayed in the top-right corner of the window.
   */
  prompt_parent_id?: string;

  /**
   * Optional, defaults to 'select_account'.
   * A space-delimited, case-sensitive list of prompts to present the
   * user.
   * Possible values are:
   * empty string The user will be prompted only the first time your
   *     app requests access. Cannot be specified with other values.
   * 'none' Do not display any authentication or consent screens. Must
   *     not be specified with other values.
   * 'consent' Prompt the user for consent.
   * 'select_account' Prompt the user to select an account.
   */
  prompt?: '' | 'none' | 'consent' | 'select_account';
}

const defaultInitOptions: GoogleInitOptions = {
  oneTapEnabled: false,
};

export class GoogleLoginProvider extends LoginProvider {
  public static readonly PROVIDER_ID: string = SocialMediaProviderEnum.GOOGLE.toUpperCase();

  public readonly changeUser = new EventEmitter<SocialUser | null>();

  private readonly _socialUser = new BehaviorSubject<SocialUser | null>(null);
  private readonly _accessToken = new BehaviorSubject<string | null>(null);
  private readonly _receivedAccessToken = new EventEmitter<string>();
  private _tokenClient: google.accounts.oauth2.TokenClient | undefined;

  clientId: string;

  constructor(private env: any, private readonly initOptions?: GoogleInitOptions) {
    super();

    this.clientId = env?.externalAuthProviders?.google?.clientId;
    console.log('GoogleLoginProvider clientId:', this.clientId);

    this.initOptions = { ...defaultInitOptions, ...this.initOptions };
    this._socialUser.pipe(skip(1)).subscribe(this.changeUser);
    this._accessToken.pipe(skip(1)).subscribe(this._receivedAccessToken);
  }

  private onLoadScript(autoLogin?: boolean) {
    google.accounts.id.initialize({
      client_id: this.clientId,
      auto_select: autoLogin,
      callback: ({ credential }) => {
        const socialUser = this.createSocialUser(credential);
        this._socialUser.next(socialUser);
      },
      prompt_parent_id: this.initOptions?.prompt_parent_id,
      itp_support: this.initOptions.oneTapEnabled,
    });

    if (this.initOptions.oneTapEnabled) {
      this._socialUser.pipe(filter((user) => user === null)).subscribe(() => google.accounts.id.prompt(console.debug));
    }

    if (this.initOptions.scopes) {
      const scope = this.initOptions.scopes instanceof Array ? this.initOptions.scopes.filter((s) => s).join(' ') : this.initOptions.scopes;

      this._tokenClient = google.accounts.oauth2.initTokenClient({
        client_id: this.clientId,
        scope,
        prompt: this.initOptions.prompt,
        callback: (tokenResponse) => {
          if (tokenResponse.error) {
            this._accessToken.error({
              code: tokenResponse.error,
              description: tokenResponse.error_description,
              uri: tokenResponse.error_uri,
            });
          } else {
            this._accessToken.next(tokenResponse.access_token);
          }
        },
      });
    }
  }

  initialize(autoLogin?: boolean): Promise<void> {
    console.log('#initialize GoogleLoginProvider');

    return new Promise((resolve, reject) => {
      if (!this.clientId) {
        reject();
      } else {
      }

      try {
        loadScript(
          GoogleLoginProvider.PROVIDER_ID,
          'https://accounts.google.com/gsi/client',
          () => {
            this.onLoadScript(autoLogin);
            resolve();
          },
          true,
          true
        );
      } catch (err) {
        reject(err);
      }
    });
  }

  getLoginStatus(): Promise<SocialUser> {
    console.log('#getLoginStatus');

    return new Promise((resolve, reject) => {
      if (this._socialUser.value) {
        resolve(this._socialUser.value);
      } else {
        reject(`No user is currently logged in with ${GoogleLoginProvider.PROVIDER_ID}`);
      }
    });
  }

  refreshToken(): Promise<SocialUser | null> {
    console.log('#refreshToken');

    return new Promise((resolve, reject) => {
      google.accounts.id.revoke(this._socialUser.value.id, (response) => {
        if (response.error) reject(response.error);
        else resolve(this._socialUser.value);
      });
    });
  }

  getAccessToken(): Promise<string> {
    console.log('#getAccessToken');

    return new Promise((resolve, reject) => {
      if (!this._tokenClient) {
        if (this._socialUser.value) {
          reject('No token client was instantiated, you should specify some scopes.');
        } else {
          reject('You should be logged-in first.');
        }
      } else {
        this._tokenClient.requestAccessToken({
          hint: this._socialUser.value?.email,
        });
        this._receivedAccessToken.pipe(take(1)).subscribe(resolve);
      }
    });
  }

  revokeAccessToken(): Promise<void> {
    console.log('#revokeAccessToken');

    return new Promise((resolve, reject) => {
      if (!this._tokenClient) {
        reject('No token client was instantiated, you should specify some scopes.');
      } else if (!this._accessToken.value) {
        reject('No access token to revoke');
      } else {
        google.accounts.oauth2.revoke(this._accessToken.value, () => {
          this._accessToken.next(null);
          resolve();
        });
      }
    });
  }

  signIn(): Promise<SocialUser> {
    return Promise.reject(
      'You should not call this method directly for Google, use "<login-google-signin-button>" wrapper ' +
        'or generate the button yourself with "google.accounts.id.renderButton()" ' +
        '(https://developers.google.com/identity/gsi/web/guides/display-button#javascript)'
    );
  }

  async signOut(): Promise<void> {
    google.accounts.id.disableAutoSelect();
    this._socialUser.next(null);
  }

  private createSocialUser(idToken: string) {
    const user = new SocialUser();
    user.idToken = idToken;
    const payload = AuthHelper.decodeJwt(idToken);
    user.id = payload.sub;
    user.name = payload.name;
    user.email = payload.email;
    user.photoUrl = payload.picture;
    user.firstName = payload['given_name'];
    user.lastName = payload['family_name'];

    user.authToken = AuthHelper.toBase64({
      firstName: user.firstName,
      lastName: user.lastName,
      jwtToken: idToken,
    });

    return user;
  }
}
