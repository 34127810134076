import { Injectable } from '@angular/core';
import { GTagServiceCore } from './gtag.service.core';
import { OrderViewModel } from '@lib/core';

declare let gtag: Function;

@Injectable({
  providedIn: 'root',
})
export class GTagService extends GTagServiceCore {
  public conversion(order?: OrderViewModel): void {
    if (this.isConfigured()) {
      const tag = this.environment.tagManager.providers.filter((f) => f.name === 'gtag')[0];

      if (tag?.conversion) {
        gtag('event', tag.conversion.name, {
          send_to: tag.conversion.sendTo,
          value: order?.totalValue,
          currency: this.environment.constants.currency,
          transaction_id: order?.bookingId,
        });
      }
    }
  }
}
