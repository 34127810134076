export { SocialAuthService, SocialAuthServiceConfig } from './socialauth.service';
export { SocialLoginModule } from './sociallogin.module';
export { SocialUser } from './entities/social-user';
export { ILoginProvider } from './entities/login-provider';
export { GoogleInitOptions, GoogleLoginProvider } from './providers/google-login-provider';
export { FacebookLoginProvider } from './providers/facebook-login-provider';
export { AppleLoginProvider } from './providers/apple-login-provider';

export { GoogleButtonDirective } from './directives/google-button.directive';
export { AppleButtonDirective } from './directives/apple-button.directive';
export { SocialMediaProviderEnum } from './social-media-provider.enum';
