import { DateTime } from 'luxon';
import { AbstractViewModel } from '../../../abstract.view.model';
import { UserVoucherApiModel } from '../../../api-model/user-history/voucher/user-voucher.api.model';
import { VoucherTypeEnum } from './voucher-type.enum';

export class UserVoucherViewModel extends AbstractViewModel<UserVoucherApiModel> {
  public batchVoucherID: string = null;
  public voucherID: string = null;
  public voucherNumber: string = null;
  public voucherName: string = null;
  public voucherDescription: string = null;
  public voucherPrice: number = null;
  public voucherFlgUnlimited: boolean = null;
  public voucherUsageLeft: number = null;
  public voucherExpiryDate: DateTime = null;
  public voucherNote: string = null;
  public flgMultiUse: boolean = null;
  public flgActive: boolean = null;
  public flgHidden: boolean = null;
  public voucherGroupId: string = null;
  public voucherGroupName: string = null;

  public isUsed = false;
  public type: VoucherTypeEnum;
  public canBeUsedOnWebSalesChannel: boolean;

  constructor(protected apiModel: UserVoucherApiModel = new UserVoucherApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.batchVoucherID = this.apiModel.batchVoucherID;
    this.voucherID = this.apiModel.voucherID;
    this.voucherNumber = this.apiModel.voucherNumber;
    this.voucherName = this.apiModel.voucherName;
    this.voucherDescription = this.apiModel.voucherDescription;
    this.voucherPrice = this.apiModel.voucherPrice;
    this.voucherFlgUnlimited = this.apiModel.voucherFlgUnlimited;
    this.voucherUsageLeft = this.apiModel.voucherUsageLeft;
    this.voucherExpiryDate = this.apiModel.voucherExpiryDate ? DateTime.fromISO(this.apiModel.voucherExpiryDate) : null;
    this.voucherNote = this.apiModel.voucherNote;
    this.flgMultiUse = this.apiModel.flgMultiUse;
    this.flgActive = this.apiModel.flgActive;
    this.flgHidden = this.apiModel.flgHidden;
    this.voucherGroupId = this.apiModel.voucherGroupId;
    this.voucherGroupName = this.apiModel.voucherGroupName;
  }

  toApiModel(): UserVoucherApiModel {
    return undefined;
  }
}
