import { Inject, Injectable } from '@angular/core';
import { LegendItemType } from '../../enum/legend-item-type.enum';
import { OccupiedStatus } from '../../enum/occupied-status.enum';
import { ScreenElements } from '../../enum/screen-elements.enum';
import { ENVIRONMENT_TOKEN } from '../../injection.tokens';
import { LegendModel } from '../../model/legend.model';
import { SeatGroupViewModel } from '../../model/view-model/screen/seat/seat-group.view.model';
import { ScreenElementViewModel } from '../../model/view-model/screen/screen-element.view.model';
import { isNullOrEmpty } from 'libs/core/src/lib/function/custom-function';
import { convertStringToEnumValue } from '../../helper/enum.helper';
import _ from 'lodash';

export interface ISeatType {
  name: string;
  color: string;
  type: string;
  displayMode: number;
}

@Injectable({
  providedIn: 'root',
})
export class SimpleLegendService {
  constructor(@Inject(ENVIRONMENT_TOKEN) protected environment: any) {}

  getLegend(screen, basedOnDisplayMode: boolean = false): Array<LegendModel> {
    if (!screen) {
      return [];
    }

    return !basedOnDisplayMode ? this.getLegendDefault(screen) : this.getLegendBasedOnDisplayMode(screen);
  }

  private getLegendDefault(screen) {
    let result = new Array<LegendModel>();

    const occupiedStatus = Object.keys(OccupiedStatus).filter((f) => f.toLowerCase() !== OccupiedStatus.Free.toLowerCase());
    let seatTypes: SeatGroupViewModel[] = [];

    const couches = screen.groups.filter((s) => s.type === 'couch');
    if (couches && couches.length > 0) {
      for (let index = 0; index < couches.length; index++) {
        const element = couches[index];
        if (!seatTypes.some((s) => s.type === element.type && s.color === element.color)) {
          seatTypes.push(element);
        }
      }
    }

    const lounges = screen.groups.filter((s) => s.type === 'lounge');
    if (lounges && lounges.length > 0) {
      for (let index = 0; index < lounges.length; index++) {
        const element = lounges[index];
        if (!seatTypes.some((s) => s.type === element.type && s.color === element.color)) {
          seatTypes.push(element);
        }
      }
    }

    const schemaSort = this.environment.pages.screen.legend.sort;

    const groupsArray = []
      .concat(
        ...screen.pseats.map((ps) =>
          ps
            .filter((f) => f.groupTypes?.some((s) => s !== 'couch' && s !== 'lounge' && s !== 'wheelchair'))
            .map((s) => (s.groups ? [s.groups[0]?.color, s.groups[0]?.name] : s.groups))
        )
      )
      .filter((r) => r);
    const groupsUniqueArray = new Map<string, string>(groupsArray);

    seatTypes.forEach((item) => {
      result.push(new LegendModel(item.type.toLowerCase(), 'screen.legend.group.' + item.type.toLowerCase(), LegendItemType.Couch, item.color));
    });

    const hasStandard = screen.pseats.some((p) => p.some((s) => !s.groupId && s.kind === '0'));
    if (hasStandard) {
      result.push(new LegendModel('standard', 'screen.legend.group.standard', LegendItemType.Default, screen.defaultSeatGroupColor));
    }

    occupiedStatus.forEach((item) => {
      result.push(new LegendModel(item.toLowerCase(), 'screen.legend.occupancyStatus.' + item.toLowerCase(), LegendItemType.Default));
    });

    result = result
      .filter((a) => schemaSort.indexOf(a.classKey) > -1)
      .sort((a, b) => (schemaSort.indexOf(a.classKey) > schemaSort.indexOf(b.classKey) ? 1 : -1));

    groupsUniqueArray.forEach((item, color) => {
      result.push(new LegendModel(item.trim().toLowerCase(), item, LegendItemType.SeatGroup, color));
    });

    const hasWheelchair = screen.pseats.some((p) => p.some((s) => s.wheelchairSeat && s.occupied !== OccupiedStatus.Locked));
    if (hasWheelchair) {
      result.push(new LegendModel('wheelchair', 'screen.legend.group.wheelchair', LegendItemType.Wheelchair));
    }

    const screenElements = this.getScreenElements(screen.screenElements);

    screenElements.forEach((item) => {
      result.push(new LegendModel(item.toLowerCase(), 'screen.legend.screenElements.' + item.toLowerCase(), LegendItemType.ScreenElement));
    });

    return result;
  }

  private getLegendBasedOnDisplayMode(screen) {
    let result = new Array<LegendModel>();
    const groupSeatTypes: ISeatType[] = screen.groups.map((group) => {
      return {
        name: group.name,
        color: group.color,
        type: group.type.replaceAll(' ', '').toLowerCase(),
        displayMode: group.displayMode,
      } as ISeatType;
    });

    let seatTypes = _.uniqWith(groupSeatTypes, (groupA, groupB) => groupA.type === groupB.type && groupA.displayMode === groupB.displayMode);

    const couchAndLoungeSeats = seatTypes.filter((item) => ['couch', 'lounge'].includes(item.type));
    couchAndLoungeSeats.forEach((item) => {
      result.push(new LegendModel(item.type, 'screen.legend.group.' + item.type, LegendItemType.Couch, item.color, item.displayMode));
    });

    const hasStandard = screen.pseats.some((p) => p.some((s) => !s.groupId && s.kind === '0'));
    if (hasStandard) {
      result.push(new LegendModel('standard', 'screen.legend.group.standard', LegendItemType.Default, screen.defaultSeatGroupColor));
    }

    const occupiedStatus = Object.keys(OccupiedStatus).filter((f) => f.toLowerCase() !== OccupiedStatus.Free.toLowerCase());
    occupiedStatus.forEach((item) => {
      result.push(new LegendModel(item.toLowerCase(), 'screen.legend.occupancyStatus.' + item.toLowerCase(), LegendItemType.Default));
    });

    const schemaSort = this.environment.pages.screen.legend.sort;
    result = result
      .filter((a) => schemaSort.indexOf(a.classKey) > -1)
      .sort((a, b) => (schemaSort.indexOf(a.classKey) > schemaSort.indexOf(b.classKey) ? 1 : -1));

    const otherSeats = seatTypes.filter((item) => !['couch', 'lounge', 'wheelchair'].includes(item.type));
    otherSeats.forEach((item) => {
      result.push(new LegendModel(item.type, item.name, LegendItemType.SeatGroup, item.color, item.displayMode));
    });

    const wheelchairSeats = seatTypes.filter((item) => ['wheelchair'].includes(item.type));
    wheelchairSeats.forEach((item) => {
      result.push(new LegendModel(item.type, item.name, LegendItemType.Wheelchair, item.color, item.displayMode));
    });

    const screenElements = this.getScreenElements(screen.screenElements);
    screenElements.forEach((item) => {
      result.push(new LegendModel(item.toLowerCase(), 'screen.legend.screenElements.' + item.toLowerCase(), LegendItemType.ScreenElement));
    });

    return result;
  }

  getSeats() {
    return [];
  }

  distinctArray(value: any, index: any, self: any): boolean {
    return self.indexOf(value) === index;
  }

  getScreenElements(elements: ScreenElementViewModel[]): ScreenElements[] {
    let groupedElements: ScreenElements[] = [];

    elements
      .filter((e) => !isNullOrEmpty(e.name))
      .forEach((element) => {
        const value = convertStringToEnumValue(ScreenElements, element.name.split(/[^A-Za-z]/)[0]?.toLowerCase());

        if (value) {
          groupedElements.push(value);
        } else if (element.name.toLowerCase().includes('entrance')) {
          groupedElements.push(ScreenElements.Exit);
        } else if (element.name.toLowerCase().includes('wall')) {
          groupedElements.push(ScreenElements.Wall);
        }
      });

    return groupedElements.filter((value, index, self) => self.indexOf(value) === index);
  }
}
