import { Directive, ElementRef, HostListener, Input, Output, EventEmitter } from '@angular/core';
import { KEY_CODE } from '../enum/key-code.enum';

@Directive({
  selector: 'input[type=number], input[digitOnly]',
})
export class DigitDirective {
  navigationKeys: Array<string> = [KEY_CODE.BACKSPACE, KEY_CODE.DELETE, KEY_CODE.ARROW_UP, KEY_CODE.ARROW_DOWN, KEY_CODE.ARROW_RIGHT, KEY_CODE.ARROW_LEFT];

  @Input() excludepattern = '[^0-9]*';

  constructor(private _el: ElementRef) {}

  @HostListener('paste', ['$event']) onPaste(event) {
    const clipboardData: DataTransfer = event.clipboardData || (window as any).clipboardData;
    const pastedText: string = clipboardData.getData('text');
    if (pastedText && !String(pastedText).match(this.getPatternRegex())) {
      event.preventDefault();
    }
  }

  @HostListener('keydown', ['$event']) onKeyUp(e: KeyboardEvent) {
    if (
      this.navigationKeys.indexOf(e.key) > -1 ||
      (e.key === 'a' && e.ctrlKey === true) || // Allow: Ctrl+A
      (e.key === 'c' && e.ctrlKey === true) || // Allow: Ctrl+C
      (e.key === 'v' && e.ctrlKey === true) || // Allow: Ctrl+V
      (e.key === 'x' && e.ctrlKey === true) || // Allow: Ctrl+X
      (e.key === 'a' && e.metaKey === true) || // Cmd+A (Mac)
      (e.key === 'c' && e.metaKey === true) || // Cmd+C (Mac)
      (e.key === 'v' && e.metaKey === true) || // Cmd+V (Mac)
      (e.key === 'x' && e.metaKey === true) || // Cmd+X (Mac)
      e.key === KEY_CODE.TAB || // Allow Tab navigation
      (e.key === KEY_CODE.TAB && e.shiftKey === true) // Allow Shift+Tab navigation
    ) {
      return;
    }

    if (e.key === ' ' || isNaN(Number(e.key))) {
      e.preventDefault();
    }
  }

  private getPatternRegex(): RegExp {
    return new RegExp(this.excludepattern, 'g');
  }
}
