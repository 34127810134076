import { Component, OnInit, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { CheckListItemModel } from 'libs/core/src/lib/model/checklistitem.model';
import { noop, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

const debouncer = new Subject<CheckListItemModel[]>();

@Component({
  template: '',
})
export class ChecklistComponent implements OnInit, ControlValueAccessor {
  wasInside = false;
  touched = false;

  @Output() public checkListChangeEvent = new EventEmitter<CheckListItemModel[]>();
  @Input() public items: CheckListItemModel[];
  @Input() public showSelectedElements = false;
  @Input() public showCheckAll = true;
  @Input() public placeholder = '';
  @Input() disabled: boolean;

  @HostListener('click')
  clickInside() {
    this.wasInside = true;
  }

  @HostListener('document:click')
  clickout() {
    if (!this.wasInside) {
      this.active = false;
    }
    this.wasInside = false;
  }

  public checkAll: CheckListItemModel = new CheckListItemModel('checkAllCheckbox', '');

  active = false;
  private elementsTrans = 'elements';

  constructor(protected translate: TranslateService) {
    debouncer.pipe(distinctUntilChanged(), debounceTime(400)).subscribe((value) => this.checkListChangeEvent.emit(value));
  }

  ngOnInit() {
    this.active = !this.showSelectedElements;
  }

  setAll() {
    if (this.items == null) {
      return;
    }
    this.items.forEach((t) => (t.checked = this.checkAll.checked));
    this.changeEventEmit();
  }

  updateCheckAll() {
    this.checkAll.checked = this.items != null && this.items.every((t) => t.checked);
    this.changeEventEmit();
    this.onTouched();
    this.onChange(this.items);
  }

  get selected() {
    if (this.items == null) {
      return '';
    }
    const checked = this.items.filter((o) => o.checked);
    if (checked.length === 0) {
      return '';
    } else if (checked.length > 3) {
      return `${checked.length} ${this.elementsTrans}`;
    }

    return checked
      .slice(0, checked.length)
      .map((o) => (o.translate ? this.translate.instant(o.name) : o.name))
      .join(',');
  }

  private changeEventEmit() {
    debouncer.next(this.items.filter((o) => o.checked));
  }

  onChange = (value: any) => {
    noop();
  };

  onTouched = () => {
    noop();
  };

  writeValue(value: any) {
    if (value.length > 0) {
      this.items = value;
      this.onChange(this.items);
    } else {
      this.items = [];
    }
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }
}
