import { Injectable, Type } from '@angular/core';
import { BasysPaymentProviderComponent } from './basys/basys-payment-provider.component';
import { ConotoxiaPaymentProviderComponent } from './conotoxia/conotoxia-payment-provider.component';
import { FiservPaymentProviderComponent } from './fiserv/fiserv-payment-provider.component';
import { HyperpayPaymentProviderComponent } from './hyperpay/hyperpay-payment-provider.component';
import { IntercardPaymentProviderComponent } from './intercard/intercard-payment-provider.component';
import { PaymentProviderComponentInterface } from './payment-provider.component.interface';
import { PayuBlikPaymentProviderComponent } from './payu-blik/payu-blik-payment-provider.component';
import { PayuPaymentProviderComponent } from './payu/payu-payment-provider.component';
import { SandboxPaymentProviderComponent } from './sandbox/sandbox-payment-provider.component';
import { WorldPayPaymentProviderComponent } from './worldpay/worldpay-payment-provider.component';
import { CorvusPayPaymentProviderComponent } from './corvuspay/corvuspay-payment-provider.component';
import { VivaWalletPaymentProviderComponent } from './vivawallet/vivawallet-payment-provider.component';
import { NestPayPaymentProviderComponent } from './nestpay/nestpay-payment-provider.component';
import { BridgePayPaymentProviderComponent } from './bridgepay/bridgepay-payment-provider.component';
import { DataTransPaymentProviderComponent } from './datatrans/datatrans-payment-provider.component';
import { FiservDevPaymentProviderComponent } from './fiservdev/fiservdev-payment-provider.component';
import { P24PaymentProviderComponent } from './p24/p24-payment-provider.component';
import { NexiXPayPaymentProviderComponent } from './nexixpay/nexixpay-payment-provider.component';

@Injectable({
  providedIn: 'root',
})
export class PaymentProviderResolver {
  private componentMap: { [type: string]: Type<PaymentProviderComponentInterface> } = {
    payu: PayuPaymentProviderComponent,
    hyperpay: HyperpayPaymentProviderComponent,
    payu_blik: PayuBlikPaymentProviderComponent,
    basys: BasysPaymentProviderComponent,
    cinkciarz: ConotoxiaPaymentProviderComponent,
    intercard: IntercardPaymentProviderComponent,
    sandbox: SandboxPaymentProviderComponent,
    worldpay: WorldPayPaymentProviderComponent,
    corvuspay: CorvusPayPaymentProviderComponent,
    vivawallet: VivaWalletPaymentProviderComponent,
    fiserv: FiservPaymentProviderComponent,
    nestpay: NestPayPaymentProviderComponent,
    bridgepay: BridgePayPaymentProviderComponent,
    datatrans: DataTransPaymentProviderComponent,
    fiservdev: FiservDevPaymentProviderComponent,
    przelewy24: P24PaymentProviderComponent,
    xpay: NexiXPayPaymentProviderComponent,
  };

  public getComponentType(providerType: string): Type<PaymentProviderComponentInterface> | null {
    return this.componentMap[providerType] || null;
  }
}
