import { Directive, ElementRef, Inject, Input, Renderer2 } from '@angular/core';
import { take } from 'rxjs';
import { SocialAuthService } from '../socialauth.service';
import { ENVIRONMENT_TOKEN } from '@lib/core';

declare let AppleID: any;

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'login-apple-button',
})
export class AppleButtonDirective {
  @Input()
  type: 'sign-in' | 'continue' | 'sign-up' = 'sign-in';

  @Input()
  mode: 'center-align' | 'left-align' | 'logo-only' = 'center-align';

  @Input()
  color: 'black' | 'white' = 'black';

  @Input()
  border: 'true' | 'false' = 'true';

  @Input()
  logoSize: 'small' | 'medium' | 'large' = 'small';

  @Input()
  width: string = '100%';

  @Input()
  height: string = '100%';

  @Input()
  labelPosition: number = 0;

  @Input()
  logoPosition: number = 0;

  @Input()
  borderRadius: number = 15;

  @Input()
  customButtonText: string = '';

  constructor(@Inject(ENVIRONMENT_TOKEN) private environment: any, el: ElementRef, socialAuthService: SocialAuthService) {
    if (!environment?.externalAuthProviders?.apple?.clientId?.length) {
      return;
    }

    socialAuthService.initState.pipe(take(1)).subscribe(() => {
      Promise.resolve({ w: this.width, h: this.height }).then((value) => {
        if (value.w > '375' || (value.w < '130' && value.w != '100%')) {
          Promise.reject(
            'Please note .. max-width 375 , min-width 130 or 100%' +
              '(https://developer.apple.com/documentation/sign_in_with_apple/displaying_sign_in_with_apple_buttons_on_the_web)'
          );
        } else if (value.h > '64' || (value.h < '30' && value.h != '100%')) {
          Promise.reject(
            'Please note .. max-height 64 , min-height 30 or 100%' +
              '(https://developer.apple.com/documentation/sign_in_with_apple/displaying_sign_in_with_apple_buttons_on_the_web)'
          );
        } else {
          const node = document.createElement('div');
          node.classList.add(`apple-${this.type}-button`);
          node.setAttribute('id', 'appleid-signin');
          node.setAttribute('data-mode', this.mode);
          node.setAttribute('data-type', this.type);
          node.setAttribute('data-color', this.color);
          node.setAttribute('data-border', this.border);
          node.setAttribute('data-border-radius', this.borderRadius.toString());
          node.setAttribute('data-width', this.width);
          node.setAttribute('data-height', this.height);
          if (this.mode === 'left-align') {
            node.setAttribute('data-logo-size', this.logoSize);
            node.setAttribute('data-logo-position', this.logoPosition.toString());
            node.setAttribute('data-label-position', this.labelPosition.toString());
          }

          el.nativeElement.appendChild(node);
          AppleID.auth.renderButton();

          const btnPlace = document.getElementById('appleid-signin');
          if (btnPlace) {
            btnPlace.textContent = '';
          }

          const buttonText = this.customButtonText.length ? this.customButtonText : 'Apple';
          const div = document.createElement('div');
          div.setAttribute('class', 'sm-login-btn apple');

          const icon = document.createElement('i');
          icon.setAttribute('class', 'fab fa-apple');
          div.appendChild(icon);

          const text = document.createElement('span');
          text.textContent = buttonText;
          div.appendChild(text);

          if (btnPlace) {
            btnPlace.appendChild(div);
          }
        }
      });
    });
  }
}
