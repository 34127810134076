import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output, Renderer2 } from '@angular/core';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormGroup } from '@angular/forms';
import cloneDeep from 'lodash-es/cloneDeep';
import PaymentData = GooglePay.PaymentData;
import { PaymentProviderComponentInterface } from '../payment-provider.component.interface';
import { GooglePay } from '../gpay/client/client';
import { OrderDataProvider } from 'libs/core/src/lib/data-provider/order.data-provider';
import { PaymentProviderConfigRequestModel } from 'libs/core/src/lib/model/request/payment-provider-config.request.model';
import { PaymentProviderPayMethodRequestModel } from 'libs/core/src/lib/model/request/payment-provider-pay-method.request.model';
import { PaymentConfigViewModel } from 'libs/core/src/lib/model/view-model/payment/config/payment.config.view.model';
import { OrderStateService } from 'libs/core/src/lib/state/order.state.service';
import { CreatePaymentRedirectUrl } from '../../../create-payment-redirect-url';
import { PaymentHelperService } from '../../../service/payment-helper.service';
import { PaymentProviderStateService } from '../../../service/payment-provider-state.service';
import {
  PaymentProviderEvent,
  DonePaymentProviderEvent,
  ErrorPaymentProviderEvent,
  WaitPaymentProviderEvent,
  WorkPaymentProviderEvent,
  ErrorPaymentKeyProviderEvent,
} from '../../event/payment-provider.event';
import { PaymentMethodEnum } from './payment-method.enum';
import { PaymentPreInitModel } from '../../model/payment-pre-init.model';
import { PaymentTotalPriceStatus, PaymentAuthMethod, PaymentCardNetwork, PaymentDataModel } from '../gpay/model/payment-data.model';
import { GooglePayService, CreatePaymentDataRequestDto } from '../gpay/service/google-pay.service';
import { PayuExpressService } from './service/payu-express.service';
import { ENVIRONMENT_TOKEN, loadScriptSequentially, ProviderEnum, sanitizeInput } from 'libs/core/src/public-api';
import { PaymentProviderPayMethodViewModel } from 'libs/core/src/lib/model/view-model/payment-provider-pay-method.view.model';
import { PaymentViewModel } from 'libs/core/src/lib/model/view-model/payment.view.model';
import { FinancialHelper } from '@lib/core';
import { OrderViewModel } from 'libs/core/src/lib/model/view-model/order/order.view.model';
import { isDevMode } from '@angular/core';
import { generalPaymentError } from '../../../payment-error.consts';
import { ApplePayService } from '../apay/service/apple-pay.service';

declare let OpenPayU: unknown;
declare let ApplePaySession: any | undefined;

const internalPaymentMethodType = {
  googlePayToken: 'GOOGLEPAY_TOKEN',
  cardToken: 'CARD_TOKEN',
  blikToken: 'BLIK',
  blikCode: 'BLIK_T6',
  payByLink: 'PBL',
  applePayToken: 'APPLEPAY_TOKEN',
};

@Component({
  selector: 'app-payment-provider-payu-component',
  templateUrl: './payu-payment-provider.component.html',
})
export class PayuPaymentProviderComponent implements PaymentProviderComponentInterface, OnInit, OnDestroy {
  cardModel: {
    cardNumber: string;
    cardCVV: string;
    cardExpirationDate: string;
    cardExpirationYear: string;
    cardExpirationMonth: string;
  } = { cardNumber: '', cardCVV: '', cardExpirationDate: '', cardExpirationMonth: '', cardExpirationYear: '' };

  @Output()
  public events: EventEmitter<PaymentProviderEvent> = new EventEmitter<PaymentProviderEvent>();

  public blikWaitSecondsForTransactionStep = 7;

  public cardWaitSecondsForTransactionStep = 4;

  public paymentMethod = PaymentMethodEnum;

  public readonly paymentProviderIdentifier: string = ProviderEnum.PAYU;

  /**
   * The collection of payByLinks methods
   */
  public payByLinkMethodCollection: Array<PaymentProviderPayMethodViewModel> = new Array<PaymentProviderPayMethodViewModel>();

  /**
   * The collection of card methods
   */
  public cardMethodCollection: Array<PaymentProviderPayMethodViewModel> = new Array<PaymentProviderPayMethodViewModel>();

  /**
   * Selected pay by link method by user
   */
  public selectedPayByLinkMethod: PaymentProviderPayMethodViewModel | null = null;

  /**
   * Selected card method
   */
  public selectedCardMethod: PaymentProviderPayMethodViewModel | null = null;

  /**
   * Selected payment provider pay method
   */
  public selectedPayMethodTypeCustom: string | null = null;

  public paymentProviderPayMethodCollection: Array<PaymentProviderPayMethodViewModel> = new Array<PaymentProviderPayMethodViewModel>();

  public paymentProviderConfig: PaymentConfigViewModel | null;

  public form: FormGroup;
  public cardFormGroup: FormGroup;

  private paymentProviderInitSubscription: Subscription = Subscription.EMPTY;

  private order: OrderViewModel = null;
  private blikNumber: string = null;

  private readonly paymentChannel: string | null = null;
  public formSubmitAttempt = false;
  public formSubmitAttempt2 = false;

  private continueUrl: string;
  private isApplePaySessionDefined = false;

  public constructor(
    @Inject(ENVIRONMENT_TOKEN) protected environment: any,
    private orderDataProvider: OrderDataProvider,
    private orderStateService: OrderStateService,
    private paymentHelperService: PaymentHelperService,
    private route: ActivatedRoute,
    private payuExpressService: PayuExpressService,
    private googlePayService: GooglePayService,
    private applePayService: ApplePayService,
    private selectedPaymentTypeService: PaymentProviderStateService,
    private formBuilder: FormBuilder,
    private renderer: Renderer2
  ) {
    this.loadProviderScripts();

    this.paymentChannel = this.environment.constants.paymentChannel;
    this.selectedPaymentTypeService.state$.subscribe((state) => {
      if (state) {
        this.selectedPayMethodTypeCustom = state.type;
        this.selectedPayByLinkMethod = state.payByLink;
      } else {
        this.selectedPayMethodTypeCustom = null;
        this.selectedPayByLinkMethod = null;
      }
    });
  }

  public ngOnInit(): void {}

  private initialComponent() {
    this.order = this.orderStateService.getOrder();

    this.events.next(new WorkPaymentProviderEvent());
    this.form = this.selectedPaymentTypeService.getBlikFormGroup();

    this.cardFormGroup = this.selectedPaymentTypeService.getPayuCardFormGroup();

    this.cardFormGroup.valueChanges.subscribe((form) => {
      const { cardCVV, cardNumber, cardExpirationDate } = form;
      this.cardModel = {
        cardCVV: cardCVV,
        cardNumber: cardNumber,
        cardExpirationDate: cardExpirationDate,
        cardExpirationMonth: cardExpirationDate ? cardExpirationDate.substring(0, 2) : '',
        cardExpirationYear: cardExpirationDate
          ? cardExpirationDate.substring(2).length < 4
            ? '20' + cardExpirationDate.substring(2)
            : cardExpirationDate.substring(2)
          : '',
      };
    });
    this.initPaymentProviderWidget();

    if (this.applePayService.checkApplePayAvailability()) {
      this.isApplePaySessionDefined = true;
    }
  }

  public ngOnDestroy(): void {
    if (this.paymentProviderInitSubscription !== Subscription.EMPTY) {
      this.paymentProviderInitSubscription.unsubscribe();
      this.paymentProviderInitSubscription = Subscription.EMPTY;
    }

    this.form?.reset();
    this.cardFormGroup?.reset();
  }

  public onPostInitPayment(paymentModel: PaymentViewModel): void {
    switch (this.selectedPayMethodTypeCustom) {
      case PaymentMethodEnum.TYPE_PBL:
      case PaymentMethodEnum.TYPE_GPAY:
      case PaymentMethodEnum.TYPE_APAY:
      case PaymentMethodEnum.TYPE_CARD:
        window.location.href = paymentModel.plainPayload;
        break;

      case PaymentMethodEnum.TYPE_BLIK:
        const waitSeconds: number = this.blikWaitSecondsForTransactionStep;
        this.events.next(new WaitPaymentProviderEvent(waitSeconds));
        setTimeout(() => {
          const request: CreatePaymentRedirectUrl = new CreatePaymentRedirectUrl();
          request.paymentChannel = this.paymentChannel;
          request.order = this.order;
          request.route = this.route;
          window.location.href = this.paymentHelperService.createPaymentRedirectUrl(request, this.continueUrl);
        }, waitSeconds * 1000);
        break;

      default:
        break;
    }
  }

  public onPreInitPayment(event: PaymentPreInitModel): Observable<PaymentPreInitModel> {
    this.continueUrl = event.continueUrl;
    return new Observable<PaymentPreInitModel>((subscriber) => {
      switch (this.selectedPayMethodTypeCustom) {
        case null:
          event.abort = true;
          this.emitNotSelectedPaymentProvider();
          subscriber.next(event);
          subscriber.complete();
          break;

        case PaymentMethodEnum.TYPE_PBL:
          if (!this.selectedPayByLinkMethod) {
            event.abort = true;
            this.emitNotSelectedPaymentProvider();
          } else {
            event.intPayMethodType = internalPaymentMethodType.payByLink;
            event.intPayMethodValue = this.selectedPayByLinkMethod.id;
          }
          subscriber.next(event);
          subscriber.complete();
          break;

        case PaymentMethodEnum.TYPE_BLIK:
          this.formSubmitAttempt = true;
          if (this.form.valid) {
            const blikTokenPayMethod: PaymentProviderPayMethodViewModel | null = this.getBLIKTokenPayMethod();
            const filteredInputBlikCode: string | null = sanitizeInput(this.form.get('blikCode').value);
            if (filteredInputBlikCode === null && blikTokenPayMethod !== null) {
              event.intPayMethodType = internalPaymentMethodType.blikToken;
              event.intPayMethodValue = blikTokenPayMethod.id;
            } else if (filteredInputBlikCode !== null) {
              event.intPayMethodType = internalPaymentMethodType.blikCode;
              event.intPayMethodValue = filteredInputBlikCode;
            } else {
              event.abort = true;
            }
          } else {
            event.abort = true;
          }
          subscriber.next(event);
          subscriber.complete();
          break;

        case PaymentMethodEnum.TYPE_CARD:
          this.formSubmitAttempt2 = true;

          if (this.selectedCardMethod !== null) {
            event.intPayMethodType = internalPaymentMethodType.cardToken;
            event.intPayMethodValue = this.selectedCardMethod.id;
            subscriber.next(event);
            subscriber.complete();
          } else {
            if (this.cardFormGroup.valid) {
              this.payuExpressService.getCardToken(this.paymentProviderConfig.salesPointId).subscribe({
                next: (token) => {
                  event.intPayMethodType = internalPaymentMethodType.cardToken;
                  event.intPayMethodValue = token;
                  subscriber.next(event);
                  subscriber.complete();
                },
                error: (e: HttpErrorResponse) => {
                  console.error('error', e);
                  this.events.next(new ErrorPaymentProviderEvent(e));
                  event.abort = true;
                },
              });
            } else {
              event.abort = true;
              subscriber.next(event);
              subscriber.complete();
            }
          }
          break;

        case PaymentMethodEnum.TYPE_GPAY:
          console.log('paymentProviderConfig', this.paymentProviderConfig);
          const googlePayClient: GooglePay.PaymentsClient = this.googlePayService.getClient(this.paymentProviderConfig.environment);
          const orderPrice: number = this.order.getPrice();
          const createPaymentRequestDataDto: CreatePaymentDataRequestDto = new CreatePaymentDataRequestDto();
          createPaymentRequestDataDto.merchantId = this.paymentProviderConfig.merchantId;
          createPaymentRequestDataDto.merchantName = this.paymentProviderConfig.merchantName;
          createPaymentRequestDataDto.gateway = 'payu';
          createPaymentRequestDataDto.gatewayMerchantId = this.paymentProviderConfig.salesPointId;
          createPaymentRequestDataDto.totalPriceStatus = PaymentTotalPriceStatus.FINAL;
          createPaymentRequestDataDto.totalPrice = FinancialHelper.fixedValue(orderPrice);
          createPaymentRequestDataDto.currencyCode = this.environment.constants.currency;
          createPaymentRequestDataDto.countryCode = this.environment.constants.country;
          createPaymentRequestDataDto.allowedCardAuthMethods = [PaymentAuthMethod.PAN_ONLY, PaymentAuthMethod.CRYPTOGRAM_3DS];
          createPaymentRequestDataDto.allowedCardMethods = [PaymentCardNetwork.VISA, PaymentCardNetwork.MASTERCARD];
          console.log('createPaymentRequestDataDto', cloneDeep(createPaymentRequestDataDto));
          const paymentDataRequest: PaymentDataModel = this.googlePayService.createPaymentDataRequest(createPaymentRequestDataDto);
          console.log('paymentDataRequest', cloneDeep(paymentDataRequest));
          googlePayClient
            .loadPaymentData(paymentDataRequest as any)
            .then((paymentData: PaymentData) => {
              console.log('paymentData', cloneDeep(paymentData));
              const token: string | null =
                paymentData &&
                paymentData.paymentMethodData &&
                paymentData.paymentMethodData.tokenizationData &&
                paymentData.paymentMethodData.tokenizationData.token
                  ? paymentData.paymentMethodData.tokenizationData.token
                  : null;
              event.intPayMethodType = internalPaymentMethodType.googlePayToken;
              event.intPayMethodValue = btoa(token as string);
              subscriber.next(event);
              subscriber.complete();
            })
            .catch((err) => {
              event.abort = true;
              this.events.next(new ErrorPaymentProviderEvent(err));
              subscriber.next(event);
              subscriber.complete();
            });
          break;

        case PaymentMethodEnum.TYPE_APAY:
          const session = this.applePayService.getSession(this.paymentProviderConfig.merchantName, this.order.getPrice());

          session.onvalidatemerchant = () => {
            this.orderDataProvider.getApplePaySession(this.order.cinemaId, this.order.id, this.paymentProviderIdentifier).subscribe({
              next: (merchantSession) => {
                session.completeMerchantValidation(merchantSession);
              },
              error: (error: any) => {
                event.abort = true;
                this.events.next(new ErrorPaymentProviderEvent(error));
                subscriber.next(event);
                subscriber.complete();

                session.abort();
              },
            });
          };

          session.onpaymentauthorized = (paymentEvent) => {
            session.completePayment({
              status: ApplePaySession.STATUS_SUCCESS,
            });

            const token: string | null =
              paymentEvent && paymentEvent.payment && paymentEvent.payment.token && paymentEvent.payment.token.paymentData
                ? JSON.stringify(paymentEvent.payment.token.paymentData)
                : null;
            event.intPayMethodType = internalPaymentMethodType.applePayToken;
            event.intPayMethodValue = btoa(token as string);
            subscriber.next(event);
            subscriber.complete();
          };

          session.begin();
          break;

        default:
          event.abort = true;
          this.emitNotSelectedPaymentProvider();
          subscriber.next(event);
          subscriber.complete();
          break;
      }
    });
  }

  private getPayMethod(id: string) {
    return this.paymentProviderPayMethodCollection.find((x) => x.id && x.id.toLocaleLowerCase() === id);
  }

  private getTokenPayMethod(type: string) {
    return this.paymentProviderPayMethodCollection.find((x) => x.type && x.type.toLocaleLowerCase() === type);
  }

  public getBLIKPayMethod(): PaymentProviderPayMethodViewModel | null {
    return this.getPayMethod('blik');
  }

  public getBLIKTokenPayMethod(): PaymentProviderPayMethodViewModel | null {
    return this.getTokenPayMethod('blik');
  }

  public getCardPayMethod(): PaymentProviderPayMethodViewModel | null {
    return this.getPayMethod('c');
  }

  public getGooglePayPayMethod(): PaymentProviderPayMethodViewModel | null {
    return this.getPayMethod('ap');
  }

  public getApplePayPayMethod(): PaymentProviderPayMethodViewModel | null {
    if (navigator.appVersion.indexOf('Mac') !== -1) {
      return this.isApplePaySessionDefined && this.getPayMethod('jp');
    }
  }

  public getPayByLinkPayMethodCollection(): Array<PaymentProviderPayMethodViewModel> {
    return this.paymentProviderPayMethodCollection.filter((x) => x.type === 'PBL' && x.id !== 'ap' && x.id !== 'c' && x.id !== 'blik' && x.id != 'jp');
  }

  public getCardMethodCollection(): Array<PaymentProviderPayMethodViewModel> {
    return this.paymentProviderPayMethodCollection.filter((x) => x.type && x.type.toLocaleLowerCase() === 'card_token' && x.isPreferred);
  }

  public onClickedPayByLinkMethod(paymentProviderPayMethod: PaymentProviderPayMethodViewModel): void {
    this.selectedPayByLinkMethod = paymentProviderPayMethod;
    const state = this.selectedPaymentTypeService.getState();
    this.selectedPaymentTypeService.setState({ ...state, payByLink: paymentProviderPayMethod });
  }

  public onClickedCardMethod(paymentProviderPayMethod: PaymentProviderPayMethodViewModel): void {
    if (this.selectedCardMethod !== paymentProviderPayMethod) {
      this.selectedCardMethod = paymentProviderPayMethod;
    } else {
      this.selectedCardMethod = null;
    }
  }

  splitExpirationDate() {
    this.cardModel.cardExpirationMonth = this.cardModel.cardExpirationDate.substring(0, 2);
    this.cardModel.cardExpirationYear = this.cardModel.cardExpirationDate.substring(2);
    if (this.cardModel.cardExpirationYear.length < 4) {
      this.cardModel.cardExpirationYear = '20' + this.cardModel.cardExpirationYear;
    }
  }

  private initPaymentProviderWidget(): void {
    const paymentProviderConfigRequest: PaymentProviderConfigRequestModel = new PaymentProviderConfigRequestModel(
      this.order.cinemaId,
      this.order.id,
      this.paymentProviderIdentifier,
      this.paymentChannel
    );

    const paymentProviderPayMethodRequest: PaymentProviderPayMethodRequestModel = new PaymentProviderPayMethodRequestModel(
      this.order.cinemaId,
      this.order.id,
      this.paymentProviderIdentifier
    );

    const fork = forkJoin([
      this.orderDataProvider.getPaymentProviderConfig(paymentProviderConfigRequest),
      this.orderDataProvider.getPaymentProviderPayMethodCollection(paymentProviderPayMethodRequest),
    ]);

    this.paymentProviderInitSubscription = fork.subscribe({
      next: ([paymentProviderConfigModel, paymentProviderPayMethodCollection]) => {
        this.paymentProviderConfig = paymentProviderConfigModel;
        this.paymentProviderPayMethodCollection = paymentProviderPayMethodCollection;

        this.payByLinkMethodCollection = this.getPayByLinkPayMethodCollection();
        this.cardMethodCollection = this.getCardMethodCollection();

        this.events.next(new DonePaymentProviderEvent());
      },
      error: (e: HttpErrorResponse) => this.events.next(new ErrorPaymentProviderEvent(e)),
    });
  }

  canDisplayError(control: string) {
    const cfg = this.cardFormGroup.get(control);
    if (!cfg || !cfg.errors) return false;

    return !cfg.valid && ((cfg.touched && cfg.dirty) || this.formSubmitAttempt2);
  }
  canDisplayErrorGroup() {
    return !this.cardFormGroup.valid && ((this.cardFormGroup.touched && this.cardFormGroup.dirty) || this.formSubmitAttempt2);
  }

  private loadProviderScripts() {
    const urls = isDevMode()
      ? [
          { src: 'https://secure.snd.payu.com/res/v2/openpayu-2.1.js', async: true },
          { src: 'https://secure.snd.payu.com/res/v2/plugin-token-2.1.js', async: true },
        ]
      : [
          { src: 'https://secure.payu.com/res/v2/openpayu-2.1.js', async: true },
          { src: 'https://secure.payu.com/res/v2/plugin-token-2.1.js', async: true },
        ];

    loadScriptSequentially(this.renderer, urls, 0, () => {
      this.initialComponent();
    });
  }

  get getDefaultPaymentImageUrl() {
    return `/assets/images/payment-providers/PAYU_lime_logo.png`;
  }

  private emitNotSelectedPaymentProvider() {
    this.events.emit(new ErrorPaymentKeyProviderEvent(generalPaymentError.NotSelectedPaymentProvider));
  }
}
