import { ComponentRef, Injectable } from '@angular/core';
import { BehaviorSubject, distinctUntilChanged, Observable } from 'rxjs';
import { FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { PaymentProviderComponentInterface } from '../proxy/provider/payment-provider.component.interface';
import { PaymentProviderPayMethodViewModel } from 'libs/core/src/lib/model/view-model/payment-provider-pay-method.view.model';
import * as _ from 'lodash';

export interface PaymentProviderStateInterface {
  type: string;
  provider: string;
  payByLink: PaymentProviderPayMethodViewModel | null;
}

@Injectable({
  providedIn: 'root',
})
export class PaymentProviderStateService {
  selectedProvider: PaymentProviderStateInterface;

  constructor(private fb: FormBuilder) {
    this.state$ = this.state.asObservable();
  }

  private state = new BehaviorSubject<PaymentProviderStateInterface>(null);
  public state$: Observable<PaymentProviderStateInterface>;
  private providerComponentCollector: { [identifier: string]: ComponentRef<PaymentProviderComponentInterface> } = {};
  public formSubmitAttempt = false;

  private blikFormGroup = this.fb.group({
    blikCode: [null, [Validators.required, Validators.minLength(6), Validators.maxLength(6)]],
  });

  private payuCardFormGroup = this.fb.group({
    cardNumber: [null, [Validators.required, Validators.minLength(16), Validators.maxLength(16)]],
    cardExpirationDate: [null, [Validators.required, this.validateExpirationDate]],
    cardCVV: [null, [Validators.required, Validators.minLength(3), Validators.maxLength(4)]],
  });

  private p24BlikFormGroup = this.fb.group({
    blikCode: [null, [Validators.required, Validators.minLength(6), Validators.maxLength(6)]],
    saveToken: [null],
  });

  private p24CardWithTokenizeGroup = this.fb.group({
    saveToken: [null],
  });

  private p24RulesFormGroup = this.fb.group({
    regulationAccept: [null, [Validators.required]],
  });

  private formGroup = new FormGroup({
    accountNumber: new FormControl('', [Validators.required, Validators.maxLength(16)]),
  });

  private basysFormGroup = new FormGroup({
    cardNumber: new FormControl('', [Validators.required, Validators.maxLength(16)]),
    expiration: new FormControl('', [Validators.required, Validators.pattern('^(0+[1-9]|1[012])/[0-9]{2}$')]),
    cvv: new FormControl('', [Validators.required, Validators.pattern('^[0-9]{3,4}$')]),
    billingAddress: new FormControl('', [Validators.required, Validators.maxLength(200)]),
    billingPostal: new FormControl('', [Validators.required, Validators.minLength(4), Validators.maxLength(7)]),
    nameOnCard: new FormControl('', [Validators.required, Validators.maxLength(100)]),
  });

  private static numberIsValid(control: FormControl): ValidationErrors | null {
    let sum = 0;
    let alt = false;
    const number: string = control.value.replace(/\s/g, '');

    if (number.length !== 16) {
      return { custom: 'Invalid card number' };
    }

    for (let i = number.length - 1; i >= 0; i--) {
      let temp: number = parseInt(number[i]);

      if (alt) {
        temp *= 2;
        if (temp > 9) {
          temp -= 9;
        }
      }
      sum += temp;
      alt = !alt;
    }

    return sum % 10 === 0 ? null : { custom: 'Invalid card number' };
  }

  public setState(state: PaymentProviderStateInterface) {
    if (_.isEqual(this.selectedProvider, state)) {
      this.selectedProvider = null;
      this.state.next(null);
    } else {
      this.selectedProvider = state;
      this.state.next(state);
    }
  }

  public addAvailablePaymentProvider(component: ComponentRef<PaymentProviderComponentInterface>, providerIdentifier: string) {
    if (!this.providerComponentCollector[providerIdentifier]) {
      this.providerComponentCollector[providerIdentifier] = component;
    }
  }

  public clearState() {
    this.providerComponentCollector = {};
    this.state.next(null);
  }

  public getProperComponent(providerIdentifier: string): ComponentRef<PaymentProviderComponentInterface> {
    return this.providerComponentCollector[providerIdentifier];
  }

  public getState(): PaymentProviderStateInterface {
    return this.state.getValue();
  }

  public getIntercardForm() {
    this.formSubmitAttempt = false;
    return this.formGroup;
  }

  public getBasysFormGroup() {
    this.formSubmitAttempt = false;
    return this.basysFormGroup;
  }

  public getPayuCardFormGroup() {
    return this.payuCardFormGroup;
  }

  public getBlikFormGroup() {
    return this.blikFormGroup;
  }

  public getPrzelewy24RulesFormGroup() {
    return this.p24RulesFormGroup;
  }

  public getPrzelewy24BlikFormGroup() {
    return this.p24BlikFormGroup;
  }

  public getPrzelewy24CardWithTokenizeGroup() {
    return this.p24CardWithTokenizeGroup;
  }

  validateExpirationDate(control: FormControl): ValidationErrors | null {
    if (control.parent && control.value) {
      const expirationMonth = parseInt(control.value.substring(0, 2));
      if (expirationMonth < 1 || expirationMonth > 12) {
        return { custom: 'Nie ma takiego miesiąca' };
      }
      if ([4, 6].indexOf(control.value.length) < 0) {
        return { custom: 'Niepoprawny format' };
      }
    }
    return null;
  }
}
