import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { WeekdayService } from '../service/weekday.service';
import { DateTime } from 'luxon';
import { DateTimeLocaleDataService } from '../service/datetime-locale-data.service';
import { SupportedDateTimeFormat } from '../locale-data/supported-datetime-format.enum';
import { SupportedDateTimeLocale } from '../locale-data/supported-datetime-locale.enum';
import { SQ_XK_LocaleData } from '../locale-data/sq-xk.locale-data';

@Pipe({
  name: 'extendedDate',
})
export class ExtendedDatePipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) protected localeId: string, protected localeDataService: DateTimeLocaleDataService, private weekdayService: WeekdayService) {}

  transform(
    value: DateTime | Date | string | number,
    format?: string,
    addRelativeCalendarDays: boolean = false,
    relativeCalendarDaysCount: number = 2
  ): string | null {
    let date: DateTime;
    switch (typeof value) {
      case 'string':
        date = DateTime.fromISO(value);
        break;
      case 'number':
        date = DateTime.fromMillis(value);
        break;
      default:
        date = value instanceof Date ? DateTime.fromJSDate(value) : value;
        break;
    }

    if (!(date instanceof DateTime) || !date.isValid) {
      return null;
    }

    return `${addRelativeCalendarDays ? this.preText(date, relativeCalendarDaysCount) : ''}${this.localeDataService.transform(
      date,
      format as SupportedDateTimeFormat
    )}`;
  }

  preText(value: DateTime, relativeCalendarDaysCount?: number) {
    const now = DateTime.local().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    if (value >= now && now >= value.minus({ days: relativeCalendarDaysCount })) {
      return `${this.capitalized(this.toRelative(value))}, `;
    }

    return '';
  }

  capitalized(input: string): string {
    if (!input) {
      return '';
    }

    return `${input.charAt(0).toUpperCase()}${input.slice(1)}`;
  }

  toRelative(date: DateTime): string {
    switch (this.localeId) {
      case SupportedDateTimeLocale.sq_XK:
        return this.formatRelative(date, new SQ_XK_LocaleData(this.weekdayService).localeData);
      default:
        return date.toRelativeCalendar({ locale: this.localeId });
    }
  }

  formatRelative(date: DateTime, localeData): string {
    const now = DateTime.now().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    const diff = date.set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).diff(now, ['years', 'months', 'days', 'hours', 'minutes', 'seconds']).toObject();

    const absDiff = (x: number) => Math.abs(Math.round(x));

    const getFormatString = (unit: string, count: number) => {
      if (count === 1) {
        return localeData.relativeTime[unit];
      } else {
        return localeData.relativeTime[unit].replace('{count}', count.toString());
      }
    };

    if (now.hasSame(date, 'day')) {
      return localeData.relativeTime.today;
    }

    if (now.plus({ days: 1 }).hasSame(date, 'day')) {
      return localeData.relativeTime.tomorrow;
    }

    if (diff.days) {
      const diffs = absDiff(diff.days);
      return date > now
        ? localeData.relativeTime.future.replace('%s', getFormatString(diffs > 1 ? 'dd' : 'd', diffs))
        : localeData.relativeTime.past.replace('%s', getFormatString(diffs > 1 ? 'dd' : 'd', diffs));
    }
  }
}
