export enum ScreeningPeriodEnum {
  DAY = 0,
  WEEK = 6,
  MONTH = 30,
  TWO_MONTHS = 60,
  QUARTER = 90,
  HALF_YEAR = 180,
  YEAR = 365,
}

export function mapDaysToScreeningPeriodEnum(days: string | number): ScreeningPeriodEnum {
  if (typeof days === 'string') {
    days = +days;
  }

  switch (days) {
    case 0:
      return ScreeningPeriodEnum.DAY;
    case 6:
      return ScreeningPeriodEnum.WEEK;
    case 30:
      return ScreeningPeriodEnum.MONTH;
    case 60:
      return ScreeningPeriodEnum.TWO_MONTHS;
    case 90:
      return ScreeningPeriodEnum.QUARTER;
    case 180:
      return ScreeningPeriodEnum.HALF_YEAR;
    case 365:
      return ScreeningPeriodEnum.YEAR;
    default:
      throw new Error('Invalid number of days');
  }
}
