import { Expose } from 'class-transformer';

export class CardFieldTemplateApiModel {
  @Expose()
  symbolId: number;

  @Expose()
  field: string;

  @Expose()
  requiredPosition: number;
}
