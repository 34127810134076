import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Injector, ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { ChoiceSliderComponent } from './component/choice-slider/choice-slider.component';
import { LightboxComponent } from './component/lightbox/lightbox.component';
import { FormErrorsDirective } from './directive/form-errors.directive';
import { LazyImgDirective } from './directive/lazy-img/lazy-img.directive';
import { SmoothInDirective } from './directive/smooth-in/smooth-in.directive';
import { ENVIRONMENT_TOKEN } from './injection.tokens';
import { AcceptLanguageInterceptor } from './interceptor/accept-language.interceptor';
import { JwtRefreshTokenInterceptor } from './interceptor/jwt-refresh-token.interceptor';
import { JwtTokenInterceptor } from './interceptor/jwt-token.interceptor';
import { RequestInterceptor } from './interceptor/request.interceptor';
import { ResponseInterceptor } from './interceptor/response.interceptor';
import { DateTimePipe } from './pipe/date-time.pipe';
import { MoneyPipe } from './pipe/money.pipe';
import { OrderByPipe } from './pipe/order-by.pipe';
import { TranslateSelectorPipe } from './pipe/translate-selector.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { ScreenTicketCountSelectorComponent } from './component/screen/ticket-count-selector/ticket-count-selector.component';
import { TicketCountGeneralAdmissionComponent } from './component/screen/ticket-count-general-admission/ticket-count-general-admission.component';
import { ScreenSeatsSelectedListComponent } from './component/screen/seats-selected-list/seats-selected-list.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimeParsePipe } from './pipe/time-parse.pipe';
import { EncodeUriPipe } from './pipe/encode-uri.pipe';
import { PinchZoomComponent } from './component/pinch-zoom/pinch-zoom.component';
import { SortPipe } from './pipe/sort.pipe';
import { VarDirective } from './directive/ng-var.directive';
import { CarouselSwipeDirective } from './directive/carousel-swipe.directive';
import { CarouselModule as OwlCarouselModule } from 'ngx-owl-carousel-o';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { ZoomAbleDirective } from './directive/zoomable/zoom-able.directive';
import { LegendPipe } from './pipe/legend.pipe';
import { PasswordInputComponent } from './component/password-input/password-input.component';
import { DividerComponent } from './component/divider/divider.component';
import { SafePipe } from './pipe/safe.pipe';
import { EvenOddPipe } from './pipe/evenodd.pipe';
import { SizeDetectorComponent } from './component/size-detector/size-detector.component';
import { RerenderDirective } from './directive/rerender.directive';
import { PlayWatermarkDirective } from './directive/play-watermark/play-watermark.directive';
import { FilterCallbackPipe } from './pipe/filter-callback.pipe';
import { DigitDirective } from './directive/digit-only.directive';
import { FieldJumperDirective } from './directive/field-jumper.directive';
import { HideOnErrorDirective } from './directive/hide-on-error.directive';
import { JoinPipe } from './pipe/join.pipe';
import { PlaceholderImgDirective } from './directive/placeholder-img/placeholder-img.directive';
import { SortAndFilterPipe } from './pipe/sort-and-filter.pipe';
import { AddLanguagePipe } from './pipe/add-lang.pipe';
import { ExtendedDatePipe } from './pipe/extended-date.pipe';
import { BreakTextPipe } from './pipe/replace-new-line';
import { ReplaceClassDirective } from './directive/replace-class.directive';

@NgModule({
  declarations: [
    DateTimePipe,
    ExtendedDatePipe,
    LazyImgDirective,
    PlaceholderImgDirective,
    PlayWatermarkDirective,
    FormErrorsDirective,
    TranslateSelectorPipe,
    ChoiceSliderComponent,
    OrderByPipe,
    MoneyPipe,
    SmoothInDirective,
    LightboxComponent,
    ScreenTicketCountSelectorComponent,
    TicketCountGeneralAdmissionComponent,
    ScreenSeatsSelectedListComponent,
    TimeParsePipe,
    EncodeUriPipe,
    PinchZoomComponent,
    SortPipe,
    SortAndFilterPipe,
    VarDirective,
    CarouselSwipeDirective,
    ZoomAbleDirective,
    LegendPipe,
    PasswordInputComponent,
    DividerComponent,
    PasswordInputComponent,
    DividerComponent,
    SafePipe,
    EvenOddPipe,
    SizeDetectorComponent,
    RerenderDirective,
    FilterCallbackPipe,
    DigitDirective,
    FieldJumperDirective,
    HideOnErrorDirective,
    JoinPipe,
    AddLanguagePipe,
    BreakTextPipe,
    ReplaceClassDirective,
  ],
  imports: [CommonModule, TranslateModule.forChild(), TooltipModule.forRoot(), BsDatepickerModule.forRoot(), OwlCarouselModule, CarouselModule.forRoot()],
  providers: [
    ExtendedDatePipe,
    //   {
    //     provide: 'GoogleTagManagerService',
    //     useFactory: (injector: Injector) => {
    //       const env = injector.get(ENVIRONMENT_TOKEN);
    //       if (env['cms']) {
    //         return new CmsGoogleTagManagerService();
    //       } else {
    //         return new GoogleTagManagerService();
    //       }
    //     },
    //     deps: [Injector],
    //   },
  ],
  exports: [
    DateTimePipe,
    ExtendedDatePipe,
    LazyImgDirective,
    PlaceholderImgDirective,
    PlayWatermarkDirective,
    FormErrorsDirective,
    TranslateSelectorPipe,
    ChoiceSliderComponent,
    OrderByPipe,
    MoneyPipe,
    SmoothInDirective,
    LightboxComponent,
    BsDatepickerModule,
    TimeParsePipe,
    EncodeUriPipe,
    PinchZoomComponent,
    SortPipe,
    SortAndFilterPipe,
    VarDirective,
    CarouselSwipeDirective,
    OwlCarouselModule,
    CarouselModule,
    ZoomAbleDirective,
    LegendPipe,
    PasswordInputComponent,
    DividerComponent,
    PasswordInputComponent,
    DividerComponent,
    SafePipe,
    EvenOddPipe,
    SizeDetectorComponent,
    RerenderDirective,
    FilterCallbackPipe,
    DigitDirective,
    FieldJumperDirective,
    HideOnErrorDirective,
    JoinPipe,
    AddLanguagePipe,
    BreakTextPipe,
    ReplaceClassDirective,
  ],
})
export class CoreLibraryModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreLibraryModule, private injector: Injector) {
    if (parentModule) {
      throw new Error('CoreLibraryModule is already loaded. Import it in the AppModule only');
    }
  }

  public static forRoot(environment: any): ModuleWithProviders<CoreLibraryModule> {
    return {
      ngModule: CoreLibraryModule,
      providers: [
        {
          provide: ENVIRONMENT_TOKEN,
          useValue: environment,
        },
      ],
    };
  }

  public static forRootWithInterceptors(environment: any): ModuleWithProviders<CoreLibraryModule> {
    return {
      ngModule: CoreLibraryModule,
      providers: [
        {
          provide: ENVIRONMENT_TOKEN,
          useValue: environment,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: JwtTokenInterceptor,
          multi: true,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: JwtRefreshTokenInterceptor,
          multi: true,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: AcceptLanguageInterceptor,
          multi: true,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: RequestInterceptor,
          multi: true,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: ResponseInterceptor,
          multi: true,
        },
      ],
    };
  }
}
