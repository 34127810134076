import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { CheckListItemModel } from 'libs/core/src/lib/model/checklistitem.model';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ModalEventEmitter, ModalStatusEnum } from '../../modal-status.enum';

@Component({
  template: '',
})
export class RefundModalComponent implements OnInit {
  @Output() event = new EventEmitter<ModalEventEmitter>();
  @Input() public content: CheckListItemModel[];
  public checkAllModel: CheckListItemModel = new CheckListItemModel('checkAllCheckbox', '');
  @Input() public lang: string;
  public acceptDisabled = true;
  public hasRefundableItems = true;

  constructor(protected bsModalRef: BsModalRef) {}

  private get refundableItems() {
    return this.content.filter((o) => o.content.isRefundable);
  }

  private verifyState() {
    this.checkAllModel.checked = this.content != null && this.refundableItems.every((t) => t.checked);
    this.acceptDisabled = !this.content.some((o) => o.checked && o.content);
  }

  ngOnInit() {
    this.hasRefundableItems = this.refundableItems.length > 0;
  }

  close() {
    this.event.emit({ state: ModalStatusEnum.close });
    this.bsModalRef.hide();
  }

  accept() {
    this.event.emit({ state: ModalStatusEnum.accept });
    this.bsModalRef.hide();
  }

  checkAll() {
    if (this.content == null) {
      return;
    }
    this.refundableItems.forEach((t) => (t.checked = this.checkAllModel.checked));
    this.verifyState();
  }

  checkGroup(model: CheckListItemModel) {
    this.content.forEach((item) => {
      if (item.group === model.group && item.checked !== model.checked) {
        item.checked = model.checked;
      }
    });

    this.verifyState();
  }
}
