import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { storageKey } from 'libs/core/src/app.const';
import { ENVIRONMENT_TOKEN } from 'libs/core/src/public-api';
import { CountdownComponentService } from 'libs/core/src/lib/service/countdown.service';
import { StateService } from 'libs/core/src/lib/state/state.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { LoadingService } from 'libs/core/src/lib/service/loading.service';
import { LoaderEnum } from 'libs/core/src/lib/enum/loader.enum';
import { filter } from 'rxjs';

@Component({
  template: '',
})
export class MessagePageComponent implements OnInit, AfterViewInit {
  public id: string = null;
  public message: any = null;

  constructor(
    @Inject(ENVIRONMENT_TOKEN) protected environment: any,
    protected router: Router,
    protected route: ActivatedRoute,
    protected translateService: TranslateService,
    protected countdownComponentService: CountdownComponentService,
    protected stateService: StateService,
    protected modalService: BsModalService,
    protected loadingService: LoadingService
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.id = params.id;
      this.prepareMessage(this.id);
    });
    this.countdownComponentService.hide();
    this.modalService.hide();
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      this.loadingService.hideLoader(LoaderEnum.MAIN);
    });
  }

  ngAfterViewInit(): void {
    this.loadingService.hideLoader(LoaderEnum.MAIN);
  }

  private prepareMessage(id: string) {
    this.translateService.get(`message.${id}`).subscribe({
      next: (res: Object) => (this.message = res),
      error: (e) => this.translateService.get(`message.default`).subscribe((res: Object) => (this.message = res)),
    });
  }

  goToRepertoire() {
    const url = this.stateService.getItem(storageKey.backUrl) ? this.stateService.getItem(storageKey.backUrl) : this.environment.backUrl;
    window.location.href = url;
  }
}
