export enum QuickFilterType {
  COPY = 'copy',
  FOURDX = '4dx',
  FAMILY = 'family',
  EVENT = 'event',
  IMAX = 'imax',
  FIRSTLOUNGE = 'firstlounge',
  SCREENX = 'screenx',
  GOLDCLASS = 'goldclass',
  ULTIMATE = 'ultimate',
  EXTREME = 'extreme',
  KIDS = 'kids',
  DUBBED = 'dub',
  VIP = 'vip',
  SUBTITLES = 'subtitles',
  GTX = 'gtx',
  OPENCAPTION = 'opencaption',
  TAGGROUPS = 'taggroups',
  TAGS = 'tags',
  LANGUAGES = 'languages',
  CINEMAS = 'cinemas',
  GENRES = 'genres',
  WHEELCHAIRFRIENDLY = 'wheelchair-friendly',
  CLOSEDCAPTION = 'closedcaption',
  _2D = '2d',
  _3D = '3d',
}

export enum QuickFilterGroup {
  PRINT_TYPE = 1,
  SCREEN_FEATURE = 2,
  LANGUAGE = 3,
  SPEAKING_TYPE = 4,
  RELEASE = 5,
  CINEMA = 6,
  GENRE = 7,
  TAG = 8,
  CUSTOM = 99,
}
