import { Component, Inject, Input } from '@angular/core';
import { ScreeningWithTickets } from 'libs/core/src/lib/model/screening-with-tickets';
import { AccountItemsViewModel } from 'libs/core/src/lib/model/view-model/account-items/account-items.view.model';
import { ScreeningWithTicketsAdapter } from 'libs/core/src/lib/model/view-model/account-items/screening-with-tickets-adapter';
import { ENVIRONMENT_TOKEN } from 'libs/core/src/public-api';

@Component({
  template: '',
})
export class UserUpcomingComponent {
  userAccountItems: AccountItemsViewModel;
  upcomingScreenings: ScreeningWithTickets[] = [];

  @Input() set accountItems(accountItems: AccountItemsViewModel) {
    this.userAccountItems = accountItems;
    this.upcomingScreenings = this.screeningWithTicketsAdapter.adapt(this.userAccountItems?.reservations);
  }

  constructor(@Inject(ENVIRONMENT_TOKEN) protected environment: any, private screeningWithTicketsAdapter: ScreeningWithTicketsAdapter) {}
}
