import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OrderDataProvider } from 'libs/core/src/lib/data-provider/order.data-provider';
import { UserDataProvider } from 'libs/core/src/lib/data-provider/user.data-provider';
import { LoaderEnum } from 'libs/core/src/lib/enum/loader.enum';
import { LoginHelper } from 'libs/core/src/lib/helper/login.helper';
import { OrderHelper } from 'libs/core/src/lib/helper/order.helper';
import { LoadingStatus } from 'libs/core/src/lib/model/loading/loading-status.enum';
import { UserLoginRequestModel } from 'libs/core/src/lib/model/request/user-login.request.model';
import { LoadingService } from 'libs/core/src/lib/service/loading.service';
import { AuthStateService } from 'libs/core/src/lib/state/auth.state.service';
import { OrderStateService } from 'libs/core/src/lib/state/order.state.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { filter, switchMap, tap } from 'rxjs';
import { FormDataBuilderInterface } from './interface/form-data.builder.interface';
import { OrderViewModel } from 'libs/core/src/lib/model/view-model/order/order.view.model';
import { ErrorHandlerService } from 'libs/core/src/lib/service/error-handler/error-handler.service';
import { validationPattern } from '@lib/core';

@UntilDestroy()
@Component({
  template: '',
})
export abstract class LoginComponent implements OnInit {
  loaderEnum: typeof LoaderEnum = LoaderEnum;
  loadingStatusEnum: typeof LoadingStatus = LoadingStatus;
  loadingStatus: LoadingStatus;

  public loginForm: FormGroup;
  public formSubmitAttempt = false;
  public errorCode = null;

  @Input() modal = false;
  @Output() registerClicked = new EventEmitter();
  @Output() remindPasswordProcessState = new EventEmitter();

  constructor(
    protected authStateService: AuthStateService,
    protected userDataProvider: UserDataProvider,
    protected orderDataProvider: OrderDataProvider,
    protected orderStateService: OrderStateService,
    protected loadingService: LoadingService,
    protected modalService: BsModalService,
    protected orderHelper: OrderHelper,
    protected errorHandlerService: ErrorHandlerService
  ) {
    this.createForm();
  }

  ngOnInit(): void {
    this.loginForm = this.getFrom();

    this.authStateService.state$
      .pipe(
        filter(() => this.authStateService.userIsLoggedAndTokenIsValid()),
        switchMap(() => this.orderStateService.state$),
        filter((o: OrderViewModel) => !!o),
        tap(() => {
          this.loadingStatus = LoadingStatus.pending;
          this.loadingService.showLoader(LoaderEnum.MAIN);
        })
      )
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (order) => {
          const user = this.authStateService.getUser();
          const updateModel = order.toApiModel();
          updateModel.userFirstName = user?.firstName;
          updateModel.userLastName = user?.lastName;
          updateModel.userPhone = user?.phone;
          updateModel.userEmail = user?.email;

          this.orderHelper.update(order.cinemaId, updateModel).subscribe(() => {
            this.loadingStatus = LoadingStatus.success;
            this.loadingService.hideLoader(LoaderEnum.MAIN);
          });
        },
      });
  }

  private createForm(): void {
    this.loginForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.pattern(validationPattern.email)]),
      password: new FormControl('', [Validators.required]),
      rememberMe: new FormControl(false),
    });
  }

  getFrom(): FormGroup {
    const initialFormData: FormDataBuilderInterface = {};
    return this.getFormGroup(initialFormData);
  }

  public onSubmit(): void {
    this.formSubmitAttempt = true;
    const passwordField = this.loginForm.controls['password'];
    if (passwordField && !passwordField.hasValidator(Validators.required)) {
      passwordField.addValidators(Validators.required);
      passwordField.updateValueAndValidity();
    }

    if (!this.loginForm.valid) {
      return;
    }

    this.loadingStatus = LoadingStatus.pending;
    this.loadingService.showLoader(LoaderEnum.LOGIN);

    const userLoginResponseModel: UserLoginRequestModel = new UserLoginRequestModel();
    userLoginResponseModel.username = this.loginForm.get('email').value;
    userLoginResponseModel.password = this.loginForm.get('password').value;
    userLoginResponseModel.rememberMe = this.loginForm.get('rememberMe').value;

    this.authStateService.login(userLoginResponseModel).subscribe({
      error: (error: HttpErrorResponse) => {
        this.loadingStatus = LoadingStatus.failed;
        this.loadingService.hideLoader(LoaderEnum.LOGIN);

        const err = this.errorHandlerService.getError(error);
        this.errorCode = LoginHelper.getErrorMessage(err?.code);
      },
      complete: () => {
        this.loadingStatus = LoadingStatus.success;
        this.loadingService.hideLoader(LoaderEnum.LOGIN);
      },
    });
  }

  abstract getFormGroup(formDataBuilderInterface: FormDataBuilderInterface): FormGroup;

  registerClick() {
    this.registerClicked.emit();
  }

  showForgotPasswordPopup(template: TemplateRef<any>) {
    this.remindPassword(() => {
      this.modalService.show(template);
    });
  }

  remindPassword(callback?: any) {
    this.formSubmitAttempt = true;

    const emailField = this.loginForm.controls['email'];
    if (!emailField?.value) {
      emailField.markAsTouched();
      emailField.setErrors({ required: true });
    }

    const passwordField = this.loginForm.controls['password'];
    if (passwordField && passwordField.hasValidator(Validators.required)) {
      passwordField.removeValidators(Validators.required);
      passwordField.setErrors(null);
    }

    this.userDataProvider.remindPassword(emailField?.value).subscribe({
      next: () => {
        if (callback) {
          callback();
        }
        this.remindPasswordProcessState.emit(true);
      },
      error: (error: HttpErrorResponse) => {
        const err = this.errorHandlerService.getError(error);
        if (err.code === '2') {
          emailField.setErrors({ invalid: true });
        }

        this.remindPasswordProcessState.emit(false);
      },
    });
  }
}
