import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ResponseValidatorService } from '../service/validator/response-validator.service';

@Injectable({
  providedIn: 'root',
})
export class ResponseInterceptor implements HttpInterceptor {
  constructor(private responseValidatorService: ResponseValidatorService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.indexOf('.json') === -1) {
      return next.handle(req).pipe(
        catchError((err: any) => {
          if (err instanceof HttpErrorResponse) {
            this.responseValidatorService.handleHttpError(err);
          } else if (err instanceof Error) {
            this.responseValidatorService.handleOtherErrors(err);
          }

          return throwError(() => err);
        })
      );
    }

    return next.handle(req);
  }
}
