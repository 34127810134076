import { UserHttpService } from '../http/user.http.service';
import { Injectable } from '@angular/core';
import { instanceToPlain, plainToInstance } from 'class-transformer';
import { UserLoginRequestModel } from '../model/request/user-login.request.model';
import { forkJoin, Observable } from 'rxjs';
import { UserLoginResponseModel } from '../model/response/user-login.response.model';
import { HttpHeaders } from '@angular/common/http';
import { map, mergeMap, tap } from 'rxjs/operators';
import { UserAgreementHttpService } from '../http/user.agreement.http.service';
import { LanguageHttpService } from '../http/language.http.service';
import { UserTokenResponseModel } from '../model/response/user-token.response.model';
import { ScreeningType } from '../enum/screening-type';
import { CmsHelper } from '../helper/cms.helper';
import { AgreementAggregationApiModel } from '../model/api-model/agreement/agreement-aggregation.api.model';
import { ProgramApiModel } from '../model/api-model/member-get-member/program.api.model';
import { UserDeletionReasonApiModel } from '../model/api-model/user/user-deletion-reason.api.model';
import { UserFavotiePaymentApiModel } from '../model/api-model/user/user-favorite-payment.api.model';
import { UserWatchlistRequestModel } from '../model/api-model/user/user-watchlist.request.model';
import { UserApiModel } from '../model/api-model/user/user.api.model';
import { AgreementType } from '../model/enum/agreement-type.enum';
import { PickupTimeStatusViewModel } from '../model/view-model/pickup-time-status.view.model';
import { ItemTransferRequestModel } from '../model/request/item-transfer.request.model';
import { UserTwoFactorAuthenticationRequestModel } from '../model/request/user-2fa.request.model';
import { UserAuthRequestModel } from '../model/request/user-auth.request.model';
import { UserDeleteRequestModel } from '../model/request/user-delete.request.model';
import { UserPreferencesRequestModel } from '../model/request/user-preferences.request.model';
import { UserReferralRecomendationdRequestModel } from '../model/request/user-referral-recommendation.request.model';
import { UserTokenRequestModel } from '../model/request/user-token.request.model';
import { AccountItemsViewModel } from '../model/view-model/account-items/account-items.view.model';
import { AgreementAggregationViewModel } from '../model/view-model/agreement/agreement-aggregation.view.model';
import { LanguageViewModel } from '../model/view-model/language/language.view.model';
import { ProgramViewModel } from '../model/view-model/member-get-member/program.view.model';
import { PromotionConditionViewModel } from '../model/view-model/member-get-member/promotion-condition.view.model';
import { UserFavoritePaymentViewModel } from '../model/view-model/user/user-favorite-payment.view.model';
import { UserPreferencesViewModel } from '../model/view-model/user/user-preferences.view.model';
import { UserViewModel } from '../model/view-model/user/user.view.model';
import { UserDeletionReasonViewModel } from '../model/view-model/user/user-deletion-reason.view.model';
import { UserWatchlistViewModel } from '../model/view-model/user/user-watchlist.view.model';
import { UserHistoryViewModel } from '../model/view-model/user-history/user-history.view.model';
import { CinemaViewModel } from '../model/view-model/cinema/cinema.view.model';
import { UserSetpasswordRequestModel } from '../model/request/user-setpassword.request.model';
import { GenreViewModel } from '../model/view-model/genre/genre.view.model';
import { UserHistoryRequestModel } from '../model/request/user-history.request.model';
import { storageKey } from '@lib/core';

@Injectable({
  providedIn: 'root',
})
export class UserDataProvider {
  constructor(
    private userHttpService: UserHttpService,
    private userAgreementHttpService: UserAgreementHttpService,
    private languageHttpService: LanguageHttpService,
    private cmsHelper: CmsHelper
  ) {}

  public login(request): Observable<UserLoginResponseModel> {
    return this.userHttpService.login(plainToInstance(UserLoginRequestModel, request as Object));
  }

  public loginByToken(request): Observable<UserTokenResponseModel> {
    return this.userHttpService.loginByToken(plainToInstance(UserTokenRequestModel, request as Object));
  }

  public doAuth(authProvider: string, token: string, state: string | null = null, redirectUri: string | null = null): Observable<UserLoginResponseModel> {
    return this.userHttpService.doAuth(
      authProvider,
      plainToInstance(UserAuthRequestModel, {
        token: token,
        state: state,
        redirect_uri: redirectUri,
      } as Object)
    );
  }

  public doAuthByToken(authProvider: string, token: string, state: string = null): Observable<UserTokenResponseModel> {
    return this.userHttpService.doAuthByToken(authProvider, token, state);
  }

  public getAuthRedirect(authProvider: string, redirectUrl: string): Observable<string> {
    return this.userHttpService.getAuthRedirect(authProvider, redirectUrl);
  }

  public update(request: UserApiModel, token: string): Observable<UserViewModel> {
    return this.updateWithApiModel(request, token).pipe(map((res: UserApiModel) => new UserViewModel(res)));
  }

  public updateWithApiModel(request: UserApiModel, token: string): Observable<UserApiModel> {
    const params = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${token}`,
      }),
    };

    return this.userHttpService.update(request, params);
  }

  public remove(requestModel: UserDeleteRequestModel): Observable<any> {
    return this.userHttpService.remove(requestModel);
  }

  public create(request: UserViewModel): Observable<UserViewModel> {
    return this.userHttpService.create(request.toApiModel(), undefined).pipe(map((res: UserApiModel) => new UserViewModel(res)));
  }

  public get(token: string): Observable<UserViewModel> {
    return this.userHttpService.getCurrent(token).pipe(map((res: UserApiModel) => new UserViewModel(res)));
  }

  public authMethod(): Observable<string[]> {
    return this.userHttpService.authMethod();
  }

  public deleteAuthMethod(method: string): Observable<Object> {
    return this.userHttpService.deleteAuthMethod(method);
  }

  public getHistoryViaApiModel(request?: UserHistoryRequestModel): Observable<UserHistoryViewModel> {
    return this.userHttpService.getHistoryViaApiModel(request).pipe(map((result) => new UserHistoryViewModel(result)));
  }

  public pickupTimeStatus(cinemaId: string, orderId: string): Observable<PickupTimeStatusViewModel> {
    return this.userHttpService
      .pickupTimeStatus(cinemaId, orderId)
      .pipe(map((result) => plainToInstance(PickupTimeStatusViewModel, instanceToPlain(result) as Object)));
  }

  public prepareFood(cinemaId: string, orderId: string): Observable<any> {
    return this.userHttpService.prepareFood(cinemaId, orderId);
  }

  public getFavouriteCinemaList(): Observable<Array<CinemaViewModel>> {
    return this.userHttpService.getFavouriteCinemaList().pipe(map((result) => result.map((m) => new CinemaViewModel(m))));
  }

  public setFavouriteCinemaList(cinemaIdList: string[]): Observable<Array<CinemaViewModel>> {
    return this.userHttpService.setFavoriteCinemaList(cinemaIdList).pipe(map((result) => result.map((m) => new CinemaViewModel(m))));
  }

  public getFavouriteGenreList(): Observable<Array<GenreViewModel>> {
    return this.userHttpService.getFavouriteGenreList().pipe(map((result) => result.map((m) => new GenreViewModel(m))));
  }

  public setFavouriteGenreList(genreIdList: string[]): Observable<Array<GenreViewModel>> {
    return this.userHttpService.setFavoriteGenreList(genreIdList).pipe(map((result) => result.map((m) => new GenreViewModel(m))));
  }

  public getAgreements(agreementType: AgreementType = AgreementType.ALL, orderAgreementId?: string): Observable<AgreementAggregationViewModel> {
    const source = this.userAgreementHttpService.getAgreements();

    if (agreementType === AgreementType.ALL) {
      return source.pipe(map((result: AgreementAggregationApiModel) => new AgreementAggregationViewModel(result)));
    }

    if (this.cmsHelper.canUseCms) {
      return source.pipe(
        map((aggrements) => {
          switch (agreementType) {
            case AgreementType.COMMON:
              aggrements.marketingAgreements = aggrements.marketingAgreements.filter((o) => o.id !== orderAgreementId);
              break;
            case AgreementType.ORDER:
              aggrements.marketingAgreements = aggrements.marketingAgreements.filter((o) => o.id === orderAgreementId);
              break;
          }

          aggrements.marketingAgreementGroups = aggrements.marketingAgreementGroups.filter((g) =>
            aggrements.marketingAgreements.reduce((x, y) => [...x, ...y.marketingAgreementGroups], []).includes(g.id)
          );

          return new AgreementAggregationViewModel(aggrements);
        })
      );
    }
  }

  public setUserAvatar(avatarFile: File): Observable<void> {
    return this.userHttpService.setUserAvatar(avatarFile);
  }

  public getAccountItems() {
    return this.userHttpService.getAccountItems();
  }

  public getAccountItemsViaApiModel(): Observable<AccountItemsViewModel> {
    return this.userHttpService.getAccountItemsViaApiModel().pipe(map((result) => new AccountItemsViewModel(result)));
  }

  public getWatchlist(): Observable<UserWatchlistViewModel> {
    return this.userHttpService.getWatchlist().pipe(map((model) => new UserWatchlistViewModel(model)));
  }

  public addToWatchlist(value: string, type: ScreeningType = ScreeningType.MOVIE) {
    return this.getWatchlist().pipe(
      map(
        (x: UserWatchlistViewModel) =>
          ({
            eventIds: x.events.map((y) => y.id),
            movieIds: x.movies.map((y) => y.id),
          } as UserWatchlistRequestModel)
      ),
      tap((mapped) => {
        if (type === ScreeningType.MOVIE) {
          mapped.movieIds.push(value);
        } else {
          mapped.eventIds.push(value);
        }
      }),
      mergeMap((x) => this.patchWatchlist(x))
    );
  }

  public removeFromWatchlist(value: string) {
    return this.getWatchlist().pipe(
      map(
        (x: UserWatchlistViewModel) =>
          ({
            eventIds: x.events.map((y) => y.id),
            movieIds: x.movies.map((y) => y.id),
          } as UserWatchlistRequestModel)
      ),
      tap((mapped) => {
        mapped.eventIds = mapped.eventIds.filter((x) => x !== value);
        mapped.movieIds = mapped.movieIds.filter((x) => x !== value);
      }),
      mergeMap((x) => this.patchWatchlist(x))
    );
  }

  public patchWatchlist(requestModel: UserWatchlistRequestModel): Observable<UserWatchlistViewModel> {
    return this.userHttpService.patchWatchlist(requestModel).pipe(map((model) => new UserWatchlistViewModel(model)));
  }

  public transferItem(request: ItemTransferRequestModel): Observable<Object> {
    return this.userHttpService.transferItem(request);
  }

  public getFavotiePayment(): Observable<UserFavoritePaymentViewModel[]> {
    return this.userHttpService
      .getFavotiePayment()
      .pipe(map((models: UserFavotiePaymentApiModel[]) => models.map((model: UserFavotiePaymentApiModel) => new UserFavoritePaymentViewModel(model))));
  }

  public removeFavotiePayment(providerId: string, token: string) {
    return this.userHttpService.deletePaymentToken(providerId, token);
  }

  public getPreferences(): Observable<UserPreferencesViewModel> {
    return this.userHttpService.getPreferences().pipe(map((model) => new UserPreferencesViewModel(model)));
  }

  public putPreferences(request: UserPreferencesRequestModel) {
    return this.userHttpService.putPreferences(request);
  }

  public getUserPreferences() {
    return forkJoin({
      languages: this.languageHttpService.getAvailableLanguages().pipe(map((models) => models.map((model) => new LanguageViewModel(model)))),
      preferences: this.getPreferences(),
    });
  }

  public createTwoFactorAuthenticationToken(request: UserTwoFactorAuthenticationRequestModel) {
    return this.userHttpService.createTwoFactorAuthenticationToken(request);
  }

  public getMemberGetMemberReferralPrograms(): Observable<ProgramViewModel[]> {
    return this.userHttpService
      .getMemberGetMemberReferralPrograms()
      .pipe(map((models: ProgramApiModel[]) => models.map((model: ProgramApiModel) => new ProgramViewModel(model))));
  }

  public listMemberGetMemberReferralRedeemable(promotionId: string): Observable<PromotionConditionViewModel> {
    return this.userHttpService.listMemberGetMemberReferralRedeemable(promotionId).pipe(map((model) => new PromotionConditionViewModel(model)));
  }

  public setMemberGetMemberReferralRecomendation(request: UserReferralRecomendationdRequestModel): Observable<Object> {
    return this.userHttpService.setMemberGetMemberReferralRecomendation(request);
  }

  public assignLoyaltyItem(voucherNumber: string, cardNumber: string) {
    return this.userHttpService.assignLoyaltyItem({ voucherNumber, cardNumber });
  }

  public getDeletionReason(): Observable<UserDeletionReasonViewModel[]> {
    return this.userHttpService
      .getDeletionReason()
      .pipe(map((models: UserDeletionReasonApiModel[]) => models.map((model: UserDeletionReasonApiModel) => new UserDeletionReasonViewModel(model))));
  }

  public remindPassword(email: string) {
    return this.userHttpService.remindPassword(email);
  }

  public setPassword(request: UserSetpasswordRequestModel): Observable<any> {
    return this.userHttpService.setPassword(request);
  }

  public registrationConfirm(token: string) {
    return this.userHttpService.confirm(token);
  }
}
