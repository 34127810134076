import { Component } from '@angular/core';
import { environment } from 'apps/oneil/src/environments/environment';
import { OrderSummaryComponent } from 'libs/shared/src/lib/component/order/order-summary/order-summary.component';

@Component({
  selector: 'app-order-order-summary',
  templateUrl: './order-summary.component.html',
})
export class OneilOrderSummaryComponent extends OrderSummaryComponent {
  env = environment;
}
