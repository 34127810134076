import { Injectable } from '@angular/core';
import { UserWatchlistViewModel } from '../user/user-watchlist.view.model';
import { CinemaViewModel } from '../cinema/cinema.view.model';
import { UserScreenViewModel } from '../user-history/screen/user-screen.view.model';
import { UserEventItemViewModel } from '../user-history/event/user-event-item.view.model';
import { UserEventViewModel } from '../user-history/event/user-event.view.model';
import { MoviePrintViewModel } from './reservation/movie-print.view.model';
import { UserMoviePrintViewModel } from '../user-history/movie-print/user-movie-print.view.model';
import { UserMoviePictureViewModel } from '../user-history/shared/user-movie-picture.view.model';
import { UserMovieTagViewModel } from '../user-history/shared/user-movie-tag.view.model';
import { UserScreeningViewModel } from '../user-history/screening/user-screening.view.model';
import { UserMovieViewModel } from '../user-history/movie/user-movie.view.model';
import { ScreeningViewModel } from './reservation/screening.view.model';
import { UserSeatViewModel } from '../user-history/seat/user-seat.view.model';
import { TicketViewModel } from './reservation/ticket.view.model';
import { UserTicketViewModel } from '../user-history/ticket/user-ticket.view.model';
import { Adapter, CustomOptions } from '../../adapter';
import { ScreeningWithTickets } from '../../screening-with-tickets';
import { ReservationViewModel } from './reservation/reservation.view.model';
import { MovieViewModel } from '../movie/movie.view.model';
import { MovieViewModel as ReservationMovieViewModel } from './reservation/movie.view.model';
import { EventViewModel } from '../event/event.view.model';
import { EventViewModel as ReservationEventViewModel } from './reservation/event.view.model';

@Injectable({
  providedIn: 'root',
})
export class ScreeningWithTicketsAdapter implements Adapter<ScreeningWithTickets[]> {
  adapt(items: ReservationViewModel[], customOptions?: CustomOptions): ScreeningWithTickets[] {
    const result: ScreeningWithTickets[] = [];

    if (!items?.length) {
      return result;
    }

    for (const item of items) {
      if (!customOptions?.withPurchased && item.isPurchased) {
        continue;
      }

      const reservation = new ScreeningWithTickets();

      reservation.tickets = this.mapTickets(item.tickets);
      reservation.screen = Object.assign(new UserScreenViewModel(), {
        name: item.screen.name,
        feature: item.screen.feature,
        number: item.screen.number,
      });

      reservation.bookingId = item.bookingId;

      if (item.event) {
        reservation.event = this.mapEvent(item.event);
        reservation.event.eventTimeTo = item.screening.screeningTimeTo;
        reservation.event.eventTimeFrom = item.screening.screeningTimeFrom;
      } else {
        reservation.movie = this.mapMovie(item.movie);
        reservation.screening = this.mapScreening(item.screening);
        reservation.moviePrint = this.mapMoviePrint(item.movie.moviePrint);
      }

      reservation.cinema = Object.assign(new CinemaViewModel(), {
        id: item.cinemaId,
      });
      reservation.reservationId = item.id;

      if (!item.event) {
        reservation.screening.id = item.screening.screeningId;
      } else {
        reservation.event.screeningId = item.screening.screeningId;
      }

      result.push(reservation);
    }

    return result;
  }

  adaptByWatchlistViewModel(model: UserWatchlistViewModel): ScreeningWithTickets[] {
    const result: ScreeningWithTickets[] = [];

    result.push(
      ...model.movies.map((item) => {
        const reservation = new ScreeningWithTickets();
        reservation.movie = this.mapMovie(item);
        return reservation;
      })
    );

    result.push(
      ...model.events.map((item) => {
        const reservation = new ScreeningWithTickets();
        reservation.event = this.mapEvent(item);
        return reservation;
      })
    );

    return result;
  }

  private mapTickets(tickets: TicketViewModel[]): UserTicketViewModel[] {
    return tickets.map((ticketBefore) => {
      const ticketAfter = new UserTicketViewModel();
      ticketAfter.salesDocumentItemId = ticketBefore.salesDocumentItemId;
      ticketAfter.name = ticketBefore.name;
      ticketAfter.price = ticketBefore.price;
      ticketAfter.ticketNumber = ticketBefore.ticketNumber;
      ticketAfter.seat = Object.assign(new UserSeatViewModel(), {
        row: ticketBefore.seats[0].rowSymbol,
        seat: ticketBefore.seats[0].seatSymbol,
        groupName: ticketBefore.seats[0].seatGroupName,
        groupId: ticketBefore.seats[0].seatGroupId,
      });

      return ticketAfter;
    });
  }

  private mapScreening(screening: ScreeningViewModel): UserScreeningViewModel {
    return Object.assign(new UserScreeningViewModel(), {
      screeningTimeFrom: screening.screeningTimeFrom,
      screeningTimeTo: screening.screeningTimeTo,
    });
  }

  private mapMovie(movie: ReservationMovieViewModel | MovieViewModel): UserMovieViewModel {
    return Object.assign(new UserMovieViewModel(), {
      id: movie.id,
      duration: movie.duration,
      ageLongDescription: movie.ageLongDescription,
      premiereDate: movie.releaseDate,
      posters: movie.posters,
      title: movie.title,
      pictures: movie.pictures.map((picture) =>
        Object.assign(new UserMoviePictureViewModel(), {
          url1: picture,
        })
      ),
      tags: movie.tagGroups.map((tagGroup) => [
        ...tagGroup.tags.map((tag) =>
          Object.assign(new UserMovieTagViewModel(), {
            orderNo: tag.orderNo,
            symbol: tag.symbol,
            description: tag.description,
          })
        ),
      ]),
    });
  }

  private mapMoviePrint(movie: MoviePrintViewModel): UserMoviePrintViewModel {
    return Object.assign(new UserMoviePrintViewModel(), {
      id: movie.id,
      printType: movie.printType,
      soundType: movie.soundType,
      speakingType: movie.speakingType,
      language: movie.language,
      subtitles: movie.subtitles,
      subtitles2: movie.subtitles2,
      release: movie.release,
    });
  }

  private mapEvent(event: ReservationEventViewModel | EventViewModel): UserEventViewModel {
    return Object.assign(new UserEventViewModel(), {
      eventId: event.id,
      pictures: event.pictures.map((picture) =>
        Object.assign(new UserMoviePictureViewModel(), {
          url1: picture,
        })
      ),
      posters: event.posters,
      eventName: event.name,
      eventItems: (event.items as any[]).map((item) =>
        Object.assign(new UserEventItemViewModel(), {
          movieId: item.movieId,
          moviePrintId: item.moviePrintId,
          movieName: item.movieName,
          moviePrintType: item.printType,
          movieSpeakingType: item.speakingType,
        })
      ),
      tags: event.tagGroups.map((tagGroup) => [
        ...tagGroup.tags.map((tag) =>
          Object.assign(new UserMovieTagViewModel(), {
            orderNo: tag.orderNo,
            symbol: tag.symbol,
            description: tag.description,
          })
        ),
      ]),
    });
  }
}
