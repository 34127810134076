import { FormControl, FormGroup, Validators } from '@angular/forms';
import { oneilValidationPattern } from 'libs/core/src/app.const';
import { TheSameFormValidator } from 'libs/core/src/lib/tool/form/validator/the-same.form-validator';
import { FormDataBuilderInterface } from 'libs/shared/src/lib/component/personal/interface/form-data.builder.interface';
import { FormBuilderInterface } from 'libs/shared/src/lib/component/personal/interface/form.builder.interface';

export class FormBuilder implements FormBuilderInterface {
  public getForm(data: FormDataBuilderInterface): FormGroup {
    let options = {
      name: new FormControl({ value: data?.user?.firstname || '', disabled: data?.user?.firstname?.length > 0 }, [Validators.maxLength(40)]),
      phone: new FormControl('', [Validators.pattern(oneilValidationPattern.phone)]),
      email: new FormControl({ value: data?.user?.email || '', disabled: data?.user?.email?.length > 0 }, [
        Validators.required,
        Validators.pattern(oneilValidationPattern.email),
      ]),
      emailRepeat: new FormControl({ value: data?.user?.emailRepeat || '', disabled: data?.user?.emailRepeat?.length > 0 }, [
        Validators.required,
        this.EmailValidator('email'),
      ]),
      customAgreement: new FormControl(false, [Validators.requiredTrue]),
    };

    const form = new FormGroup(options, {
      validators: [new TheSameFormValidator('email', 'emailRepeat', 'emailsNotMatches').getValidator()],
    });

    return form;
  }

  private EmailValidator(confirmEmailInput: string) {
    let confirmEmailControl: FormControl;
    let emailControl: FormControl;

    return (control: FormControl) => {
      if (!control.value) {
        return null;
      }

      if (!confirmEmailControl) {
        confirmEmailControl = control;
        emailControl = control.parent.get(confirmEmailInput) as FormControl;
        emailControl.valueChanges.subscribe(() => {
          confirmEmailControl.updateValueAndValidity();
        });
      }

      const email = emailControl.value ? emailControl.value.toLocaleLowerCase() : '';
      const emailConfirm = confirmEmailControl.value ? confirmEmailControl.value.toLocaleLowerCase() : '';

      if (email !== emailConfirm) {
        return {
          notMatch: true,
        };
      }
      return null;
    };
  }
}
