import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { storageKey } from '../../app.const';
import { ENVIRONMENT_TOKEN } from '../injection.tokens';
import { StateService } from '../state/state.service';

@Injectable({
  providedIn: 'root',
})
export class AllowedGuard implements CanActivate {
  constructor(@Inject(ENVIRONMENT_TOKEN) protected environment: any, private router: Router, private stateService: StateService) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const allowedPages = this.environment.constants.allowedPages as string[];
    const pageIdentify = next.data.pageIdentify as string;

    if (!allowedPages.includes(pageIdentify)) {
      const backUrl = this.stateService.getItem(storageKey.backUrl) || this.environment.backUrl;

      this.router.navigateByUrl(backUrl);
      return false;
    }

    return true;
  }
}
