import { AfterContentChecked, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from 'libs/core/src/lib/service/app.service';
import { HeaderService } from 'libs/core/src/lib/service/header.service';
import { NavigationHelperService } from 'libs/core/src/lib/service/navigation/navigation-helper.service';
import { VoucherService } from 'libs/core/src/lib/service/voucher.service';
import { AuthStateService } from 'libs/core/src/lib/state/auth.state.service';
import { CateringStateService } from 'libs/core/src/lib/state/catering.state.service';
import { OrderStateService } from 'libs/core/src/lib/state/order.state.service';
import { HeaderComponent } from 'libs/shared/src/lib/component/layout/header/header.component';
import { CinemaDataProvider } from 'libs/core/src/lib/data-provider/cinema.data-provider';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
})
export class OneilHeaderComponent extends HeaderComponent implements OnInit, AfterContentChecked, OnDestroy {
  env = environment;

  constructor(
    protected orderStateService: OrderStateService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected translateService: TranslateService,
    protected navigationHelperService: NavigationHelperService,
    protected headerService: HeaderService,
    protected voucherService: VoucherService,
    protected authService: AuthStateService,
    protected cateringStateService: CateringStateService,
    protected appService: AppService,
    protected cinemaDataProvider: CinemaDataProvider
  ) {
    super(
      environment,
      orderStateService,
      router,
      route,
      translateService,
      navigationHelperService,
      headerService,
      voucherService,
      authService,
      cateringStateService,
      appService,
      cinemaDataProvider
    );
  }
}
