import { Injectable } from '@angular/core';
import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { storageKey } from '../../app.const';
import { TranslateService } from '@ngx-translate/core';
import { StateService } from '../state/state.service';

@Injectable({
  providedIn: 'root',
})
export class AcceptLanguageInterceptor implements HttpInterceptor {
  constructor(private stateService: StateService, private translateService: TranslateService) {}
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const lang = this.stateService.getItem(storageKey.lang) || this.translateService?.currentLang;

    if (lang) {
      req = req.clone({
        headers: req.headers.set('Accept-Language', lang),
      });
    }

    return next.handle(req);
  }
}
