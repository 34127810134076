import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { BehaviorSubject, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { UserTokenResponseModel } from '../model/response/user-token.response.model';
import { AuthStateService } from '../state/auth.state.service';

@Injectable({
  providedIn: 'root',
})
export class JwtRefreshTokenInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private readonly forbiddenRequestUrl = ['token', 'login', 'password'];
  private readonly userAreaRoutePrefix: string = 'user-area';

  constructor(private authStateService: AuthStateService, private route: Router) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const authToken = this.authStateService.getToken();

    if (!authToken && this.requestIncludeForbiddenUrl(this.forbiddenRequestUrl, req.url)) {
      return next.handle(req);
    }

    return next.handle(req).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse) {
          if (error.status === 401 || error.status === 403) {
            if (!this.requestIncludeForbiddenUrl(this.forbiddenRequestUrl, req.url)) {
              return this.handleError(req, next);
            }
          }

          return throwError(error);
        }
      })
    );
  }

  private handleError(req: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing && this.authStateService.isLogged()) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      return this.authStateService.refreshToken().pipe(
        switchMap(({ token }: UserTokenResponseModel) => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next(token);
          return next.handle(this.addToken(req, token));
        }),
        catchError((error) => {
          this.handleLogout();
          return throwError(error);
        })
      );
    } else {
      return this.refreshTokenSubject.pipe(
        filter((token) => token != null),
        take(1),
        switchMap((token) => next.handle(this.addToken(req, token))),
        catchError((error) => {
          this.handleLogout();
          return throwError(error);
        })
      );
    }
  }

  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  private requestIncludeForbiddenUrl(toSearch: Array<string>, url: string): boolean {
    return toSearch.some(url.includes.bind(url));
  }

  private handleLogout(): void {
    this.authStateService.logout();

    if (this.route.url.includes(this.userAreaRoutePrefix)) {
      this.route.navigate([this.userAreaRoutePrefix, 'user-login']);
    } else {
      this.route.navigate(['user-login']);
    }
  }
}
