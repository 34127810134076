import { Expose } from 'class-transformer';

export class ProviderConfigurationApiModel {
  @Expose()
  url_iframe: string;

  @Expose()
  payment_form_url: string;

  @Expose()
  public_key: string;

  @Expose()
  sign: string;
}
