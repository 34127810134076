import { Expose } from 'class-transformer';

export class AccountItemsVoucherApiModel {
  @Expose()
  id: string;

  @Expose()
  name: string;

  @Expose()
  description: string;

  @Expose()
  expiryDate: string;

  @Expose()
  number: string;

  @Expose()
  type: number;

  @Expose()
  usesLeft: number;

  @Expose()
  picture: string;

  @Expose()
  voucherGroupId: string;

  @Expose()
  voucherGroupName: string;

  @Expose()
  usedOnSalesChannels: string[];
}
