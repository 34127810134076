import { AbstractViewModel } from '../../../abstract.view.model';
import { UserExtraFeeApiModel } from '../../../api-model/user-history/ticket/user-extra-fee.api.model';
import { UserTicketApiModel } from '../../../api-model/user-history/ticket/user-ticket.api.model';
import { UserSeatViewModel } from '../seat/user-seat.view.model';
import { UserExtraFeeViewModel } from './user-extra-fee.view.model';

export class UserTicketViewModel extends AbstractViewModel<UserTicketApiModel> {
  public id: string = null;
  public seatId: string = null;
  public screeningId: string = null;
  public ticketId: string = null;
  public screenId: string = null;
  public price: number = null;
  public quantity: number = null;
  public moviePrintId: string = null;
  public movieId: string = null;
  public name: string = null;
  public orderId: string = null;
  public salesDocumentItemId: string = null;
  public extraFees: UserExtraFeeApiModel[] = [];
  public isReturned: boolean = null;
  public isTransferred: boolean = null;
  public transferred: number = null;
  public eventId: string = null;
  public voucherNumber: string = null;
  public voucherName: string = null;
  public voucherBatchName: string = null;
  public ticketNumber: string = null;
  public bookingId: string = null;
  public reservationId: string = null;

  public seat: UserSeatViewModel = null;
  public cinemaId: string = null;
  public canBeRefund = false;
  public canBeSend = false;

  constructor(protected apiModel: UserTicketApiModel = new UserTicketApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.id = this.apiModel.id;
    this.seatId = this.apiModel.seatId;
    this.screeningId = this.apiModel.screeningId;
    this.ticketId = this.apiModel.ticketId;
    this.screenId = this.apiModel.screenId;
    this.price = this.apiModel.price;
    this.quantity = this.apiModel.quantity;
    this.moviePrintId = this.apiModel.moviePrintId;
    this.movieId = this.apiModel.movieId;
    this.name = this.apiModel.name;
    this.orderId = this.apiModel.orderId;
    this.salesDocumentItemId = this.apiModel.salesDocumentItemId;
    this.extraFees = this.extraFees.map((extraFee) => new UserExtraFeeViewModel(extraFee));
    this.isReturned = this.apiModel.isReturned;
    this.isTransferred = this.apiModel.isTransferred;
    this.transferred = this.apiModel.transferred;
    this.eventId = this.apiModel.eventId;
    this.voucherNumber = this.apiModel.voucherNumber;
    this.voucherName = this.apiModel.voucherName;
    this.voucherBatchName = this.apiModel.voucherBatchName;
    this.ticketNumber = this.apiModel.ticketNumber;
    this.bookingId = this.apiModel.bookingId;
    this.reservationId = this.apiModel.reservationId;
  }

  toApiModel(): UserTicketApiModel {
    return undefined;
  }

  public get seatPlace(): string {
    return this.seat?.seatPlace;
  }
}
