<div class="container">
  <div class="row">
    <div class="text-center col-12 p-0">
      <app-steps [step]="order?.isOnlyGiftCardOrder() ? 3 : 4"></app-steps>
    </div>
  </div>

  <div class="main-container">
    <div [smooth-in]="!loadingService.isLoading(loaderEnum.MAIN)">
      <ng-container *ngIf="basketPageModel">
        <div class="row">
          <div class="col-12 col-sm-6">
            <div class="mt-3 mb-4 my-sm-5">
              <app-book-movie-list [screen]="basketPageModel?.screen"
                                   [cinemaId]="order?.cinemaId"></app-book-movie-list>
            </div>
          </div>
          <div class="col-12 col-sm-6 my-2 my-sm-5 text-right order-first order-sm-last">
            <app-simple-countdown></app-simple-countdown>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <app-sidebar>
              <div sidebar-title>
                {{ 'basket.title' | translate }}
              </div>
              <div class="cart-sidebar" sidebar-body>
                <ng-container *ngIf="order && basketPageModel">
                  <app-order-order-summary [basketPageModel]="basketPageModel"
                                           buttonText="{{'personal.personal.form.submit'|translate}}"
                                           [selectedCateringArticleCombinationList]="selectedCateringArticleCombinationList"
                                           [order]="order" [submitButtonShow]="false">
                  </app-order-order-summary>
                </ng-container>
              </div>
            </app-sidebar>
          </div>

          <div class="col-md-6">
            <app-sidebar>
              <div sidebar-title>
                {{paymentProxyComponent?.paymentInitialized ? ('payment.title' | translate) : ('payment.title2' | translate)}}
              </div>
              <div sidebar-body class="d-flex align-items-center" style="min-height:180px">
                <div class="row" *ngIf="order && !paymentProxyComponent?.paymentInitialized">
                  <div class="col-12">
                    <app-loyalty-payment-list></app-loyalty-payment-list>
                  </div>
                </div>
                <div class="payment-block" *ngIf="paymentMethodList?.length > 0"
                     [hidden]="!paymentProxyComponent?.paymentInitialized">
                  <ng-container *ngFor="let paymentMethod of paymentMethodList">
                    <app-payment-proxy-component #paymentProxyComponent
                                                 *ngIf="selectedPaymentMethod?.id === paymentMethod.id"
                                                 [paymentMethod]="paymentMethod"
                                                 (providerEvent)="onPaymentProviderEvent($event)">
                    </app-payment-proxy-component>
                  </ng-container>
                  <app-loading *ngIf="loadingService.isLoading(loaderEnum.PAYMENT)" [config]="{}"
                               backdrop-position="relative" size="small"></app-loading>
                </div>
              </div>
            </app-sidebar>
          </div>
        </div>

        <div class="row">
          <div class="col-6 mt-4 d-flex justify-content-start">
            <button type="button" (click)="goToPreviousRoute()"
                    class="btn btn-lg btn-default">{{ 'screen.navigation.previous' | translate }}</button>
          </div>
          <div class="col-6 mt-4 d-flex justify-content-end">
            <ng-container *ngIf="order?.getCount() > 0">
              <button type="submit" [disabled]="!selectedPaymentMethod || paymentProxyComponent?.paymentInProgress"
                      (click)="payButtonClicked()" class="btn btn-lg btn-next">
                <ng-container *ngIf="!canCompleteOrder()">
                  {{ 'payment.creditCard.card-element.pay' | translate | uppercase }}
                  {{environment.constants.currency}}
                  {{order?.valueToPay | number:'1.2-2'}}
                </ng-container>
                <ng-container *ngIf="canCompleteOrder()">
                  {{ 'order.summary.complete_order' | translate | uppercase }}
                </ng-container>
              </button>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </div>

    <app-loading [id]="loaderEnum.LOYALTY" [show]="false"></app-loading>
  </div>
</div>

<app-popup [isVisible]="paymentErrorMessage">
  <div class="row pb-4">
    <div class="col-12">
      <div class="question" [innerHTML]="paymentErrorMessage"></div>
    </div>
  </div>
  <div class="row" footer>
    <div class="col-12 text-center">
      <input type="submit" class="btn btn-lg btn-primary" value="{{ 'screen.popup.close_button' | translate }}"
             (click)="onClickedCloseModal()" />
    </div>
  </div>
</app-popup>