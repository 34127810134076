import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { storageKey } from 'libs/core/src/app.const';
import { OrderViewModel } from 'libs/core/src/lib/model/view-model/order/order.view.model';
import { VoucherViewModel } from 'libs/core/src/lib/model/view-model/voucher/voucher.view.model';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { combineLatest, Subscription } from 'rxjs';
import { ModalEventEmitter, ModalStatusEnum } from './modal-status.enum';

@Component({
  template: '',
})
export class ModalComponent implements OnInit, OnDestroy {
  @Output() clickEvent = new EventEmitter<ModalEventEmitter>();
  @Input() templateType: string = null;
  @Input() orderModel: OrderViewModel = null;
  @Input() voucherModel: VoucherViewModel = null;
  @Input() modalContent: any = null;
  @Input() class: string = '';
  @Input() config: ModalOptions = { ignoreBackdropClick: true };

  public modalRef: BsModalRef;
  subscriptions: Subscription[] = [];

  templates = {};

  constructor(protected modalService: BsModalService, protected changeDetection: ChangeDetectorRef) {}

  ngOnInit() {
    if (this.templateType === 'voucherInfo' && (!this.voucherModel.flgMultiuse || this.orderModel.screeningItems.filter((f) => !f.hasVoucher()).length <= 1)) {
      this.clickEvent.emit({ state: ModalStatusEnum.accept, count: 1 });
      return;
    }

    if (this.templateType !== 'voucherInfo') {
      const _combine = combineLatest(this.modalService.onHidden).subscribe(() => this.changeDetection.markForCheck());

      this.subscriptions.push(
        this.modalService.onHidden.subscribe((reason: string | any) => {
          if (typeof reason === 'string' && ['backdrop-click', 'esc'].indexOf(reason) !== -1) {
            this.clickEvent.emit({ state: ModalStatusEnum.close });
          }
        }),
        this.modalService.onHide.subscribe((reason: string | any) => {
          this.clickEvent.emit({ state: ModalStatusEnum.close });
        })
      );

      this.subscriptions.push(_combine);
    }

    this.initModal();
  }

  private initModal() {
    const initialState = {
      order: this.orderModel,
      voucher: this.voucherModel,
      content: this.modalContent,
      lang: sessionStorage.getItem(storageKey.lang),
    };

    this.modalRef = this.modalService.show(this.templates[this.templateType], {
      animated: false,
      class: this.class,
      initialState,
    });

    this.modalRef.content.event.subscribe((res: ModalEventEmitter) => {
      this.modalRef = null;
      this.clickEvent.emit(res);
    });
  }

  unsubscribe() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });

    this.subscriptions = [];
  }

  ngOnDestroy() {
    if (this.modalRef) {
      this.modalRef.hide();
      this.modalRef = null;
    }

    this.unsubscribe();
  }
}
