import { FormControl, FormGroup, Validators } from '@angular/forms';
import { oneilValidationPattern } from '@lib/core';
import { FormDataBuilderInterface } from 'libs/shared/src/lib/component/login/interface/form-data.builder.interface';
import { FormBuilderInterface } from 'libs/shared/src/lib/component/login/interface/form.builder.interface';

export class OneilFormBuilder implements FormBuilderInterface {
  public getForm(data: FormDataBuilderInterface): FormGroup {
    return new FormGroup({
      email: new FormControl('', [Validators.required, Validators.pattern(oneilValidationPattern.email)]),
      password: new FormControl('', [Validators.required]),
      rememberMe: new FormControl(false),
    });
  }
}
