import { Injectable } from '@angular/core';
import { GooglePay } from '../client/client';
import {
  PaymentAuthMethod,
  PaymentCardNetwork,
  PaymentCardOptions,
  PaymentDataModel,
  PaymentMerchantInfoModel,
  PaymentMethod,
  PaymentMethodType,
  PaymentTotalPriceStatus,
  PaymentTransactionInfoModel,
  TokenizationSpecification,
  TokenizationSpecificationPaymentGatewayOptions,
  TokenizationSpecificationType,
} from '../model/payment-data.model';
import PaymentOptions = GooglePay.PaymentOptions;

declare var google: any;

@Injectable({
  providedIn: 'root',
})
export class GooglePayService {
  /**
   * Creates payment data request
   * @param dataRequestDto
   */
  public createPaymentDataRequest(dataRequestDto: CreatePaymentDataRequestDto): PaymentDataModel {
    console.log('dataRequestDto:', dataRequestDto);

    const paymentDataModel: PaymentDataModel = new PaymentDataModel();
    paymentDataModel.apiVersion = 2;
    paymentDataModel.apiVersionMinor = 0;

    const allowedPaymentMethods = new Array<PaymentMethod>();
    if (dataRequestDto.allowedCardAuthMethods && dataRequestDto.allowedCardMethods) {
      const cardPaymentMethod: PaymentMethod = new PaymentMethod();
      cardPaymentMethod.type = PaymentMethodType.CARD;

      const cardPaymentMethodOptions = new PaymentCardOptions();
      cardPaymentMethodOptions.allowedAuthMethods = dataRequestDto.allowedCardAuthMethods ?? [];
      cardPaymentMethodOptions.allowedCardNetworks = dataRequestDto.allowedCardMethods ?? [];
      cardPaymentMethod.parameters = cardPaymentMethodOptions;

      const tokenization: TokenizationSpecification = new TokenizationSpecification();
      tokenization.type = TokenizationSpecificationType.PAYMENT_GATEWAY;
      //tokenization.tokenizationType = TokenizationSpecificationType.PAYMENT_GATEWAY;

      if (dataRequestDto.gateway && dataRequestDto.gatewayMerchantId) {
        const tokenizationParameters = new TokenizationSpecificationPaymentGatewayOptions();
        tokenizationParameters.gateway = dataRequestDto.gateway;
        tokenizationParameters.gatewayMerchantId = dataRequestDto.gatewayMerchantId;
        tokenization.parameters = tokenizationParameters;
      }
      cardPaymentMethod.tokenizationSpecification = tokenization;
      allowedPaymentMethods.push(cardPaymentMethod);
    }
    paymentDataModel.allowedPaymentMethods = allowedPaymentMethods;

    const transactionInfo = new PaymentTransactionInfoModel();
    transactionInfo.countryCode = dataRequestDto.countryCode;
    transactionInfo.currencyCode = dataRequestDto.currencyCode;
    transactionInfo.totalPriceStatus = dataRequestDto.totalPriceStatus;
    transactionInfo.totalPrice = dataRequestDto.totalPrice;
    paymentDataModel.transactionInfo = transactionInfo;

    if (dataRequestDto.merchantId) {
      const merchantInfo = new PaymentMerchantInfoModel();
      merchantInfo.merchantId = dataRequestDto.merchantId;
      merchantInfo.merchantName = dataRequestDto.merchantName;
      paymentDataModel.merchantInfo = merchantInfo;
    }

    return paymentDataModel;
  }

  public getClient(environment: 'TEST' | 'PRODUCTION' = 'PRODUCTION'): GooglePay.PaymentsClient {
    return new google.payments.api.PaymentsClient({
      environment,
    } as PaymentOptions);
  }
}

export class CreatePaymentDataRequestDto {
  public merchantId: string;

  public merchantName: string;

  public gateway: string;

  public gatewayMerchantId: string;

  public totalPrice: string;

  public currencyCode: string;

  public countryCode: string;

  public totalPriceStatus: PaymentTotalPriceStatus;

  public allowedCardAuthMethods: Array<PaymentAuthMethod>;

  public allowedCardMethods: Array<PaymentCardNetwork>;

  public environment: 'TEST' | 'PRODUCTION';
}
