import { Component, Input } from '@angular/core';
import { AbstractControl, ControlValueAccessor, ValidationErrors, Validator } from '@angular/forms';
import { getDropdownDays, getDropdownMonths, getDropdownYears } from 'libs/core/src/lib/date/date.helper';
import { panValue } from 'libs/core/src/lib/tool/number/number';
import { DateTime } from 'luxon';

export enum DateSortTypeEnum {
  DMY = 'dmy',
  MDY = 'mdy',
  YMD = 'ymd',
  YDM = 'ydm',
}
export enum PartOfTimeEnum {
  DAY = 'day',
  MONTH = 'month',
  YEAR = 'year',
}
export enum PartOfTimeSignEnum {
  DAY = 'd',
  MONTH = 'm',
  YEAR = 'y',
}

@Component({
  template: '',
})
export class DateComponent implements ControlValueAccessor, Validator {
  @Input() hasError = false;
  @Input() minimumAge: number = null;
  @Input() disabledFutureDate = true;
  @Input() tabStart = 1;
  @Input() sortType: DateSortTypeEnum = DateSortTypeEnum.DMY;

  controlsMapping = new Map<PartOfTimeSignEnum, PartOfTimeEnum>([
    [PartOfTimeSignEnum.DAY, PartOfTimeEnum.DAY],
    [PartOfTimeSignEnum.MONTH, PartOfTimeEnum.MONTH],
    [PartOfTimeSignEnum.YEAR, PartOfTimeEnum.YEAR],
  ]);

  controls: { id: PartOfTimeEnum }[] = [];
  date: DateTime | null = null;
  day: string | null = null;
  month: string | null = null;
  year: string | null = null;

  days = getDropdownDays(1, 31);
  months = getDropdownMonths(1, 12);
  years = getDropdownYears();

  protected partOfTimeSign: typeof PartOfTimeSignEnum = PartOfTimeSignEnum;

  onChange = (date: DateTime | null) => {
    if (!date) {
      this.markAsTouched();
    }

    this.date = date;
    return;
  };

  onTouched = () => {};

  touched = false;
  disabled = false;

  ngOnInit(): void {
    this.controls = this.getControls();
  }

  onYearChange(event) {
    if (!this.disabled) {
      this.year = event?.target?.value || event.id;
      this.onDateChange(PartOfTimeSignEnum.YEAR);
    }
  }

  onMonthChange(event) {
    if (!this.disabled) {
      this.month = panValue(event?.target?.value || event.id, 2);
      this.onDateChange(PartOfTimeSignEnum.MONTH);
    }
  }

  onDayChange(event) {
    if (!this.disabled) {
      this.day = panValue(event?.target?.value || event.id, 2);
      this.onDateChange(PartOfTimeSignEnum.DAY);
    }
  }

  onDateChange(field: string) {
    const date =
      (field === PartOfTimeSignEnum.YEAR && this.month && this.day) ||
      (field === PartOfTimeSignEnum.MONTH && this.year && this.day) ||
      (field === PartOfTimeSignEnum.DAY && this.year && this.month)
        ? `${this.year}-${this.month}-${this.day}`
        : null;

    if (date) {
      this.hasError = false;
      this.onChange(DateTime.fromISO(date, { zone: 'utc' }));
    } else {
      this.onChange(null);
    }
  }

  writeValue(date: DateTime | null) {
    if (!date) {
      return;
    }

    this.year = date.year.toString();
    this.month = panValue(date.month, 2);
    this.day = panValue(date.day, 2);
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    this.hasError = false;
    const date = control.value as DateTime;
    const now = DateTime.utc().startOf(PartOfTimeEnum.DAY);
    if (date) {
      const yearsDiff = date.diff(now, 'years').years;
      if (!date.isValid || date.year < 1900) {
        this.hasError = true;
        return { invalidDate: { date } };
      }

      if (this.minimumAge && Math.abs(yearsDiff) < this.minimumAge) {
        this.hasError = true;
        return { minimumAge: { requiredAge: this.minimumAge, actualAge: yearsDiff } };
      }

      if (this.disabledFutureDate && now < date) {
        this.hasError = true;
        return { invalidDate: { date } };
      }
    }
  }

  getValidObjectOrNull() {}

  getIndex(field: PartOfTimeSignEnum) {
    return this.sortType.indexOf(field);
  }

  getFocusOnParams(field: PartOfTimeSignEnum) {
    const focusOnParam: { prev?: string; next?: string } = {};
    const fieldIndex = this.getIndex(field);
    const getInput = (f) => {
      const m = this.controlsMapping.get(f);
      return m ? `input[id='${m}']` : null;
    };
    if (fieldIndex < this.sortType.length - 1) {
      const nextField = this.sortType[fieldIndex + 1];
      focusOnParam.next = getInput(nextField);
    }

    if (fieldIndex > 0) {
      const prevField = this.sortType[fieldIndex - 1];
      focusOnParam.prev = getInput(prevField);
    }

    return focusOnParam;
  }

  getControls() {
    const order = this.sortType.split('');
    return order
      .map((char) => {
        switch (char) {
          case PartOfTimeSignEnum.DAY:
            return { id: PartOfTimeEnum.DAY };
          case PartOfTimeSignEnum.MONTH:
            return { id: PartOfTimeEnum.MONTH };
          case PartOfTimeSignEnum.YEAR:
            return { id: PartOfTimeEnum.YEAR };
          default:
            return null;
        }
      })
      .filter((control) => control !== null);
  }
}
