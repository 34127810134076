import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule, TranslateLoader, MissingTranslationHandler, TranslateService } from '@ngx-translate/core';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ModalModule } from 'ngx-bootstrap/modal';
import { WrapMissingTranslationHandler } from 'libs/core/src/lib/handler/wrap-missing-translation-handler';
import { SharedLibraryModule } from 'libs/shared/src/public-api';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { CustomAppComponent } from './app.component';
import { HttpLoaderFactory, SocialAuthServiceConfigFactory } from './config/config.provider';
import { OneilCoreComponent } from './component/core/core.component';
import { OneilLayoutComponent } from './component/layout/layout/layout.component';
import { OneilHeaderComponent } from './component/layout/header/header.component';
import { OneilCountdownComponent } from './component/countdown/countdown.component';
import { OneilFooterComponent } from './component/layout/footer/footer.component';
import { OneilScreeningPageComponent } from './page/screening/screening.component';
import { OneilScreeningSelectDateComponent } from './component/screening/select-date/select-date.component';
import { OneilCinemaSelectorComponent } from './component/cinema-selector/cinema-selector.component';
import { OneilScreeningListComponent } from './component/screening/list/list.component';
import { OneilScreeningListItemComponent } from './component/screening/list/component/item/item.component';
import { OneilScreenTagComponent } from './component/screen/screen-tag/screen-tag.component';
import { OneilStepsComponent } from './component/steps/steps.component';
import { OneilBasketPageComponent } from './page/basket/basket.component';
import { OneilBookMovieListComponent } from './component/book-movie-list/book-movie-list.component';
import { OneilSidebarComponent } from './component/layout/template/sidebar/sidebar.component';
import { OneilBasketListComponent } from './component/basket/basket-list/basket-list.component';
import { OneilOrderTicketsSummaryComponent } from './component/order/tickets-summary/tickets-summary.component';
import { OneilScreenNavigationComponent } from './component/screen/navigation/navigation.component';
import { OneilOrderSummaryPageComponent } from './page/order-summary/order-summary.component';
import { OneilOrderSummaryComponent } from './component/order/order-summary/order-summary.component';
import { OneilScreenPageComponent } from './page/screen/screen.component';
import { OneilMessagePageComponent } from './page/message/message.component';
import { OneilScreenSeatsComponent } from './component/screen/seats/seats.component';
import { OneilAppScreenSeatsMultiSeatComponent } from './component/screen/seats/component/multiseat/multiseat.component';
import { OneilScreenSeatsSeatComponent } from './component/screen/seats/component/seat/seat.component';
import { OneilScreenSeatsSelectedListComponent } from './component/seats-selected-list/seats-selected-list.component';
import { OneilScreenLegendComponent } from './component/screen/legend/legend.component';
import { OneilPaymentPageComponent } from './page/payment/payment.component';
import { OneilPersonalComponent } from './component/personal/personal.component';
import { OneilLoadingComponent } from './component/loading/loading.component';
import { OneilDropdownComponent } from './component/ui/dropdown/dropdown.component';
import { OneilPolicyComponent } from './component/policy/policy.component';
import { OneilUserStatusComponent } from './component/user/user-status/status.component';
import { OneilLoginComponent } from './component/login/login.component';
import { OneilPersonalInformationComponent } from './component/personal/personal-information/personal-information.component';
import { OneilMovieAttributesComponent } from './component/screening/movie-attributes/movie-attributes.component';
import { OneilPersonalPageComponent } from './page/personal/personal.component';
import { ToastrModule } from 'ngx-toastr';
import { OneilUserPageComponent } from './page/user/user-page.component';
import { OneilUserProfileComponent } from './component/user/user-profile/user-profile.component';
import { OneilUserLoyaltyComponent } from './component/user/user-loyalty/user-loyalty.component';
import { NgxMaskModule } from 'ngx-mask';
import { OneilUserUpcomingComponent } from './component/user/user-upcoming/user-upcoming.component';
import { OneilUserHistoryComponent } from './component/user/user-history/user-history.component';
import { OneilChecklistComponent } from './component/ui/checklist/checklist.component';
import { OneilVoucherCardComponent } from './component/voucher/voucher-card/voucher-card.component';
import { OneilVoucherDetailsComponent } from './component/voucher/voucher-details/voucher-details.component';
import { OneilVoucherSelectorComponent } from './component/voucher/voucher-selector/voucher-selector.component';
import { OneilVoucherCardSummaryComponent } from './component/voucher/voucher-card/component/voucher-card-summary/voucher-card-summary.component';
import { OneilVoucherCardInputComponent } from './component/voucher/voucher-card/component/voucher-card-input/voucher-card-input.component';
import { OneilDateComponent } from './component/ui/date/date.component';
import { OneilAccountItemsComponent } from './component/account-items/account-items.component';
import { OneilGiftCardsPageComponent } from './page/gift-cards/gift-cards-page.component';
import { OneilRefundPageComponent } from './page/refund/refund.component';
import { OneilRefundComponent } from './component/refund/refund.component';
import { OneilRefundModalComponent } from './component/modal/refund/refund/refund.component';
import { OneilRefundFailModalComponent } from './component/modal/refund/refund-fail/refund-fail.component';
import { OneilRefundSuccessModalComponent } from './component/modal/refund/refund-success/refund-success.component';
import { OneilModalComponent } from './component/modal/modal.component';
import { OneilVirtualPassComponent } from './component/order/order-virtual-pass/order-virtual-pass.component';
import { OneilLoyaltyInputComponent } from './component/loyalty/loyalty-input/loyalty-input.component';
import { OneilCardInputComponent } from './component/loyalty/card/card-input/card-input.component';
import { OneilVoucherInputComponent } from './component/loyalty/voucher/voucher-input/voucher-input.component';
import { OneilUserRegisterComponent } from './page/user/register/user-register.component';
import { OneilLoyaltyPaymentListComponent } from './component/loyalty/loyalty-payment-list/loyalty-payment-list.component';
import { OneilAccountItemsVouchersComponent } from './component/account-items/account-items-vouchers/account-items-vouchers.component';
import { OneilAccountItemsCardsComponent } from './component/account-items/account-items-cards/account-items-cards.component';
import { OneilAccountItemsVouchersListComponent } from './component/account-items/account-items-vouchers/account-items-vouchers-list/account-items-vouchers-list.component';
import { OneilAccountItemsCardsListComponent } from './component/account-items/account-items-cards/account-items-cards-list/account-items-cards-list.component';
import { OneilUserLoyaltyPopupsComponent } from './component/user/user-loyalty/user-loyalty-popups/user-loyalty-popups.component';
import { OneilWorldpayRedirectHubComponent } from './page/worldpay/worldpay-redirect-hub.component';
import { OneilSimpleCountdownComponent } from './component/simple-countdown/simple-countdown.component';
import { defineLocale } from 'ngx-bootstrap/chronos';
import * as locales from 'ngx-bootstrap/locale';
import { CoreLibraryModule, storageKey } from '@lib/core';

function defineLocales() {
  for (const locale in locales) {
    defineLocale(locales[locale].abbr, locales[locale]);
  }
}
defineLocales();

export function localeIdFactory(translate: TranslateService) {
  const locale = translate.currentLang || sessionStorage.getItem(storageKey.lang);
  const item =
    environment.constants.language.cultureMap.find((o) => o.key === locale) ||
    environment.constants.language.cultureMap.find((o) => o.key === environment.constants.language.default);
  return item?.value ?? locale;
}

@NgModule({
  declarations: [
    CustomAppComponent,
    OneilCoreComponent,
    OneilLayoutComponent,
    OneilHeaderComponent,
    OneilCountdownComponent,
    OneilFooterComponent,
    OneilSidebarComponent,
    OneilScreeningPageComponent,
    OneilScreeningSelectDateComponent,
    OneilCinemaSelectorComponent,
    OneilScreeningListComponent,
    OneilScreeningListItemComponent,
    OneilScreenTagComponent,
    OneilScreenPageComponent,
    OneilMessagePageComponent,
    OneilStepsComponent,
    OneilScreenNavigationComponent,
    OneilScreenSeatsComponent,
    OneilScreenSeatsSeatComponent,
    OneilAppScreenSeatsMultiSeatComponent,
    OneilScreenSeatsSelectedListComponent,
    OneilScreenLegendComponent,
    OneilBasketPageComponent,
    OneilBookMovieListComponent,
    OneilBasketListComponent,
    OneilOrderTicketsSummaryComponent,
    OneilPaymentPageComponent,
    OneilPersonalComponent,
    OneilOrderSummaryPageComponent,
    OneilOrderSummaryComponent,
    OneilLoadingComponent,
    OneilDropdownComponent,
    OneilPolicyComponent,
    OneilUserStatusComponent,
    OneilPersonalInformationComponent,
    OneilLoginComponent,
    OneilMovieAttributesComponent,
    OneilPersonalPageComponent,
    OneilUserPageComponent,
    OneilUserProfileComponent,
    OneilUserLoyaltyComponent,
    OneilUserUpcomingComponent,
    OneilUserHistoryComponent,
    OneilChecklistComponent,
    OneilVoucherCardComponent,
    OneilVoucherDetailsComponent,
    OneilVoucherSelectorComponent,
    OneilVoucherCardSummaryComponent,
    OneilVoucherCardInputComponent,
    OneilDateComponent,
    OneilAccountItemsComponent,
    OneilGiftCardsPageComponent,
    OneilModalComponent,
    OneilRefundComponent,
    OneilRefundPageComponent,
    OneilRefundModalComponent,
    OneilRefundFailModalComponent,
    OneilRefundSuccessModalComponent,
    OneilVirtualPassComponent,
    OneilLoyaltyInputComponent,
    OneilCardInputComponent,
    OneilVoucherInputComponent,
    OneilUserRegisterComponent,
    OneilLoyaltyPaymentListComponent,
    OneilAccountItemsVouchersComponent,
    OneilAccountItemsCardsComponent,
    OneilAccountItemsVouchersListComponent,
    OneilAccountItemsCardsListComponent,
    OneilUserLoyaltyPopupsComponent,
    OneilWorldpayRedirectHubComponent,
    OneilSimpleCountdownComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreLibraryModule.forRootWithInterceptors(environment),
    SharedLibraryModule.forRoot(environment, [
      {
        provide: 'SocialAuthServiceConfig',
        useFactory: SocialAuthServiceConfigFactory,
      },
    ]),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      missingTranslationHandler: { provide: MissingTranslationHandler, useClass: WrapMissingTranslationHandler },
    }),
    HttpClientModule,
    BsDatepickerModule.forRoot(),
    FormsModule,
    ModalModule.forRoot(),
    ReactiveFormsModule,
    ToastrModule.forRoot({
      closeButton: true,
    }),
    NgxMaskModule.forRoot(),
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useFactory: localeIdFactory,
      deps: [TranslateService],
    },
  ],
  bootstrap: [CustomAppComponent],
})
export class AppModule {}
