import { getNumberValueOrZero } from '@lib/core';
import { AbstractViewModel } from '../../abstract.view.model';
import { CardTypeMembershipDowngradeFeeApiModel } from '../../api-model/card/card-type-membership-downgrade-fee.api.model';
import { ExtensionTypeEnum } from '../../enum/extension-type.enum';

export class CardTypeMembershipDowngradeFeeViewModel extends AbstractViewModel<CardTypeMembershipDowngradeFeeApiModel> {
  public feeId: string = null;
  public name: string = null;
  public extensionTime: number = null;
  public extensionType: ExtensionTypeEnum | null = null;
  public feeValue: number = null;
  public downgradeToCardTypeId: string = null;
  public downgradeToCardTypeName: string = null;
  public purchasableDaysBeforeExpirationDate: number = null;

  public membershipTotalValue: number = 0.0;
  public linkToDescription: string = null;

  constructor(protected apiModel: CardTypeMembershipDowngradeFeeApiModel = new CardTypeMembershipDowngradeFeeApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.feeId = this.apiModel.feeId;
    this.name = this.apiModel.name;
    this.extensionTime = this.apiModel.extensionTime;
    this.extensionType = this.apiModel.extensionType === 0 ? ExtensionTypeEnum.ADD_TIME : ExtensionTypeEnum.OVERLAP_TIME;
    this.feeValue = this.apiModel.feeValue;
    this.downgradeToCardTypeId = this.apiModel.downgradeToCardTypeId;
    this.downgradeToCardTypeName = this.apiModel.downgradeToCardTypeName;
    this.purchasableDaysBeforeExpirationDate = getNumberValueOrZero(this.apiModel.purchasableDaysBeforeExpirationDate);
  }

  toApiModel(): CardTypeMembershipDowngradeFeeApiModel {
    return undefined;
  }
}
