import { Expose } from 'class-transformer';
import { CardTypeMembershipFeeApiModel } from './card-type-membership.api.model';

export class CardTypeMembershipUpgradeFeeApiModel extends CardTypeMembershipFeeApiModel {
  @Expose()
  upgradeToCardTypeId: string;

  @Expose()
  upgradeToCardTypeName: string;
}
