import { Injectable } from '@angular/core';
import { loadScript } from '@lib/core';

declare var ApplePaySession: any | undefined;

@Injectable({
  providedIn: 'root',
})
export class ApplePayService {
  scriptUrl = 'https://applepay.cdn-apple.com/jsapi/1.latest/apple-pay-sdk.js';
  scriptLoaded = false;

  async checkApplePayAvailability(): Promise<boolean> {
    if (typeof ApplePaySession === 'undefined') {
      if (!this.scriptLoaded) {
        try {
          await this.loadApplePayScript();
          this.scriptLoaded = true;
          return this.checkApplePayAvailability();
        } catch (error) {
          console.error('Error loading Apple Pay script:', error);
          return false;
        }
      }

      console.warn('ApplePaySession is not available. The browser does not support Apple Pay.');
      return false;
    }

    const canMakePayments = ApplePaySession.canMakePayments();
    if (!canMakePayments) {
      console.warn('The browser supports Apple Pay, but the user cannot make payments.');
    }

    return canMakePayments;
  }

  async canMakePaymentsWithActiveCard(merchantId: string): Promise<boolean> {
    if (typeof ApplePaySession !== 'undefined') {
      try {
        return await ApplePaySession.canMakePaymentsWithActiveCard(merchantId);
      } catch (error) {
        console.error('Error while checking for an active card: ', error);
        return false;
      }
    } else {
      console.warn('ApplePaySession is not available. The browser does not support Apple Pay.');
      return false;
    }
  }

  supportsVersion(version: number): boolean {
    if (typeof ApplePaySession !== 'undefined') {
      const result = ApplePaySession.supportsVersion(version);
      if (!result) {
        console.warn(`ApplePaySession does not support version:${version}`);
      }
      return result;
    } else {
      console.warn('ApplePaySession is not available, unable to check version support.');
      return false;
    }
  }

  public getSession(
    merchantName: string,
    orderAmount: number,
    currencyCode = 'PLN',
    countryCode = 'PL',
    supportedNetworks = ['visa', 'masterCard'],
    merchantCapabilities = ['supports3DS']
  ): any {
    const version = 3;
    if (!this.supportsVersion(version)) {
      return null;
    }

    const paymentRequest = {
      currencyCode,
      countryCode,
      supportedNetworks,
      merchantCapabilities,
      total: {
        label: merchantName,
        amount: orderAmount,
      },
    };

    const applePaySession = new ApplePaySession(version, paymentRequest);
    return applePaySession;
  }

  private loadApplePayScript(): Promise<void> {
    return new Promise((resolve, reject) => {
      loadScript(
        'applepay',
        this.scriptUrl,
        () => {
          resolve();
        },
        false,
        false,
        true
      );
    });
  }
}
