import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import indexOf from 'lodash-es/indexOf';
import { MessageService } from '../message.service';
import { FieldConstraintViolationModel } from './response-validator/model/field-constraint-violation.model';
import { ErrorHandlerService, IResponseCustomErrorInterface } from '../error-handler/error-handler.service';
import { MessageModel, MessageType } from '../../model/message.model';

@Injectable({
  providedIn: 'root',
})
export class ResponseValidatorService {
  constructor(
    private router: Router,
    private messageService: MessageService,
    private translate: TranslateService,
    private errorHandlerService: ErrorHandlerService
  ) {}

  handleHttpError(err: HttpErrorResponse) {
    if (this.errorHandlerService.handle(err)) {
      return;
    }

    const error: IResponseCustomErrorInterface = this.errorHandlerService.getError(err);
    const messages = this.getMessages(error);
    const errorMessage = messages.find((m: MessageModel) => indexOf(['20000', '503'], m.code) !== -1);
    if (errorMessage) {
      this.router.navigate(['error', errorMessage.code]);
    }
  }

  handleOtherErrors(err: Error) {
    if (this.errorHandlerService.handle(err)) {
      return;
    }

    if (err.name === 'TimeoutError') {
      this.router.navigate(['error', 'timeout']);
    }
  }

  handleProperiesErrors(errorResponse: HttpErrorResponse) {
    const messages = [];
    if (!(errorResponse && errorResponse.error && errorResponse.error.error)) {
      return messages;
    }

    if (
      errorResponse.error.error.details !== undefined &&
      errorResponse.error.error.details.properties !== undefined &&
      errorResponse.error.error.details.properties.errors !== undefined
    ) {
      const errorCollection = errorResponse.error.error.details.properties.errors;

      Object.keys(errorCollection).map((prop) => {
        messages[prop] = errorCollection[prop].map((error: IResponseCustomErrorInterface) => {
          return new MessageModel(MessageType.warning, this.translate.instant(`errors.${error.code}`, error.code.toString()));
        });
      });
    }

    return messages;
  }

  handleErrors(errorResponse: HttpErrorResponse) {
    let messages = [];
    const err = this.errorHandlerService.getError(errorResponse);
    if (err) {
      if (!!err.details?.errors) {
        messages = err.details.errors.map((error) => {
          return new MessageModel(MessageType.warning, this.translate.instant(`errors.${error.code}`));
        });
      } else {
        messages = [new MessageModel(MessageType.warning, err.message, err.code.toString())];
      }
    }

    return messages;
  }

  createVirtualResponseErrors(fields: Array<any>): any {
    const schema: any = {
      error: {
        error: {
          errors: {},
          details: {
            properties: {
              errors: [],
            },
          },
        },
      },
    };

    for (const field of fields) {
      schema.error.error.details.properties.errors[field.name] = field.constraint;
    }

    return schema;
  }

  createConstraintViolations(fieldConstraintViolationList: Array<FieldConstraintViolationModel>): any {
    const schema: Array<any> = fieldConstraintViolationList.map((fieldConstraintViolation) => {
      return {
        name: fieldConstraintViolation.FieldName,
        constraint: fieldConstraintViolation.ViolationCodes.map((x: string) => {
          return { code: x };
        }),
      };
    });

    return this.createVirtualResponseErrors(schema);
  }

  viewErrors(err): void {
    const properrors: Array<MessageModel> | { [key: string]: Array<MessageModel> } = this.handleProperiesErrors(err);
    if (!Array.isArray(properrors)) {
      for (const prop of Object.keys(properrors)) {
        for (const message of properrors[prop] as Array<MessageModel>) {
          this.messageService.add(message);
        }
      }
    } else {
      properrors.forEach((message) => {
        this.messageService.add(message);
      });
    }

    const errors = this.handleErrors(err);
    errors.forEach((message) => {
      this.messageService.add(message);
    });
  }

  private getMessages(error: IResponseCustomErrorInterface) {
    let messages = [];

    switch (error.status) {
      case 403:
        break;
      case 404:
        break;
      case 409:
        break;
      case 503:
        messages.push(new MessageModel(MessageType.warning, this.translate.instant('errors.503'), '503'));
        break;
      case 401:
        break;
      case 400:
        if (error.errors) {
          messages = error.errors.map((error: IResponseCustomErrorInterface) => {
            return new MessageModel(MessageType.warning, this.translate.instant(`errors.${error.code}`), error.code.toString());
          });
        }
        break;
      default:
        messages.push(new MessageModel(MessageType.warning, this.translate.instant('errors.20000'), '20000'));
        break;
    }

    return messages;
  }
}
