import { Directive, ElementRef, Inject, Input } from '@angular/core';
import { take } from 'rxjs';
import { SocialAuthService } from '../socialauth.service';
import { ENVIRONMENT_TOKEN } from '@lib/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'login-google-button',
})
export class GoogleButtonDirective {
  @Input() width: string = '';
  @Input() locale: string = '';
  @Input() customButtonText: string = '';

  constructor(@Inject(ENVIRONMENT_TOKEN) private environment: any, el: ElementRef, socialAuthService: SocialAuthService) {
    if (!environment?.externalAuthProviders?.google?.clientId?.length) {
      return;
    }

    const createGoogleLoginWrapper = (locale) => {
      const googleLoginWrapper = document.createElement('div');
      googleLoginWrapper.style.display = 'none';
      googleLoginWrapper.classList.add('custom-google-button');

      document.body.appendChild(googleLoginWrapper);

      google.accounts.id.renderButton(googleLoginWrapper, {
        type: 'icon',
        size: 'small',
        locale: locale,
      });

      const googleLoginWrapperButton = googleLoginWrapper.querySelector('div[role=button]') as HTMLDivElement;
      return {
        click: () => {
          googleLoginWrapperButton.click();
        },
      };
    };

    socialAuthService.initState.pipe(take(1)).subscribe(() => {
      Promise.resolve(this.width).then((value) => {
        if (value > '400' || (value < '200' && value != '')) {
          Promise.reject('Please note .. max-width 400 , min-width 200 ' + '(https://developers.google.com/identity/gsi/web/tools/configurator)');
        } else {
          const googleButtonWrapper = createGoogleLoginWrapper(this.locale);
          window['handleGoogleLogin'] = () => {
            googleButtonWrapper.click();
          };

          const buttonText = this.customButtonText.length ? this.customButtonText : 'Google';

          const div = document.createElement('div');
          div.setAttribute('class', 'sm-login-btn google');
          div.setAttribute('onclick', 'handleGoogleLogin()');

          const icon = document.createElement('i');
          icon.setAttribute('class', 'fab fa-google');
          div.appendChild(icon);

          const text = document.createElement('span');
          text.textContent = buttonText;
          div.appendChild(text);

          if (el.nativeElement) {
            el.nativeElement.appendChild(div);
          }
        }
      });
    });
  }
}
