import { Expose, Type } from 'class-transformer';
import { UserExtraFeeApiModel } from './user-extra-fee.api.model';

export class UserTicketApiModel {
  @Expose()
  id: string;

  @Expose()
  seatId: string;

  @Expose()
  screeningId: string;

  @Expose()
  ticketId: string;

  @Expose()
  screenId: string;

  @Expose()
  price: number;

  @Expose()
  quantity: number;

  @Expose()
  moviePrintId: string;

  @Expose()
  movieId: string;

  @Expose()
  name: string;

  @Expose()
  orderId: string;

  @Expose()
  salesDocumentItemId: string;

  @Expose()
  @Type(() => UserExtraFeeApiModel)
  extraFees: UserExtraFeeApiModel[];

  @Expose()
  isReturned: boolean;

  @Expose()
  isTransferred: boolean;

  @Expose()
  transferred: number;

  @Expose()
  eventId: string;

  @Expose()
  voucherNumber: string;

  @Expose()
  voucherName: string;

  @Expose()
  voucherBatchName: string;

  @Expose()
  ticketNumber: string;

  @Expose()
  bookingId: string;

  @Expose()
  reservationId: string;
}
