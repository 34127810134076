<app-popup [isVisible]="true">
  <div class="row pb-4">
    <div class="col-12">
      <h4> <span class="font-weight-normal"> {{ 'refund.modal.title' | translate }}</span></h4>
      <div class="question">
        <span>
          {{
            'refund.modal.select_first_line' | translate: { 
              count: content.length, 
              plural_text: 'pluralMapper.ticket' | translate | translateSelector: content.length
            }
          }}
        </span>
        <br />
        <span *ngIf="hasRefundableItems">
          <b>{{ 'refund.modal.select_second_line' | translate }}</b>
        </span>
      </div>
    </div>
    <div class="p-3">
      <div *ngIf="hasRefundableItems" class="form-group align-content-center mb-3">
        <div class="checkbox">
          <label [for]="checkAllModel.id" class="d-flex align-content-center">
            <input type="checkbox" class="form-checkbox form-radio" [id]="checkAllModel.id"
                   [(ngModel)]="checkAllModel.checked" (change)="checkAll()">
            <span [className]="checkAllModel.checked ? 'checkbox-active cr' : 'cr'"><i
                 class="cr-icon fa fa-check"></i></span><span><b>{{'shared.checkAll' | translate}}</b></span>
          </label>
        </div>
      </div>
      <div class="form-group align-content-center" *ngFor="let item of content">
        <ng-container *ngIf="item.content.isRefundable else noRefundableTemplate">
          <div class="checkbox">
            <label [for]="item.id" class="d-flex align-content-center">
              <input type="checkbox" class="form-checkbox form-radio" [id]="item.id" [(ngModel)]="item.checked"
                     (ngModelChange)="checkGroup(item)">
              <span [class]="item.checked ? 'checkbox-active cr' : 'cr'">
                <i class="cr-icon fa fa-check"></i>
              </span>
              <ng-container *ngTemplateOutlet="label"></ng-container>
            </label>
          </div>
        </ng-container>

        <ng-template #noRefundableTemplate>
          <div class="checkbox">
            <label class="cursor-default">
              <span class="cr cr-none"></span>
              <ng-container *ngTemplateOutlet="label"></ng-container>
            </label>
          </div>
        </ng-template>

        <ng-template #label>
          <div class="d-flex align-items-center justify-content-between w-100 gap-05">
            <span class="text-left">{{item.name}}</span>
            <span *ngIf="item.innerContent" [innerHtml]="item.innerContent"
                  class="inner-content text-right text-nowrap"></span>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
  <div class="row" footer>
    <div class="col-12 text-center">
      <button type="button" class="btn btn-next btn-back" (click)="close()">
        {{ 'refund.modal.button.decline' | translate }}
      </button>
      <button *ngIf="hasRefundableItems" type="button" class="btn btn-next" (click)="accept()"
              [disabled]="acceptDisabled">
        {{ 'refund.modal.button.confirm' | translate }}
      </button>
    </div>
  </div>
</app-popup>