<app-popup [isVisible]="userLoyaltyPopupObject?.userLoyaltyPopupType === userLoyaltyPopupEnum.VoucherWarning">
  <div class="row pb-4">
    <div class="col-12">
      <h4>{{'voucher.input.error.cannot_use' | translate }}</h4>
      <div class="question">
        {{'voucher.voucher-order-required' | translate }}
      </div>
    </div>
  </div>
  <div class="row" footer>
    <div class="col-6 text-center">
      <button class="btn btn-default btn-navigation px-3 px-md-4 mb-sm-2" (click)="goToHomepage()">
        {{ 'payment.buy' | translate | uppercase }}
      </button>
    </div>
    <div class="col-6 text-center">
      <button class="btn btn-default btn-navigation px-3 px-md-4 mb-sm-2" (click)="clear()">
        {{ 'personal.button.ok' | translate | uppercase }}
      </button>
    </div>
  </div>
</app-popup>

<app-popup [isVisible]="userLoyaltyPopupObject?.userLoyaltyPopupType === userLoyaltyPopupEnum.VoucherError">
  <div class="row pb-4">
    <div class="col-12">
      <h4>{{userLoyaltyPopupObject?.message ?? 'voucher.input.error.cannot_use' | translate }}</h4>
    </div>
  </div>
  <div class="row" footer>
    <div class="col-12 text-center">
      <button class="btn btn-default btn-navigation px-3 px-md-4 mb-sm-2" (click)="clear()">
        {{ 'personal.button.ok' | translate | uppercase }}
      </button>
    </div>
  </div>
</app-popup>

<app-popup [isVisible]="userLoyaltyPopupObject?.userLoyaltyPopupType === userLoyaltyPopupEnum.CardWarning">
  <div class="row pb-4">
    <div class="col-12">
      <h4>{{'gift-card.cannot_use' | translate }}</h4>
      <div class="question">
        {{'gift-card.giftacrd-order-required' | translate }}
      </div>
    </div>
  </div>
  <div class="row" footer>
    <div class="col-6 text-center">
      <button class="btn btn-default btn-navigation px-3 px-md-4 mb-sm-2" (click)="goToHomepage()">
        {{ 'payment.buy' | translate | uppercase }}
      </button>
    </div>
    <div class="col-6 text-center">
      <button class="btn btn-default btn-navigation px-3 px-md-4 mb-sm-2" (click)="clear()">
        {{ 'personal.button.ok' | translate | uppercase }}
      </button>
    </div>
  </div>
</app-popup>

<app-popup [isVisible]="userLoyaltyPopupObject?.userLoyaltyPopupType === userLoyaltyPopupEnum.CardError">
  <div class="row pb-4">
    <div class="col-12">
      <h4>{{userLoyaltyPopupObject?.message ?? 'gift-card.cannot_use' | translate }}</h4>
    </div>
  </div>
  <div class="row" footer>
    <div class="col-12 text-center">
      <button class="btn btn-default btn-navigation px-3 px-md-4 mb-sm-2" (click)="clear()">
        {{ 'personal.button.ok' | translate | uppercase }}
      </button>
    </div>
  </div>
</app-popup>