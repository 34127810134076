import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Settings } from 'luxon';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { storageKey } from '../../app.const';
import { LanguageDataProvider } from '../data-provider/language.data-provider';
import { ENVIRONMENT_TOKEN } from '../injection.tokens';
import { StateService } from '../state/state.service';
import { of, switchMap, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TranslationService {
  public prevLang = '';

  public constructor(
    @Inject(ENVIRONMENT_TOKEN) protected environment: any,
    protected languageDataProvider: LanguageDataProvider,
    protected bsLocaleService: BsLocaleService,
    protected translateService: TranslateService,
    protected stateService: StateService
  ) {
    this.setupLanguage();
  }

  public setupLanguage() {
    this.translateService.addLangs(this.environment.constants.language.available);
    this.prevLang = this.stateService.getItem(storageKey.prevLang);

    const lang =
      this.searchLanguageInUrl(this.environment.constants.language.available) ??
      this.stateService.getItem(storageKey.lang) ??
      this.environment.constants.language.default;

    this.translateService.setDefaultLang(lang);
    Settings.defaultLocale = lang;
    this.useLanguage(lang);
  }

  public isTranslationExists(translationKey: string): boolean {
    return this.instant(translationKey) !== translationKey;
  }

  useLanguage(lang: string) {
    // await the http request for the translation file
    this.stateService.setItem(storageKey.lang, lang);
    this.bsLocaleService.use(lang);
    this.translateService.use(lang);
  }

  public get onLangChange() {
    return this.translateService.onLangChange.pipe(
      switchMap((value) => {
        return value && this.prevLang && this.translateService.currentLang != this.prevLang ? of(value) : of(null);
      }),
      tap(() => {
        this.setPrevLang();
      })
    );
  }

  private setPrevLang() {
    this.prevLang = this.translateService.currentLang;
    this.stateService.setItem(storageKey.prevLang, this.prevLang);
  }

  public instant(key: string | Array<string>, interpolateParams?: Object): string | any {
    return this.translateService.instant(key, interpolateParams);
  }

  public getLangs() {
    return this.translateService.getLangs();
  }

  get currentLang(): string {
    return this.translateService.currentLang;
  }
  get getBrowserLang(): string {
    return this.translateService.getBrowserLang();
  }
  get getBrowserCultureLang(): string {
    return this.translateService.getBrowserCultureLang();
  }

  private searchLanguageInUrl(langs: string[]): string | null {
    return langs.find((lang) => window.location.pathname.includes(`/${lang}/`)) || null;
  }
}
