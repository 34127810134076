import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NavigationHelperService } from 'libs/core/src/lib/service/navigation/navigation-helper.service';
import { OrderStateService } from 'libs/core/src/lib/state/order.state.service';
import { TranslateService } from '@ngx-translate/core';
import { OrderDataProvider } from 'libs/core/src/lib/data-provider/order.data-provider';
import { CountdownComponentService } from 'libs/core/src/lib/service/countdown.service';
import { MessageService } from 'libs/core/src/lib/service/message.service';
import { ResponseValidatorService } from 'libs/core/src/lib/service/validator/response-validator.service';
import { AuthStateService } from 'libs/core/src/lib/state/auth.state.service';
import { BasketDataProvider } from 'libs/core/src/lib/data-provider/basket.data-provider';
import { GoogleTagManagerService } from 'libs/core/src/lib/service/analytics-services/google-tag-manager.service';
import { LoaderEnum } from 'libs/core/src/lib/enum/loader.enum';
import { LoadingService } from 'libs/core/src/lib/service/loading.service';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { oneilValidationPattern } from 'libs/core/src/app.const';
import { TheSameFormValidator } from 'libs/core/src/lib/tool/form/validator/the-same.form-validator';
import { UserDataProvider } from 'libs/core/src/lib/data-provider/user.data-provider';
import { AgreementAggregationViewModel } from 'libs/core/src/lib/model/view-model/agreement/agreement-aggregation.view.model';
import { UserViewModel } from 'libs/core/src/lib/model/view-model/user/user.view.model';
import { LoginHelper } from 'libs/core/src/lib/helper/login.helper';
import { HttpErrorResponse } from '@angular/common/http';
import { filter, switchMap } from 'rxjs';
import { UserLoginRequestModel } from 'libs/core/src/lib/model/request/user-login.request.model';
import { UserSetpasswordRequestModel } from 'libs/core/src/lib/model/request/user-setpassword.request.model';
import { NavigationService } from 'libs/core/src/lib/service/navigation/navigation.service';
import { ErrorHandlerService } from 'libs/core/src/lib/service/error-handler/error-handler.service';

export enum PersonalStateEnum {
  Initial = 'initial',
  Success = 'success',
  Failed = 'failed',
}

@Component({
  selector: 'app-user-register',
  templateUrl: './user-register.component.html',
})
export class OneilUserRegisterComponent implements OnInit {
  public displayMode: 'register' | 'resetPassword' | 'setPassword' | 'confirmRegistration';

  public state = PersonalStateEnum.Initial;
  public stateEnum: typeof PersonalStateEnum = PersonalStateEnum;

  public registerForm: FormGroup = null;
  public setPasswordForm: FormGroup = null;
  public resetPasswordForm: FormGroup = null;

  public errorCode: string = null;

  private userLoginRequestModel: UserLoginRequestModel = null;

  public registerFormErrors: Object = null;
  private token: string = null;

  public constructor(
    protected responseValidator: ResponseValidatorService,
    protected orderDataProvider: OrderDataProvider,
    protected orderStateService: OrderStateService,
    protected navigationHelper: NavigationHelperService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected countdownComponentService: CountdownComponentService,
    protected messageService: MessageService,
    protected translate: TranslateService,
    protected authStateService: AuthStateService,
    protected basketDataProvider: BasketDataProvider,
    protected googleTagManagerService: GoogleTagManagerService,
    protected loadingService: LoadingService,
    protected userDataProvider: UserDataProvider,
    private navigationService: NavigationService,
    private errorHandlerService: ErrorHandlerService
  ) {}

  ngOnInit(): void {
    this.loadingService.hideLoader(LoaderEnum.MAIN);

    this.route.params.subscribe((params) => {
      this.displayMode = params['displaymode'];
      switch (this.displayMode) {
        case 'register':
          this.userDataProvider.getAgreements().subscribe((agreements: AgreementAggregationViewModel) => {
            this.registerForm = this.getRegisterForm({ agreements: agreements.marketingAgreements });
          });
          break;
        case 'resetPassword':
          this.resetPasswordForm = this.getResetPasswordForm();
          break;
        case 'setPassword':
          this.setPasswordForm = this.getSetPasswordForm(params['token']);
          break;
        case 'confirmRegistration':
          this.userDataProvider.registrationConfirm(params['token']).subscribe({
            next: (res) => {
              this.state = PersonalStateEnum.Success;
            },
            error: (error) => {
              this.state = PersonalStateEnum.Failed;
            },
          });

          break;
      }
    });
  }

  public getRegisterForm(data: any): FormGroup {
    const group = new FormGroup(
      {
        firstName: new FormControl('', [Validators.required]),
        lastName: new FormControl('', [Validators.required]),
        email: new FormControl('', [Validators.required, Validators.pattern(oneilValidationPattern.email)]),
        password: new FormControl('', [
          Validators.required,
          Validators.pattern(oneilValidationPattern.password), // 100 - SOAP limit
        ]),
        repeatPassword: new FormControl('', [Validators.required]),
        birthday: new FormControl(''),
        agreements: new FormArray([]),
      },
      {
        validators: [new TheSameFormValidator('password', 'repeatPassword', 'notMatch').getValidator()],
      }
    );

    if (data.agreements) {
      data.agreements.map((o, i) => {
        const formControl = new FormControl(null);
        if (o.required) {
          formControl.setValidators(Validators.required);
        }

        (group.get('agreements') as FormArray).insert(i, formControl);
      });
    }

    return group;
  }

  public submitForm(form: 'registerForm' | 'resetPasswordForm' | 'setPasswordForm') {
    this.errorCode = null;

    (this[form] as FormGroup).updateValueAndValidity();

    if ((this[form] as FormGroup).valid) {
      this.loadingService.showLoader(LoaderEnum.MAIN);

      if (form === 'registerForm') {
        const requestModel: UserViewModel = Object.assign(new UserViewModel(), this[form].value);
        this.userDataProvider.create(requestModel).subscribe({
          next: (user) => {
            this.userLoginRequestModel = Object.assign(new UserLoginRequestModel(), {
              username: user.email,
              password: requestModel['password'],
            });

            this.loadingService.hideLoader(LoaderEnum.MAIN);
            this.state = PersonalStateEnum.Success;
            this.resetForm(form);
          },
          error: (e: HttpErrorResponse) => {
            this.errorCode = this.errorHandlerService.getCode(e);
            this.loadingService.hideLoader(LoaderEnum.MAIN);
          },
        });
      } else if (form === 'resetPasswordForm') {
        const formData = Object.assign({}, this[form].value);
        this.userDataProvider.remindPassword(formData['email']).subscribe({
          next: (response) => {
            this.loadingService.hideLoader(LoaderEnum.MAIN);
            this.state = PersonalStateEnum.Success;
            this.resetForm(form);
          },
          error: (e: HttpErrorResponse) => {
            this.errorCode = this.errorHandlerService.getCode(e);
            this.loadingService.hideLoader(LoaderEnum.MAIN);
          },
        });
      } else if (form === 'setPasswordForm') {
        const formData = Object.assign({}, this[form].value);

        const requestModel = new UserSetpasswordRequestModel();
        requestModel.passwordToken = formData['token'];
        requestModel.password = formData['password'];

        this.userDataProvider.setPassword(requestModel).subscribe({
          next: (response) => {
            this.loadingService.hideLoader(LoaderEnum.MAIN);
            this.clearTokenQuery();
            this.state = PersonalStateEnum.Success;
            this.resetForm(form);
          },
          error: (e: HttpErrorResponse) => {
            this.errorCode = this.errorHandlerService.getCode(e);
            this.loadingService.hideLoader(LoaderEnum.MAIN);
          },
        });
      }
    } else {
      (this[form] as FormGroup).markAsTouched();
    }
  }

  resetForm(form: 'registerForm' | 'resetPasswordForm' | 'setPasswordForm') {
    this[form].reset();
  }

  public getResetPasswordForm(): FormGroup {
    const group = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.pattern(oneilValidationPattern.email)]),
    });

    return group;
  }

  public getSetPasswordForm(token: string): FormGroup {
    const group = new FormGroup(
      {
        token: new FormControl(token),
        password: new FormControl('', [
          Validators.required,
          Validators.pattern(oneilValidationPattern.password), // 100 - SOAP limit
        ]),
        repassword: new FormControl('', [Validators.required]),
      },
      {
        validators: [new TheSameFormValidator('password', 'repassword', 'notMatch').getValidator()],
      }
    );

    return group;
  }

  public fieldIsInvalid(name: string, form: 'registerForm' | 'setPasswordForm' | 'resetPasswordForm'): boolean {
    const field = this[form].get(name);
    return field.invalid && (field.touched || this[form].touched);
  }

  public passwordIsValid(form: 'registerForm' | 'setPasswordForm'): boolean {
    return LoginHelper.passwordIsValid(this[form].get('password'), new FormControl());
  }

  public tryLoginAndGoForward() {
    if (!this.userLoginRequestModel) {
      return;
    }

    this.authStateService.login(this.userLoginRequestModel).subscribe({
      next: (user) => {
        this.authStateService.state$
          .pipe(
            filter(() => this.authStateService.userIsLoggedAndTokenIsValid()),
            switchMap((u) => this.orderStateService.state$),
            filter((o) => o && (!o.userEmail || o.userEmail === '')),
            switchMap((s) => this.orderDataProvider.updateOrderUserData(s.cinemaId, s, user.toApiModel()))
          )
          .subscribe((s) => {
            this.loadingService.hideLoader(LoaderEnum.MAIN);
            this.navigationService.back();
          });
      },
      error: (error: HttpErrorResponse) => {
        this.loadingService.hideLoader(LoaderEnum.MAIN);
        this.errorCode = LoginHelper.getErrorMessage(this.errorHandlerService.getError(error).code);
      },
    });
  }

  stateBack() {
    if (this.state === PersonalStateEnum.Failed) {
      this.state = PersonalStateEnum.Initial;
      this.errorCode = null;
    }
  }

  private clearTokenQuery() {
    window.history.replaceState({}, '', '/');
  }

  setPasswordButtonClick() {
    if (this.state === PersonalStateEnum.Success) {
      this.navigationService.goToHomePage(true);
    } else {
      this.navigationService.goToHomePage();
    }
  }
}
