import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-password-input',
  styleUrls: ['./password-input.component.scss'],
  templateUrl: './password-input.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: PasswordInputComponent,
      multi: true,
    },
  ],
})
export class PasswordInputComponent implements ControlValueAccessor {
  value: string;

  showPassword = false;
  touched = false;
  disabled = false;

  @Input('maxlength') maxlength: string | null = null;
  @Input('autocomplete') autocomplete: string = 'new-password';
  @Input('class') class: string = 'form-control pr-5';
  @Input() tabStart = 1;

  @ViewChild('inputElement') inputElement: ElementRef;

  constructor() {}

  ngOnInit(): void {
    if (!!this.maxlength) {
      this.inputElement?.nativeElement?.setAttribute('maxlength', this.maxlength);
    }
  }

  onChange = (value: string) => {};
  onTouched = () => {};

  writeValue(value: string) {
    this.onChange(value);
    this.value = value;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }
}
