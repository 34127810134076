import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { MessageService } from 'libs/core/src/lib/service/message.service';
import { MessageModel } from 'libs/core/src/lib/model/message.model';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
})
export class MessageComponent implements OnInit, AfterViewInit {
  public message$: Observable<MessageModel>;
  @Input() public custom: boolean;
  @ViewChild('messageElement', { read: ElementRef, static: true })
  public el: ElementRef;

  constructor(protected messageService: MessageService) {}

  ngOnInit() {
    this.message$ = this.messageService.state$;
  }

  ngAfterViewInit(): void {
    this.message$.subscribe((value) => {
      if (this.el && value) {
        if (this.custom) {
          const targetElement = this.el.nativeElement;
          targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        } else window.scrollTo(0, 0);
      }
    });
  }

  onClose() {
    this.messageService.clear();
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView();
  }
}
