import { DateTime } from 'luxon';
import { AbstractViewModel } from '../../abstract.view.model';
import { CardTypeApiModel } from '../../api-model/card/card-type.api.model';
import { UserCardTypeViewModel } from '../user-history/card-type/user-card-type.view.model';
import { CardTypeBatchViewModel } from './card-type-batch.view.model';
import { CardTypeGraphicTemplateViewModel } from './card-type-graphic-template.view.model';
import { CardTypeMembershipExtensionFeeViewModel } from './card-type-membership-extension-fee.view.model';
import { CardTypeMembershipUpgradeFeeViewModel } from './card-type-membership-upgrade-fee.view.model';
import { CardTypeTypeViewModel } from './card-type-type.view.model';
import { CardTypeValueViewModel } from './card-type-value.view.model';
import { CardActiveDiscountViewModel } from './card-active-discount.view.model';
import { CardFieldTemplateViewModel } from './card-field-template.view.model';
import { CardTypeMembershipDowngradeFeeViewModel } from './card-type-membership-downgrade-fee.view.model';

export class CardTypeViewModel extends AbstractViewModel<CardTypeApiModel> {
  public id: string = null;
  public name: string = null;
  public description: string = null;
  public multipleUse: boolean = null;
  public rechargeable: boolean = null;
  public expirationDate: DateTime = null;
  public expirationType: number = null;
  public daysFromIssueToExpiration: number = null;
  public flgEndOfMonth: boolean = null;
  public monthsFromIssueToExpiration: number = null;
  public autoNumber: boolean = null;
  public issueFee: number = null;
  public replacementFee: number = null;
  public maximumBalance: number = null;
  public minTopUpValue: number;
  public maxTopUpValue: number;
  public graphic: string = null;
  public canBeIssued: boolean = null;
  public flgNeedPayment: boolean = null;
  public membershipNewFee: number = null;
  public reservationsAllowed: boolean = null;
  public reservationsPickUpExpiryTime: number = null;
  public earlierAccessToScheduleTime: number = null;
  public cinemaList: string[] = null;
  public types: CardTypeTypeViewModel[];
  public valueList: CardTypeValueViewModel[];
  public discountList: CardActiveDiscountViewModel[];
  public graphicTemplateList: CardTypeGraphicTemplateViewModel[];
  public fieldTemplateList: CardFieldTemplateViewModel[];
  public membershipExtensionFeeList: CardTypeMembershipExtensionFeeViewModel[];
  public membershipUpgradeFeeList: CardTypeMembershipUpgradeFeeViewModel[];
  public membershipDowngradeFeeList: CardTypeMembershipDowngradeFeeViewModel[];
  public batchList: CardTypeBatchViewModel[];

  constructor(protected apiModel: CardTypeApiModel = new CardTypeApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.id = this.apiModel.id;
    this.name = this.apiModel.name;
    this.description = this.apiModel.description;
    this.multipleUse = this.apiModel.multipleUse;
    this.rechargeable = this.apiModel.rechargeable;
    this.expirationDate = this.apiModel.expirationDate ? DateTime.fromISO(this.apiModel.expirationDate) : null;
    this.expirationType = this.apiModel.expirationType;
    this.daysFromIssueToExpiration = this.apiModel.daysFromIssueToExpiration;
    this.flgEndOfMonth = this.apiModel.flgEndOfMonth;
    this.monthsFromIssueToExpiration = this.apiModel.monthsFromIssueToExpiration;
    this.autoNumber = this.apiModel.autoNumber;
    this.issueFee = this.apiModel.issueFee;
    this.replacementFee = this.apiModel.replacementFee;
    this.maximumBalance = this.apiModel.maximumBalance;
    this.graphic = this.apiModel.graphic;
    this.canBeIssued = this.apiModel.canBeIssued;
    this.flgNeedPayment = this.apiModel.flgNeedPayment;
    this.membershipNewFee = this.apiModel.membershipNewFee;
    this.reservationsAllowed = this.apiModel.reservationsAllowed;
    this.reservationsPickUpExpiryTime = this.apiModel.reservationsPickUpExpiryTime;
    this.earlierAccessToScheduleTime = this.apiModel.earlierAccessToScheduleTime;
    this.cinemaList = this.apiModel.cinemaList;
    this.types = this.apiModel.types ? this.apiModel.types.map((type) => new CardTypeTypeViewModel(type)) : [];
    this.valueList = this.apiModel.valueList ? this.apiModel.valueList.map((value) => new CardTypeValueViewModel(value)) : [];
    this.discountList = this.apiModel.discountList ? this.apiModel.discountList.map((value) => new CardActiveDiscountViewModel(value)) : [];
    this.graphicTemplateList = this.apiModel.graphicTemplateList
      ? this.apiModel.graphicTemplateList.map((graphicTemplate) => new CardTypeGraphicTemplateViewModel(graphicTemplate))
      : [];
    this.fieldTemplateList = this.apiModel.fieldTemplateList
      ? this.apiModel.fieldTemplateList.map((fieldTemplate) => new CardFieldTemplateViewModel(fieldTemplate))
      : [];
    this.membershipExtensionFeeList = this.apiModel.membershipExtensionFeeList
      ? this.apiModel.membershipExtensionFeeList.map((extension) => new CardTypeMembershipExtensionFeeViewModel(extension))
      : [];
    this.membershipUpgradeFeeList = this.apiModel.membershipUpgradeFeeList
      ? this.apiModel.membershipUpgradeFeeList.map((upgrade) => new CardTypeMembershipUpgradeFeeViewModel(upgrade))
      : [];
    this.membershipDowngradeFeeList = this.apiModel.membershipDowngradeFeeList
      ? this.apiModel.membershipDowngradeFeeList.map((downgrade) => new CardTypeMembershipDowngradeFeeViewModel(downgrade))
      : [];
    this.batchList = this.apiModel.batchList ? this.apiModel.batchList.map((batch) => new CardTypeBatchViewModel(batch)) : [];
    this.minTopUpValue = this.apiModel.minTopUpValue ?? 1;
    this.maxTopUpValue = this.apiModel.maxTopUpValue ?? 250;
  }

  toApiModel(): CardTypeApiModel {
    return undefined;
  }

  toUserCardTypeViewModel(extra?: UserCardTypeViewModel): UserCardTypeViewModel {
    const model = Object.assign(extra ?? new UserCardTypeViewModel(), {
      id: this.id,
      typeName: this.name,
      typeId: this.id,
      graphic: this.graphic,
      graphicTemplateList: this.graphicTemplateList.map((item) => item.toUserCardTypeTemplateViewModel()),
      types: this.types.map((item) => item.toUserCardTypeTypeViewModel()),
      canBeIssued: this.batchList.some((item) => item.canBeIssued),
    });
    return model;
  }
}
