import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { OrderDataProvider } from 'libs/core/src/lib/data-provider/order.data-provider';
import { OrderAliasViewModel } from 'libs/core/src/lib/model/view-model/order/order-alias.view.model';
import { VirtualPassViewModel } from 'libs/core/src/lib/model/view-model/sales-document/virtual-pass/virtual-pass.view.model';
import { MobileDeviceUtils } from 'libs/core/src/lib/utilities/mobile-device-utils';
import { ENVIRONMENT_TOKEN, makeUrl } from 'libs/core/src/public-api';
import { of, mergeMap, map } from 'rxjs';
import { VirtualPassProviderEnum } from './enum/virtual-pass-provider.enum';

@Component({
  template: '',
})
export class VirtualPassComponent implements OnInit {
  private sem = false;
  public virtualPassProvider: typeof VirtualPassProviderEnum = VirtualPassProviderEnum;
  public isAppleEnabled = false;
  public isGoogleEnabled = false;

  @Input() public bookingId: string;
  @Input() public cinemaId: string;
  @Input() public salesDocumentId: string;
  @Input() public screeningId: string | undefined = undefined;
  @Input() public screeningItemId: string;
  @Input() public ticketNumber: string;
  @Input() public passUrl: string;
  @Input() public mode: 'reservation' | 'ticket' | 'alltickets' | undefined = undefined;

  @Output() public loadingStatus = new EventEmitter<boolean>();

  constructor(@Inject(ENVIRONMENT_TOKEN) protected environment: any, protected orderDataProvider: OrderDataProvider) {}

  ngOnInit(): void {
    this.isAppleEnabled = this.environment?.virtualPass?.indexOf(VirtualPassProviderEnum.APPLE) >= 0;
    this.isGoogleEnabled = this.environment?.virtualPass?.indexOf(VirtualPassProviderEnum.GOOGLE) >= 0;
  }

  public onClickedButton(event: Event, virtualPassProvider: string): void {
    this.loadingStatus.emit(true);
    event.preventDefault();

    if (this.sem) {
      return;
    }

    this.sem = true;

    (this.cinemaId && this.salesDocumentId
      ? of({ cinemaId: this.cinemaId, salesDocumentId: this.salesDocumentId } as OrderAliasViewModel)
      : this.orderDataProvider.getOrderTransactionNumber(this.bookingId)
    )
      .pipe(
        mergeMap((orderAlias: OrderAliasViewModel) =>
          this.orderDataProvider.getVirtualPass(orderAlias.cinemaId, orderAlias.salesDocumentId, virtualPassProvider, this.screeningId, this.mode)
        ),
        map((virtualPasses: VirtualPassViewModel[]) => {
          if (this.screeningId) {
            return virtualPasses.find((o) => o.screeningId === this.screeningId);
          } else if (this.screeningItemId) {
            return virtualPasses.find((o) => o.screeningItemId === this.screeningItemId);
          } else if (this.ticketNumber) {
            return virtualPasses.find((o) => o.ticketNumber === this.ticketNumber);
          }

          return virtualPasses[0];
        })
      )
      .subscribe((virtualPass: VirtualPassViewModel) => {
        this.loadingStatus.emit(false);
        this.sem = false;
        var link = document.createElement('a');
        link.href = virtualPass.passUrl;
        link.click();
      });
  }

  public get isSafari(): boolean {
    return MobileDeviceUtils.isSafari();
  }

  public get isMobile(): boolean {
    return MobileDeviceUtils.isMobile();
  }

  public makeUrl(url: string) {
    return makeUrl(this.environment, url);
  }
}
