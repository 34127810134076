import { DateTime } from 'luxon';
import { SupportedDateTimeFormat } from './supported-datetime-format.enum';
import { WeekdayService } from '../service/weekday.service';
import { BaseLocaleData } from './base.locale-date';

export class SQ_XK_LocaleData extends BaseLocaleData {
  constructor(protected weekdayService: WeekdayService) {
    super(weekdayService);
  }

  format(value: DateTime, format: SupportedDateTimeFormat) {
    switch (format) {
      case SupportedDateTimeFormat.DAY_SHORT:
        return value.toFormat('ccc');
      case SupportedDateTimeFormat.MONTH_SHORT:
        return value.toFormat('MMM');
      case SupportedDateTimeFormat.MONTH_WIDE:
        return value.toFormat('MMMM');
      case SupportedDateTimeFormat.DATE_SHORT_WITH_WEEKDAY:
        const customFormat1 = ["'" + (this.customWeekday(value) ?? this.localeData.weekdays[value.weekday - 1]) + "'", ', dd.MM.yyyy'].join('');
        return value.toFormat(customFormat1);
      case SupportedDateTimeFormat.DATE_SHORT_WITH_WEEKDAY_SHORT:
        const customFormat2 = ["'" + (this.customWeekday(value) ?? this.localeData.weekdaysShort[value.weekday - 1]) + "'", ', dd.MM.yyyy'].join('');
        return value.toFormat(customFormat2);
      case SupportedDateTimeFormat.DAY_MONTH_WITH_WEEKDAY_SHORT:
        const customFormat3 = ["'" + (this.customWeekday(value) ?? this.localeData.weekdaysShort[value.weekday - 1]) + "'", ', dd.MM'].join('');
        return value.toFormat(customFormat3);
      case SupportedDateTimeFormat.DATE_SHORT_WITH_WEEKDAY_FULL:
        const customFormat4 = [
          "'" + (this.customWeekday(value) ?? this.localeData.weekdaysShort[value.weekday - 1]) + "'",
          ', ',
          "'" + (this.customWeekday(value) ?? this.localeData.months[value.month]) + "'",
          ' d, yyyy',
        ].join('');
        return value.toFormat(customFormat4);
      case SupportedDateTimeFormat.TIME_SIMPLE:
        return value.toFormat('HH:mm');
      case SupportedDateTimeFormat.CARD_EXPIRATION:
        return value.toFormat('MM/yy');
      default:
        return value.toLocaleString();
    }
  }

  localeData = {
    months: ['Janar', 'Shkurt', 'Mars', 'Prill', 'Maj', 'Qershor', 'Korrik', 'Gusht', 'Shtator', 'Tetor', 'Nëntor', 'Dhjetor'],
    monthsShort: ['Jan', 'Shk', 'Mar', 'Pri', 'Maj', 'Qer', 'Kor', 'Gsh', 'Sht', 'Tet', 'Nën', 'Dhj'],
    weekdays: ['E Hënë', 'E Martë', 'E Mërkurë', 'E Enjte', 'E Premte', 'E Shtunë', 'E Diel'],
    weekdaysShort: ['Hën', 'Mar', 'Mër', 'Enj', 'Pre', 'Sht', 'Die'],
    weekdaysMin: ['Hë', 'Ma', 'Më', 'En', 'Pr', 'Sh', 'Di'],
    firstDayOfWeek: 1, // Monday
    relativeTime: {
      today: 'Sot',
      tomorrow: 'Nesër',
      future: 'në %s',
      past: '%s më parë',
      s: 'pak se një minutë',
      m: '1 minutë',
      mm: '{count} minuta',
      h: '1 orë',
      hh: '{count} orë',
      d: '1 ditë',
      dd: '{count} ditë',
      M: '1 muaj',
      MM: '{count} muaj',
      y: '1 vit',
      yy: '{count} vite',
    },
  };
}

export const SQ_XK_DatePartOrder = 'dmy';
