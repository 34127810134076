import { EventEmitter } from '@angular/core';
import { SocialUser } from './social-user';

export abstract class LoginProvider implements ILoginProvider {
  constructor() {}

  readonly changeUser?: EventEmitter<SocialUser>;
  abstract initialize(autoLogin?: boolean): Promise<void>;
  abstract getLoginStatus(): Promise<SocialUser>;
  abstract signIn(signInOptions?: object): Promise<SocialUser>;
  abstract signOut(revoke?: boolean): Promise<void>;
  refreshToken?(): Promise<SocialUser | null>;
}

export interface ILoginProvider {
  readonly changeUser?: EventEmitter<SocialUser>;
  initialize(autoLogin?: boolean): Promise<void>;
  getLoginStatus(): Promise<SocialUser>;
  signIn(signInOptions?: object): Promise<SocialUser>;
  signOut(revoke?: boolean): Promise<void>;
  refreshToken?(): Promise<SocialUser | null>;
}
