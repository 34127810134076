import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { plainToInstance } from 'class-transformer';
import { AgreementAggregationApiModel } from '../model/api-model/agreement/agreement-aggregation.api.model';
import { Cacheable, LocalStorageStrategy } from 'ts-cacheable';

@Injectable({
  providedIn: 'root',
})
export class UserAgreementHttpService {
  public static cacheBuster$ = new Subject<void>();

  constructor(private http: HttpClient) {}

  @Cacheable({
    cacheBusterObserver: UserAgreementHttpService.cacheBuster$,
    storageStrategy: LocalStorageStrategy,
    maxAge: 3600000,
  })
  getAgreements(): Observable<AgreementAggregationApiModel> {
    return this.http
      .get<AgreementAggregationApiModel[]>(`/user/agreement`)
      .pipe(map((result) => plainToInstance(AgreementAggregationApiModel, result as object)));
  }
}
