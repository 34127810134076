<div class="order-summary-container">
  <div class="container">

    <div class="main-container" *ngIf="loadingService.loadingStatus && loadingService.loadingStatus !== 'pending'">
      <ng-container *ngIf="screenMap">
        <div class="d-flex flex-column pt-2" *ngIf="loadingService.loadingStatus === 'success'; else loading">
          <ng-container [ngSwitch]="order.status">
            <div class="pt-5 pb-5">
              <ng-container *ngSwitchCase="4">
                <h7 class="text-center font-weight-bold transaction-successful m-0">{{
                      'order.summary.head.success.part1' | translate }}</h7>
                <ng-container *ngIf="order.bookingId">
                  <p class="transaction-details text-center"> {{'order.summary.head.title' | translate}}:
                    <strong>{{order.bookingId}}</strong>
                  </p>
                  <p class="transaction-details m-0 text-center">{{'order.summary.head.subtitle' | translate}}</p>
                </ng-container>
              </ng-container>
              <ng-container *ngSwitchCase="5">
                <h7 class="text-center font-weight-bold transaction-failed m-0">
                  {{ 'order.summary.head.failed' |  translate }}
                </h7>
              </ng-container>
              <ng-container *ngSwitchDefault>
                <h7 class="text-center font-weight-bold transaction-pending m-0">
                  {{ 'order.summary.head.pending' | translate }}
                </h7>
              </ng-container>
            </div>
          </ng-container>
        </div>

        <div class="row">
          <div class="col-12">
            <app-sidebar>
              <div sidebar-title>
                ORDER SUMMARY
              </div>
              <div sidebar-body>
                <app-order-order-summary [order]="order" [selectedVoucherList]="selectedVoucherList"
                                         [basketPageModel]="basketPageModel" [isLastPage]="true"
                                         [selectedCateringArticleCombinationList]="selectedCateringArticleCombinationList">
                </app-order-order-summary>
              </div>
            </app-sidebar>
          </div>
        </div>

        <div class="mt-4" *ngIf="!order?.isOnlyGiftCardOrder()">
          <app-order-virtual-pass [bookingId]="order?.bookingId"
                                  (loadingStatus)="generatingVirtualPass = $event"></app-order-virtual-pass>
        </div>

        <div class="row">
          <div class="col-12 mt-4 d-flex justify-content-center justify-content-sm-end">
            <button class="btn btn-primary" (click)="backToMain()">
              {{ 'order.ticketsSummary.backToMain' | translate }}
            </button>
          </div>
        </div>
      </ng-container>

    </div>
  </div>
</div>

<app-loading *ngIf="generatingVirtualPass"></app-loading>

<ng-template #modal>
  <div class="modal-body text-center p-5">
    <svg class="mb-3" style="fill: white; font-size: 3rem;" xmlns="http://www.w3.org/2000/svg" height="1em"
         viewBox="0 0 512 512">
      <path
            d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-384c13.3 0 24 10.7 24 24V264c0 13.3-10.7 24-24 24s-24-10.7-24-24V152c0-13.3 10.7-24 24-24zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z" />
    </svg>
    <h4>{{ 'order.summary.modal.title' | translate }}</h4>
    <div class="mb-4">
      <span>{{ 'order.summary.modal.description' | translate }}</span>
    </div>
    <button class="btn btn-next" (click)="onModalClose()">Close</button>
  </div>
</ng-template>