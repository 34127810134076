<div class="panel-head">
  <label class="radio-container">
    <input id="{{paymentType}}" type="radio" name="pay_method" (click)="paymentProviderClick()" [checked]="selected"
           [ngClass]="{'selected': selected}" [value]="paymentProvider">
    <span class="label">{{properTranslation}} <ng-content select="[label]"></ng-content> </span>
    <span class="checkmark" [class.active]="selected" [class.noActive]="!selected"></span>
  </label>
  <div class="image">
    <img *ngIf="paymentImgUrl" [src]="paymentImgUrl" />
  </div>
</div>
<ng-content></ng-content>