import { DateTime } from 'luxon';
import { SupportedDateTimeFormat } from './supported-datetime-format.enum';
import { WeekdayService } from '../service/weekday.service';
import { BaseLocaleData } from './base.locale-date';

export class SL_SI_LocaleData extends BaseLocaleData {
  constructor(protected weekdayService: WeekdayService) {
    super(weekdayService);
  }

  format(value: DateTime, format: SupportedDateTimeFormat) {
    switch (format) {
      case SupportedDateTimeFormat.DAY_SHORT:
        return value.toFormat('ccc');
      case SupportedDateTimeFormat.MONTH_SHORT:
        return value.toFormat('MMM');
      case SupportedDateTimeFormat.MONTH_WIDE:
        return value.toFormat('MMMM');
      case SupportedDateTimeFormat.DATE_SHORT_WITH_WEEKDAY:
        const cwf1 = this.customWeekdayFormat(value, 'cccc, dd.MM.yyyy');
        return value.toFormat(cwf1);
      case SupportedDateTimeFormat.DATE_SHORT_WITH_WEEKDAY_SHORT:
        const cwf2 = this.customWeekdayFormat(value, 'ccc, dd.MM.yyyy', 'ccc');
        return value.toFormat(cwf2);
      case SupportedDateTimeFormat.DAY_MONTH_WITH_WEEKDAY_SHORT:
        const cwf3 = this.customWeekdayFormat(value, 'ccc, dd.MM', 'ccc');
        return value.toFormat(cwf3);
      case SupportedDateTimeFormat.DATE_SHORT_WITH_WEEKDAY_FULL:
        const cwf4 = this.customWeekdayFormat(value, 'cccc, MMMM d, yyyy');
        return value.toFormat(cwf4);
      case SupportedDateTimeFormat.TIME_SIMPLE:
        return value.toFormat('HH:mm');
      case SupportedDateTimeFormat.CARD_EXPIRATION:
        return value.toFormat('MM/yy');
      default:
        return value.toLocaleString();
    }
  }
}

export const SL_SI_DatePartOrder = 'dmy';
