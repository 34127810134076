<div class="container">
  <div class="row">
    <div class="text-center col-12 p-0">
      <app-steps [step]="1"></app-steps>
    </div>
  </div>
  <div class="main-container">
    <div [smooth-in]="loadingService.isLoaded(loaderEnum.MAIN)">
      <ng-container *ngIf="screen">
        <div class="row">
          <div class="col-12 col-md-7 order-2 order-md-1 mt-2 mt-md-5 mb-5">
            <app-book-movie-list [screen]="screen" [cinemaId]="cinemaId"></app-book-movie-list>
          </div>
          <div class="col-12 col-md-5 order-1 order-md-2 mt-1 mb-1 mt-md-5 mb-md-5 text-right">
            <app-simple-countdown></app-simple-countdown>
          </div>
        </div>

        <div class="row">
          <div class="col-md-9">
            <h5 class="m-0">{{ 'screen.title' | translate | uppercase }}</h5>

            <div class="row">
              <div class="col-12 col-screen">
                <div style="min-height: 52px;">
                  <app-message [custom]="true"></app-message>
                </div>
                <pinch-zoom #pinch [disablePan]="false" [autoHeight]="false" backgroundColor="ffffff"
                            [autoZoomOut]="false">
                  <div>
                    <div class="screen-header-container mt-0 mt-md-3 mb-3">
                      <div class="screen-header">{{ 'screen.header.hall' | translate }}</div>
                    </div>
                    <app-screen-seats #screenSeats *ngIf="!screen?.generalAdmission" dir="ltr" [screen]="screen"
                                      [limit]="10" [seatIds]="seatIds" [priceSeparatorsEnabled]="priceSeparatorsEnabled"
                                      [rowSeparators]="rowSeparators" (seatsSelectedEvent)="onSeatsSelected($event)"
                                      (seatPopupShowEvent)="onShowedSeatPopup($event)"
                                      [shouldShowPopupOnSelectedSeat]="shouldShowPopupOnSelectedSeat"
                                      (lastSelectedSeatChange)="lastSelectedSeatChange($event)"
                                      [clickedSeatGroupTypes]="screenStateService.state.showSeatGroupPopups">
                    </app-screen-seats>
                  </div>
                </pinch-zoom>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <app-sidebar>
              <div sidebar-title>
                {{ 'screen.seatsSelectedList.label' | translate | uppercase }}
              </div>
              <div sidebar-body>
                <ng-container *ngIf="!screen.generalAdmission">
                  <ng-container *ngIf="seatIds && seatIds.length > 0 else emptySeatSelectedTemplate">
                    <app-screen-seats-selected-list [screen]="screen"
                                                    [seatIds]="seatIds"></app-screen-seats-selected-list>
                  </ng-container>
                </ng-container>
              </div>
            </app-sidebar>
            <br>
            <app-sidebar>
              <div sidebar-title>
                {{ 'screen.legend.label' | translate | uppercase }}
              </div>
              <div class="row" sidebar-body>
                <div class="col-12 screen-seats-container" [class]="'size-'+screen?.pseats[0].length">
                  <app-screen-legend [screen]="screen"></app-screen-legend>
                </div>
              </div>
            </app-sidebar>
          </div>
        </div>

        <div class="row">
          <div class="col-12 mt-4">
            <app-screen-navigation (navigationEvent)="onNavigationClick($event)" [screeningId]="screeningId"
                                   [isLoadingData]="isLoadingData"
                                   [isNextButtonDisabled]="isLoadingData || isNextButtonDisabled()"
                                   [totalPrice]="ticketPrice">
            </app-screen-navigation>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<app-popup [isVisible]="showSelectedSeatModal" (closeButtonClicked)="onClickedCloseModal()">
  <div class="row pb-4">
    <div class="col-12">
      <h4 [innerHTML]="'screen.seats.chosenSeatType' | translate" class="p-0"></h4>
      <div class="question">
        <ng-container *ngIf="lastSelectedSeat && rowSeparators[lastSelectedSeat.rowNumber]">
          <ng-container
                        *ngIf="lastSelectedSeat.transformGroupTypesToString().toLocaleLowerCase().trim() !== 'none' else noneTransformGroupTypesTemplate">
            <span class="popup-ticket-type">
              {{ lastSelectedSeat.transformGroupTypesToString() | titlecase }}
            </span>
          </ng-container>
          <ng-template #noneTransformGroupTypesTemplate>
            <span class="popup-ticket-type">
              {{ lastSelectedSeat.defaultSeatGroupName && lastSelectedSeat.defaultSeatGroupName.length > 0 ? lastSelectedSeat.defaultSeatGroupName : 'Standard' }}
            </span>
          </ng-template>
        </ng-container>
        <ng-container *ngIf="lastSelectedSeat !== null && lastSelectedSeat.groupDescriptionCollection">
          <ng-container *ngIf="lastSelectedSeat.groupDescriptionCollection.length > 0 else noGroupDescriptionTemplate">
            <div *ngFor="let description of lastSelectedSeat.groupDescriptionCollection" [innerHTML]="description">
            </div>
          </ng-container>
          <ng-template #noGroupDescriptionTemplate>
            <div [innerHTML]="lastSelectedSeat.defaultGroupDescription"></div>
          </ng-template>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="row" footer>
    <div class="col-6">
      <button class="btn btn-default" (click)="onClickedCloseModal(false)">{{ 'shared.cancel' | translate }}</button>
    </div>
    <div class="col-6">
      <button class="btn btn-next" (click)="onClickedCloseModal(true)">{{ 'shared.ok' | translate }}</button>
    </div>
  </div>
</app-popup>

<ng-template #emptySeatSelectedTemplate>
  <span>
    {{ 'screen.seatsSelectedList.nothingSelected' | translate }}
  </span>
</ng-template>