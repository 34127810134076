import { loadScript } from '@lib/core';
import { LoginProvider } from '../entities/login-provider';
import { SocialUser } from '../entities/social-user';
import { SocialMediaProviderEnum } from '../social-media-provider.enum';

declare let FB: any;

export class FacebookLoginProvider extends LoginProvider {
  public static readonly PROVIDER_ID: string = SocialMediaProviderEnum.FACEBOOK.toUpperCase();

  private requestOptions = {
    scope: 'email,public_profile',
    fields: 'name,email,picture,first_name,last_name',
    version: 'v10.0',
    locale: '',
  };

  clientId: string;
  supportedLocales: any[];

  constructor(private env: any, private initOptions: any = {}) {
    super();

    this.clientId = env?.externalAuthProviders?.facebook?.clientId;
    console.log('FacebookLoginProvider clientId:', this.clientId);

    this.supportedLocales = env.externalAuthProviders?.facebook?.supportedLocales;

    this.checkSupportedLocales();

    this.requestOptions = {
      ...this.requestOptions,
      ...initOptions,
    };
  }

  checkSupportedLocales() {
    // https://developers.facebook.com/docs/messenger-platform/messenger-profile/supported-locales/
    if (!this.supportedLocales?.some((l) => l === this.initOptions.locale)) {
      this.initOptions.locale = this.supportedLocales?.filter((l) => l.substring(0, 2) === this.initOptions.locale.substring(0, 2))[0] ?? 'en_US';
    }
  }

  private onLoadScript() {
    FB.init({
      appId: this.env.externalAuthProviders.facebook.clientId,
      autoLogAppEvents: true,
      cookie: true,
      xfbml: true,
      version: this.requestOptions.version,
    });
  }

  initialize(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!this.clientId) {
        resolve();
      } else {
        try {
          loadScript(FacebookLoginProvider.PROVIDER_ID, `https://connect.facebook.net/${this.requestOptions.locale}/sdk.js`, () => {
            this.onLoadScript();
            resolve();
          });
        } catch (err) {
          reject(err);
        }
      }
    });
  }

  getLoginStatus(): Promise<SocialUser> {
    return new Promise((resolve, reject) => {
      FB.getLoginStatus((response: any) => {
        if (response.status === 'connected') {
          const authResponse = response.authResponse;
          FB.api(`/me?fields=${this.requestOptions.fields}`, (fbUser: any) => {
            resolve(this.createSocialUser(fbUser, authResponse));
          });
        } else {
          reject(`No user is currently logged in with ${FacebookLoginProvider.PROVIDER_ID}`);
        }
      });
    });
  }

  signIn(signInOptions?: any): Promise<SocialUser> {
    const options = { ...this.requestOptions, ...signInOptions };
    return new Promise((resolve, reject) => {
      FB.login((response: any) => {
        if (response.authResponse) {
          const authResponse = response.authResponse;
          FB.api(`/me?fields=${options.fields}`, (fbUser: any) => {
            resolve(this.createSocialUser(fbUser, authResponse));
          });
        } else {
          reject('User cancelled login or did not fully authorize.');
        }
      }, options);
    });
  }

  signOut(): Promise<void> {
    return new Promise((resolve, reject) => {
      FB.logout((response: any) => {
        resolve();
      });
    });
  }

  private createSocialUser(fbUser: any, authResponse: any) {
    let user: SocialUser = new SocialUser();

    user.id = fbUser.id;
    user.name = fbUser.name;
    user.email = fbUser.email;
    user.photoUrl = 'https://graph.facebook.com/' + fbUser.id + '/picture?type=normal';
    user.firstName = fbUser.first_name;
    user.lastName = fbUser.last_name;
    user.authToken = authResponse.accessToken;

    user.response = fbUser;

    return user;
  }
}
