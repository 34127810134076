import { DateTime } from 'luxon';
import { AbstractViewModel } from '../../abstract.view.model';
import { OrderApiModel } from '../../api-model/order/order.api.model';
import { VoucherViewModel } from '../voucher/voucher.view.model';
import { CardItemViewModel } from './card-item/card-item.view.model';
import { CardTypeItemViewModel } from './card-type-item/card-type-item.view.model';
import { DefaultExtraFeeViewModel } from './default-extra-fee/default-extra-fee.view.model';
import { FbItemViewModel } from './fb-item/fb-item.view.model';
import { OrderMembershipPricesViewModel } from './order-membership-prices.view.model';
import { PaymentMethodViewModel } from './payment-method/payment-method.view.model';
import { ScreeningItemViewModel } from './screening-item/screening-item.view.model';
import { VoucherItemViewModel } from './voucher-item/voucher-item.view.model';
import { isNullOrEmpty } from 'libs/core/src/lib/function/custom-function';
import { IOrder } from '../../../interfaces';

export interface IVoucher {
  name: string;
  number: string;
  count: number;
}

export enum OrderStatus {
  NEW = 0,
  STARTED = 1,
  FINISHED = 4,
  CANCELLED = 5,
  FINISHING = 6,
}

export class OrderViewModel extends AbstractViewModel<OrderApiModel> implements IOrder {
  id: string;
  bookingId: string;
  dateEntry: DateTime;
  dateTimeToLive: DateTime;
  status: number;
  userFirstName: string;
  userLastName: string;
  userPhone: string;
  userEmail: string;
  screeningItems: ScreeningItemViewModel[] = [];
  cardTypeItems: CardTypeItemViewModel[] = [];
  voucherItems: VoucherItemViewModel[] = [];
  fbItems: FbItemViewModel[] = [];
  cardItems: CardItemViewModel[] = [];
  externalUserId: string;
  cardId: string;
  paymentMethods: PaymentMethodViewModel[] = [];
  valueToPay: number;
  totalValue: number;
  totalNetValue: number;
  totalTaxValue: number;
  defaultPriceLevelTotalValue: number;
  taxId: string;
  orderNumber: string;
  totalEarnedPoints: number;
  totalRedemptionPoints: number;
  defaultExtraFees: DefaultExtraFeeViewModel[] = [];
  membershipPrices: OrderMembershipPricesViewModel[] = [];
  documentNumber: string;
  salesDocumentId: string;

  // customFields
  createdFromSalesDocument = false;
  pickupTime: DateTime = null;
  voucher: VoucherViewModel = null;

  constructor(public cinemaId: string, protected apiModel: OrderApiModel = new OrderApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.id = this.apiModel.id;
    this.bookingId = this.apiModel.bookingId;
    this.dateEntry = this.apiModel.dateEntry ? DateTime.fromISO(this.apiModel.dateEntry) : null;
    this.dateTimeToLive = this.apiModel.dateTimeToLive ? DateTime.fromISO(this.apiModel.dateTimeToLive) : null;
    this.status = this.apiModel.status;
    this.userFirstName = this.apiModel.userFirstName;
    this.userLastName = this.apiModel.userLastName;
    this.userPhone = this.apiModel.userPhone;
    this.userEmail = this.apiModel.userEmail;
    this.screeningItems = this.apiModel.screeningItems?.map((screeningItem) => new ScreeningItemViewModel(screeningItem));
    this.cardTypeItems = this.apiModel.cardTypeItems?.map((cardTypeItem) => new CardTypeItemViewModel(cardTypeItem));
    this.voucherItems = this.apiModel.voucherItems?.map((voucherItem) => new VoucherItemViewModel(voucherItem));
    this.fbItems = this.apiModel.fbItems?.map((fbItem) => new FbItemViewModel(fbItem));
    this.cardItems = this.apiModel.cardItems?.map((cardItem) => new CardItemViewModel(cardItem));
    this.externalUserId = this.apiModel.externalUserId;
    this.cardId = this.apiModel.cardId;
    this.paymentMethods = this.apiModel.paymentMethods?.map((paymentMethod) => new PaymentMethodViewModel(paymentMethod));
    this.valueToPay = this.apiModel.valueToPay;
    this.totalValue = this.apiModel.totalValue;
    this.totalNetValue = this.apiModel.totalNetValue;
    this.totalTaxValue = this.apiModel.totalTaxValue;
    this.defaultPriceLevelTotalValue = this.apiModel.defaultPriceLevelTotalValue;
    this.taxId = this.apiModel.taxId;
    this.orderNumber = this.apiModel.orderNumber;
    this.totalEarnedPoints = this.apiModel.totalEarnedPoints;
    this.totalRedemptionPoints = this.apiModel.totalRedemptionPoints;
    this.defaultExtraFees = this.apiModel.defaultExtraFees?.map((defaultExtraFee) => new DefaultExtraFeeViewModel(defaultExtraFee));
    this.membershipPrices = this.apiModel.membershipPrices?.map((membershipPrice) => new OrderMembershipPricesViewModel(membershipPrice));
    this.documentNumber = this.apiModel.documentNumber ?? '';
    this.salesDocumentId = this.apiModel.documentNumber ?? '';
  }

  toApiModel(): OrderApiModel {
    const apiModel = Object.assign(this.apiModel, {
      id: this.id,
      userFirstName: this.userFirstName,
      userLastName: this.userLastName,
      userPhone: this.userPhone,
      userEmail: this.userEmail,
      taxId: this.taxId,
      externalUserId: this.externalUserId,
    });

    console.log('apiModel', apiModel);
    return apiModel;
  }

  getCountOptionalFeeByGroupFee(id: string): number {
    return this.screeningItems.reduce((acc, curr) => {
      if (curr.optionalExtraFees.includes(id)) {
        acc++;
      }

      return acc;
    }, 0);
  }

  public hasVoucher() {
    return this.screeningItems.some((it) => it.hasVoucher()) || this.fbItems.some((it) => it.hasVoucher());
  }

  public itemsWithoutVoucher() {
    return [...this.screeningItems, ...this.fbItems].filter((f) => !f.hasVoucher());
  }

  public hasVoucherNumber(number: string) {
    return this.screeningItems.some((it) => it.hasVoucherNumber(number)) || this.fbItems.some((it) => it.hasVoucherNumber(number));
  }

  public hasCardPayment(cardId: string) {
    return this.paymentMethods.some((pm) => pm.cardId === cardId);
  }

  // TODO: Delete when API returns getBasket in all responses
  getPrice() {
    let sum = 0;
    this.screeningItems.forEach((value, index, array) => {
      sum += value.price;
    });

    this.voucherItems.forEach((value) => {
      sum += value.value * value.quantity;
    });

    this.fbItems.forEach((value) => {
      sum += value.price * value.quantity;

      value.modifierItemList.forEach((modifier) => {
        if (modifier.isSeparate) {
          sum += modifier.price;
        }
      });
    });

    this.defaultExtraFees.forEach((value) => {
      sum += value.defaultExtraFeePrice;
    });

    return sum;
  }

  getVouchers(): IVoucher[] {
    const voucherNumbers: IVoucher[] = [];
    const items = [...this.fbItems, ...this.screeningItems];
    // TODO: Support cardItems, cardTypeItems
    items.forEach((value) => {
      if (value.voucherNumber && !voucherNumbers.some((v) => v.number === value.voucherNumber)) {
        voucherNumbers.push({
          name: value.voucherName,
          number: value.voucherNumber,
          count: items.filter((i) => i.hasVoucher()).length,
        });
      }
    });

    return [...voucherNumbers];
  }

  public getCount(): number {
    return this.screeningItems?.length + this.fbItems?.length + this.voucherItems?.length + this.cardItems?.length + this.cardTypeItems?.length;
  }

  get rewardsItems(): Array<FbItemViewModel | VoucherItemViewModel | CardTypeItemViewModel | ScreeningItemViewModel> {
    return [...this.fbItems, ...this.voucherItems, ...this.cardTypeItems, ...this.screeningItems];
  }

  public get isRewardsTransaction(): boolean {
    return this.totalRedemptionPoints < 0 || this.rewardsItems.some((x) => x.itemRedemptionPoints < 0);
  }

  isOnlyGiftCardOrder() {
    const orderItemsLength = [...this.fbItems, ...this.voucherItems, ...this.cardTypeItems, ...this.screeningItems].length;
    return orderItemsLength <= 0 && this.cardItems.length > 0;
  }

  getRealValueToPay() {
    return this.valueToPay === 0 ? this.paymentMethods?.filter((m) => !m.cardId)?.reduce((sum, current) => sum + current.value, 0) : this.valueToPay;
  }

  getCardPaymentMethods() {
    return this.paymentMethods?.filter((p) => p.cardId);
  }

  isEmailFilled() {
    return !isNullOrEmpty(this.userEmail);
  }

  hasExternalPayments() {
    return this.paymentMethods?.filter((f) => !f.cardId)?.length > 0;
  }

  get transactionNumber() {
    return this.documentNumber;
  }
}
