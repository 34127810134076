import { Component, Input, ElementRef, Renderer2, Output, EventEmitter, HostListener, AfterViewInit } from '@angular/core';
import { getRandomNumber } from '@lib/core';

@Component({
  template: '',
})
export class StickyHeaderComponent implements AfterViewInit {
  @Input() topOffset: number = 0;
  @Output() stickyHeaderChanged = new EventEmitter<IStickyHeaderParams>();

  private _params: IStickyHeaderParams = { componentKey: getRandomNumber() } as IStickyHeaderParams;
  public get params() {
    return this._params;
  }

  public get headerHeight() {
    return `${this._params.headerHeight}px`;
  }

  public get isSticky() {
    return this._params.isSticky;
  }
  public get componentKey() {
    return this._params.componentKey;
  }

  constructor(private el: ElementRef, protected renderer: Renderer2) {}

  ngOnDestroy(): void {
    document.removeEventListener('scroll', this.onScroll.bind(this), true);
  }

  ngAfterViewInit() {
    this.calculateStickyHeader();
    document.addEventListener('scroll', this.onScroll.bind(this), true);
  }

  onScroll(): void {
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

    const nativeElement = this.el?.nativeElement;
    const componentPosition = nativeElement ? nativeElement.offsetTop : 0;
    const distanceFromTop = nativeElement ? nativeElement.getBoundingClientRect().top : 0;

    this._params = Object.assign(this._params, {
      componentPosition: componentPosition,
      scrollPosition: scrollPosition,
      distanceFromTop: distanceFromTop,
      topOffset: this.topOffset,
      isSticky: scrollPosition + this.topOffset >= componentPosition && distanceFromTop <= this.topOffset,
    });

    this.calculateStickyHeader();
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.calculateStickyHeader();
  }

  private calculateStickyHeader() {
    const header = this.el.nativeElement.querySelector(`.header-${this.componentKey}`);
    this.renderer.setStyle(header, 'top', `${this.isSticky ? this.topOffset - this._params.distanceFromTop : 0}px`);
    this._params = Object.assign(this._params, {
      headerHeight: header.offsetHeight,
    });

    this.emitChanges();
  }

  private emitChanges() {
    this.stickyHeaderChanged.emit(this._params);
  }
}

export interface IStickyHeaderParams {
  componentKey: number;
  componentPosition: number;
  scrollPosition: number;
  distanceFromTop: number;
  headerHeight: number;
  isSticky: boolean;
  topOffset: number;
}
