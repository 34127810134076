import { getNumberValueOrZero } from '@lib/core';
import { AbstractViewModel } from '../../abstract.view.model';
import { CardTypeMembershipUpgradeFeeApiModel } from '../../api-model/card/card-type-membership-upgrade-fee.api.model';
import { ExtensionTypeEnum } from '../../enum/extension-type.enum';

export class CardTypeMembershipUpgradeFeeViewModel extends AbstractViewModel<CardTypeMembershipUpgradeFeeApiModel> {
  public feeId: string = null;
  public name: string = null;
  public extensionTime: number = null;
  public extensionType: ExtensionTypeEnum | null = null;
  public feeValue: number = null;
  public upgradeToCardTypeId: string = null;
  public upgradeToCardTypeName: string = null;
  public purchasableDaysBeforeExpirationDate: number = null;

  public membershipTotalValue: number = 0.0;
  public linkToDescription: string = null;

  constructor(protected apiModel: CardTypeMembershipUpgradeFeeApiModel = new CardTypeMembershipUpgradeFeeApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.feeId = this.apiModel.feeId;
    this.name = this.apiModel.name;
    this.extensionTime = this.apiModel.extensionTime;
    this.extensionType = this.apiModel.extensionType === 0 ? ExtensionTypeEnum.ADD_TIME : ExtensionTypeEnum.OVERLAP_TIME;
    this.feeValue = this.apiModel.feeValue;
    this.upgradeToCardTypeId = this.apiModel.upgradeToCardTypeId;
    this.upgradeToCardTypeName = this.apiModel.upgradeToCardTypeName;
    this.purchasableDaysBeforeExpirationDate = getNumberValueOrZero(this.apiModel.purchasableDaysBeforeExpirationDate);
  }

  toApiModel(): CardTypeMembershipUpgradeFeeApiModel {
    return undefined;
  }
}
