export const cardTokenizationIframeOptions = {
  lang: 'pl',
  size: {
    width: '100%',
    height: '381px',
  },
  labels: {
    cardholder: 'Imię i nazwisko posiadacza karty',
    card: 'Numer karty',
    cvv: 'Numer CVV/CVC karty',
    exp: 'Data ważności karty',
  },
  agreement: {
    contentEnabled: {
      enabled: 'true',
      checkboxEnabled: 'true',
    },
    //TOSLanguage: 'PL',
  },
  placeholders: {
    card: 'Podaj numer swojej karty',
    cvv: 'Podaj numer CVV swojej karty',
    exp: 'Podaj datę ważności swojej karty',
    cardholder: 'Podaj imię i nazwisko posiadacza karty',
  },
  styles: {
    font: {
      basic: {
        size: '0.8rem',
        family: '"DM Sans",sans-serif',
        weight: '200',
        // color: '#000',
      },
      error: {
        color: 'red',
        weight: '500',
      },
      // agreement: {
      //   family: 'Impact,Charcoal,sans-serif',
      //   color: '#000',
      //   size: '24px',
      //   weight: '500',
      //   link: '#000',
      //   visited: '#000',
      //   active: '#000',
      // },
      // focus: {
      //   color: '#000',
      //   weight: '500',
      // },
      // placeholders: {
      //   color: '#000',
      //   weight: '500',
      // },
    },
    border: {
      card: {
        // type: 'solid',
        // color: '#000',
        // width: '15px',
        radius: '3px',
      },
      cvv: {
        // type: 'solid',
        // color: '#000',
        // width: '15px',
        radius: '3px',
      },
      exp: {
        // type: 'solid',
        // color: '#000',
        // width: '15px',
        radius: '3px',
      },
      form: {
        type: 'none',
      },
      cardholder: {
        type: 'solid',
        color: '#000',
        width: '15px',
        radius: '3px',
      },
    },
    // agreement_checkbox: {
    //   color: '#000',
    //   colorInside: '#000',
    //   colorSelected: '#000',
    //   width: '15px',
    // },
    // background: {
    //   card: {
    //     color: '#000',
    //   },
    //   cvv: {
    //     color: '#000',
    //   },
    //   exp: {
    //     color: '#000',
    //   },
    //   form: {
    //     color: '#000',
    //   },
    //   cardholder: {
    //     color: '#000',
    //   },
    // },
  },
  // buttons: {
  //   tokenize: {
  //     label: 'Zapisz kartę i zapłać',
  //     backgroundColor: '#000',
  //     border: {
  //       type: 'solid',
  //       color: '#000',
  //       width: '15px',
  //       radius: '15px',
  //     },
  //   },
  //   tokenize_temporary: {
  //     label: 'Zapisz kartę i zapłać',
  //     backgroundColor: '#000',
  //     border: {
  //       type: 'solid',
  //       color: '#000',
  //       width: '15px',
  //       radius: '15px',
  //     },
  //   },
  // },
};
