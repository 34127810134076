import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { DateTime } from 'luxon';
import { SupportedDateTimeLocale } from '../locale-data/supported-datetime-locale.enum';
import { BG_BG_DatePartOrder, BG_BG_LocaleData } from '../locale-data/bg-bg.locale-data';
import { EN_US_DatePartOrder, EN_US_LocaleData } from '../locale-data/en-us.locale-data';
import { SupportedDateTimeFormat } from '../locale-data/supported-datetime-format.enum';
import { WeekdayService } from './weekday.service';
import { EN_GB_DatePartOrder, EN_GB_LocaleData } from '../locale-data/en-gb.locale-data';
import { PL_PL_DatePartOrder, PL_PL_LocaleData } from '../locale-data/pl-pl.locale-data';
import { DE_CH_DatePartOrder, DE_CH_LocaleData } from '../locale-data/de-ch.locale-data';
import { FR_CH_DatePartOrder, FR_CH_LocaleData } from '../locale-data/fr-ch.locale-data';
import { HR_HR_DatePartOrder, HR_HR_LocaleData } from '../locale-data/hr-hr.locale-data';
import { SR_Latn_DatePartOrder, SR_Latn_LocaleData } from '../locale-data/sr-latn.locale-data';
import { SQ_XK_DatePartOrder, SQ_XK_LocaleData } from '../locale-data/sq-xk.locale-data';
import { EN_SG_LocaleData } from '../locale-data/en-sg.locale-data';
import { SL_SI_DatePartOrder, SL_SI_LocaleData } from '../locale-data/sl-si.locale-data';

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat
// https://moment.github.io/luxon/api-docs/index.html#datetimetolocalestring

@Injectable({
  providedIn: 'root',
})
export class DateTimeLocaleDataService {
  constructor(@Inject(LOCALE_ID) protected localeId: string, protected weekdayService: WeekdayService) {}

  transform(value: DateTime, format: SupportedDateTimeFormat): string {
    value.setLocale(this.localeId);

    switch (this.localeId) {
      case SupportedDateTimeLocale.en_US:
        return new EN_US_LocaleData(this.weekdayService).format(value, format);
      case SupportedDateTimeLocale.en_GB:
        return new EN_GB_LocaleData(this.weekdayService).format(value, format);
      case SupportedDateTimeLocale.en_SG:
        return new EN_SG_LocaleData(this.weekdayService).format(value, format);
      case SupportedDateTimeLocale.de_CH:
        return new DE_CH_LocaleData(this.weekdayService).format(value, format);
      case SupportedDateTimeLocale.fr_CH:
        return new FR_CH_LocaleData(this.weekdayService).format(value, format);
      case SupportedDateTimeLocale.pl_PL:
        return new PL_PL_LocaleData(this.weekdayService).format(value, format);
      case SupportedDateTimeLocale.hr_HR:
        return new HR_HR_LocaleData(this.weekdayService).format(value, format);
      case SupportedDateTimeLocale.sq_XK:
        return new SQ_XK_LocaleData(this.weekdayService).format(value, format);
      case SupportedDateTimeLocale.sr_Latn:
        return new SR_Latn_LocaleData(this.weekdayService).format(value, format);
      case SupportedDateTimeLocale.bg_BG:
        return new BG_BG_LocaleData(this.weekdayService).format(value, format);
      case SupportedDateTimeLocale.sl_SI:
        return new SL_SI_LocaleData(this.weekdayService).format(value, format);
      default:
        return value.toLocaleString();
    }
  }

  datePartOrder() {
    switch (this.localeId) {
      case SupportedDateTimeLocale.en_US:
        return EN_US_DatePartOrder;
      case SupportedDateTimeLocale.en_GB:
        return EN_GB_DatePartOrder;
      case SupportedDateTimeLocale.de_CH:
        return DE_CH_DatePartOrder;
      case SupportedDateTimeLocale.fr_CH:
        return FR_CH_DatePartOrder;
      case SupportedDateTimeLocale.pl_PL:
        return PL_PL_DatePartOrder;
      case SupportedDateTimeLocale.hr_HR:
        return HR_HR_DatePartOrder;
      case SupportedDateTimeLocale.sq_XK:
        return SQ_XK_DatePartOrder;
      case SupportedDateTimeLocale.sr_Latn:
        return SR_Latn_DatePartOrder;
      case SupportedDateTimeLocale.bg_BG:
        return BG_BG_DatePartOrder;
      case SupportedDateTimeLocale.sl_SI:
        return SL_SI_DatePartOrder;
      default:
        return 'dmy';
    }
  }
}
