export class PaymentDataModel {
  public apiVersion: number;

  public apiVersionMinor: number;

  public allowedPaymentMethods: Array<PaymentMethod> = new Array<PaymentMethod>();

  public transactionInfo: PaymentTransactionInfoModel;

  public merchantInfo: PaymentMerchantInfoModel;
}

export class PaymentTransactionInfoModel {
  public totalPriceStatus: PaymentTotalPriceStatus;

  public totalPrice: string | undefined = undefined;

  public currencyCode: string;

  public countryCode: string | undefined = undefined;

  public transactionId: string | undefined = undefined;

  public displayItems: Array<PaymentMethod> = undefined;

  public totalPriceLabel: string | undefined = undefined;
}

export class PaymentMerchantInfoModel {
  /**
   * A Google merchant identifier issued after your website is approved by Google.
   * Required when PaymentsClient is initialized with an environment property of PRODUCTION.
   * See the Integration checklist for more information about the approval process
   * and how to obtain a Google merchant identifier.
   */
  public merchantId: string;

  /**
   * Merchant name encoded as UTF-8. Merchant name is rendered in the payment sheet.
   * In TEST environment, or if a merchant isn't recognized, a “Pay Unverified Merchant” message is
   * displayed in the payment sheet.
   */
  public merchantName: string;
}

export class PaymentMethod {
  /**
   * A short identifier for the supported payment method. Only CARD and PAYPAL currently are supported entries.
   */
  public type: PaymentMethodType;

  public parameters: PaymentMethodOptions;

  public tokenizationSpecification: TokenizationSpecification | undefined = undefined;
}

export class TokenizationSpecification {
  public type: TokenizationSpecificationType;

  //public tokenizationType: TokenizationSpecificationType;

  public parameters: TokenizationSpecificationOptions;
}

export interface PaymentMethodOptions {}

export interface TokenizationSpecificationOptions {}

export class PaymentCardOptions implements PaymentMethodOptions {
  /**
   * Fields supported to authenticate a card transaction.

   PAN_ONLY: This authentication method is associated with payment cards stored on file with the user's Google Account.
   Returned payment data includes personal account number (PAN) with the expiration month and the expiration year.

   CRYPTOGRAM_3DS: This authentication method is associated with cards stored as Android device tokens.
   Returned payment data includes a 3-D Secure (3DS) cryptogram generated on the device.

   */
  public allowedAuthMethods: Array<PaymentAuthMethod> = new Array<PaymentAuthMethod>();

  /**
   * One or more card networks that you support, also supported by the Google Pay API
   */
  public allowedCardNetworks: Array<PaymentCardNetwork> = new Array<PaymentCardNetwork>();

  public allowPrepaidCards: boolean | undefined = undefined;

  public billingAddressRequired: boolean | undefined = undefined;

  public billingAddressParameters: undefined = undefined;
}

export class TokenizationSpecificationPaymentGatewayOptions {
  public gateway: string;

  public gatewayMerchantId: string;
}

export class PaymentDisplayItem {
  /**
   * The label to be displayed for the given option.
   */
  public label: string;

  /**
   * Type of displayed line item
   */
  public type: DisplayItemType;

  /**
   * The monetary value of the cart item with an optional decimal precision of two decimal places.
   * Negative values are allowed.
   */
  public price: string;

  /**
   * The following variables define price variance
   */
  public status: DisplayItemStatus;
}

export enum PaymentTotalPriceStatus {
  /**
   * Used for a capability check. Do not use this property if the transaction is processed in an EEA country
   */
  NOT_CURRENTLY_KNOWN = 'NOT_CURRENTLY_KNOWN',

  /**
   * Total price may adjust based on the details of the response, such as sales tax collected based on a billing address.
   */
  ESTIMATED = 'ESTIMATED',

  /**
   * Total price doesn't change from the amount presented to the shopper.
   */
  FINAL = 'FINAL',
}

export enum DisplayItemType {
  LINE_ITEM = 'LINE_ITEM',

  SUBTOTAL = 'SUBTOTAL',
}

export enum DisplayItemStatus {
  FINAL = 'FINAL',

  PENDING = 'PENDING',
}

export enum PaymentMethodType {
  CARD = 'CARD',

  PAYPAL = 'PAYPAL',
}

export enum PaymentAuthMethod {
  /**
   * This authentication method is associated with payment cards stored on file with the user's Google Account.
   * Returned payment data includes personal account number (PAN) with the expiration month and the expiration year.
   */
  PAN_ONLY = 'PAN_ONLY',

  /**
   * This authentication method is associated with cards stored as Android device tokens.
   * Returned payment data includes a 3-D Secure (3DS) cryptogram generated on the device.
   */
  CRYPTOGRAM_3DS = 'CRYPTOGRAM_3DS',
}

export enum PaymentCardNetwork {
  AMEX = 'AMEX',

  DISCOVER = 'DISCOVER',

  INTERAC = 'INTERAC',

  JCB = 'JCB',

  MASTERCARD = 'MASTERCARD',

  VISA = 'VISA',

  ELECTRON = 'ELECTRON',

  MAESTRO = 'MAESTRO',
}

export enum TokenizationSpecificationType {
  PAYMENT_GATEWAY = 'PAYMENT_GATEWAY',

  DIRECT = 'DIRECT',
}
