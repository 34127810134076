import { AbstractViewModel } from '../../abstract.view.model';
import { CardActiveDiscountApiModel } from '../../api-model/card/card-active-discount.api.model';

export class CardActiveDiscountViewModel extends AbstractViewModel<CardActiveDiscountApiModel> {
  public name: string = null;
  public active: boolean = null;
  public registrationRequired: boolean = null;

  constructor(protected apiModel: CardActiveDiscountApiModel = new CardActiveDiscountApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.name = this.apiModel.name;
    this.active = this.apiModel.active;
    this.registrationRequired = this.apiModel.registrationRequired;
  }

  toApiModel(): CardActiveDiscountApiModel {
    return undefined;
  }
}
