import { AbstractViewModel } from '../../abstract.view.model';
import { CardFieldTemplateApiModel } from '../../api-model/card/card-field-template.api.model';

export class CardFieldTemplateViewModel extends AbstractViewModel<CardFieldTemplateApiModel> {
  public symbolId: number = null;
  public field: string = null;
  public requiredPosition: number = null;

  constructor(protected apiModel: CardFieldTemplateApiModel = new CardFieldTemplateApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.symbolId = this.apiModel.symbolId;
    this.field = this.apiModel.field;
    this.requiredPosition = this.apiModel.requiredPosition;
  }

  toApiModel(): CardFieldTemplateApiModel {
    return undefined;
  }
}
