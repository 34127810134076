import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { concatMap, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import cloneDeep from 'lodash-es/cloneDeep';
import { OrderDataProvider } from '../data-provider/order.data-provider';
import { VoucherDataProvider } from '../data-provider/voucher.data-provider';
import { OrderViewModel } from '../model/view-model/order/order.view.model';
import { ScreeningItemViewModel } from '../model/view-model/order/screening-item/screening-item.view.model';
import { VoucherItemViewModel } from '../model/view-model/order/voucher-item/voucher-item.view.model';
import { VoucherTypeModel } from '../model/voucher-type.model';
import { OrderStateService } from '../state/order.state.service';
import { LoadingStatus } from '../model/loading/loading-status.enum';
import { VoucherViewModel } from '../model/view-model/voucher/voucher.view.model';
import { VoucherPurchaseViewModel } from '../model/view-model/voucher/voucher-purchase.view.model';
import { TranslateService } from '@ngx-translate/core';
import { ErrorHandlerService } from './error-handler/error-handler.service';
import { NotificationModel, NotificationService, NotificationType } from '@lib/core';
import { BsModalService } from 'ngx-bootstrap/modal';

export interface PatchVoucherItemOptions {
  createNewOrder: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class VoucherService {
  private _loadingStatus = new BehaviorSubject<LoadingStatus>(null);
  public loadingStatus$ = this._loadingStatus.asObservable();

  constructor(
    private orderDataProvider: OrderDataProvider,
    private voucherDataProvider: VoucherDataProvider,
    private orderStateService: OrderStateService,
    protected notificationService: NotificationService,
    protected translateService: TranslateService,
    protected errorHandlerService: ErrorHandlerService,
    protected modalService: BsModalService
  ) {}

  set loadingStatus(value: LoadingStatus) {
    this._loadingStatus.next(value);
  }

  /**
   * Gets information about Voucher
   * @param cinemaId string
   * @param voucherNumber string
   */
  public getVoucherInformation(cinemaId: string, voucherNumber: string): Observable<VoucherViewModel> {
    return this.voucherDataProvider.getVoucher(cinemaId, voucherNumber);
  }

  public getVoucherInformationWithLimitUsageModal(
    order: OrderViewModel,
    voucherNumber: string,
    modalComponent: any
  ): Observable<{ limitUsage: number; voucherInfo: VoucherViewModel }> {
    return this.voucherDataProvider.getVoucher(order?.cinemaId, voucherNumber).pipe(
      switchMap((voucherInfo) => {
        if (!voucherInfo.flgMultiuse) {
          return forkJoin({
            limitUsage: of(null),
            voucherInfo: of(voucherInfo),
          });
        }

        const initialState = {
          voucher: voucherInfo,
          order: order,
        };

        const modal = this.modalService.show(modalComponent, {
          initialState,
          ignoreBackdropClick: true,
        });

        return forkJoin({
          limitUsage: modal.onHide.pipe(
            take(1),
            map((r: number | undefined) => r)
          ),
          voucherInfo: of(voucherInfo),
        });
      })
    );
  }

  public assignVoucherToOrder(cinemaId: string, orderId: string, voucherNumber: string, limitUsage?: number): Observable<OrderViewModel> {
    return this.putVoucherToOrder(cinemaId, orderId, voucherNumber, limitUsage).pipe(
      concatMap(() => {
        return this.orderDataProvider.getOrder(cinemaId, orderId).pipe(
          map((order: OrderViewModel) => {
            console.log('putVoucherToOrder findById order:', order, voucherNumber);
            this.orderStateService.setOrder(order);
            this.orderStateService.setVoucher(voucherNumber);
            return order;
          })
        );
      })
    );
  }

  public putVoucherToOrder(cinemaId: string, orderId: string, voucherNumber: string, limitUsage?: number) {
    return this.voucherDataProvider.putVoucherToOrder(cinemaId, orderId, voucherNumber, limitUsage).pipe();
  }

  /**
   * Patches vouchers into order at current state
   */
  public patch(selectedVoucherCollection: Array<VoucherTypeModel>, options: PatchVoucherItemOptions): Observable<OrderViewModel> {
    const order: OrderViewModel = this.orderStateService.getOrder();
    const items: Array<VoucherItemViewModel> = this.buildOrderItemCollection(selectedVoucherCollection);

    return this.orderDataProvider.patchVoucherItems(order.cinemaId, items, options && options.createNewOrder ? null : order).pipe(
      map((resOrder: OrderViewModel) => {
        this.orderStateService.setOrder(resOrder);

        return resOrder;
      })
    );
  }

  public getSelectedVoucherCollectionFromOrder(order: OrderViewModel, voucherTypeCollection: Array<VoucherTypeModel>): Array<VoucherTypeModel> {
    const selectedVoucherCollection: Array<VoucherTypeModel> = new Array<VoucherTypeModel>();
    order.voucherItems.forEach((orderVoucherItem: VoucherItemViewModel) => {
      for (let i = 0; i < orderVoucherItem.quantity; i++) {
        const matchedVoucher: VoucherTypeModel | undefined = voucherTypeCollection.find((x) => x.itemId === orderVoucherItem.itemId);

        if (matchedVoucher) {
          selectedVoucherCollection.push(cloneDeep<VoucherTypeModel>(matchedVoucher));
        }
      }
    });

    return selectedVoucherCollection;
  }

  public createVoucherCollectionFromOrder(order: OrderViewModel): Array<VoucherTypeModel> {
    const voucherCollection: Array<VoucherTypeModel> = new Array<VoucherTypeModel>();
    let voucherItemFee: VoucherTypeModel | null = null;
    let sumVoucherItem = 0;

    order.voucherItems?.forEach((orderVoucherItem) => {
      for (let i = 0; i < orderVoucherItem.quantity; i++) {
        const voucher: VoucherTypeModel = new VoucherTypeModel();
        voucher.itemId = orderVoucherItem.itemId; // or id?
        voucher.price = orderVoucherItem.value;
        voucher.taxRate = orderVoucherItem.itemTaxRate;
        voucher.name = orderVoucherItem.itemName;
        voucher.id = orderVoucherItem.id; // or itemId?

        if (voucher.name && voucher.name.toLocaleLowerCase().includes('fee')) {
          voucherItemFee = voucher;
        } else {
          sumVoucherItem++;
          voucherCollection.push(voucher);
        }
      }
    });

    if (voucherItemFee) {
      for (let i = 0; i < sumVoucherItem; i++) {
        voucherCollection.push(voucherItemFee);
      }
    }

    return voucherCollection;
  }

  public getOrderVouchers(order: OrderViewModel): string[] {
    if (!order && !order.screeningItems) {
      return [];
    }

    return order.screeningItems
      .filter((item) => item.voucherName && item.voucherName.length > 0)
      .map((item) => {
        return item.voucherNumber;
      });
  }

  /**
   * Builds an list of selected vouchers into OrderVoucherItem collection
   */
  private buildOrderItemCollection(selectedVoucherList: Array<VoucherTypeModel>): Array<VoucherItemViewModel> {
    return selectedVoucherList.map((selectedVoucher) => {
      const orderItem: VoucherItemViewModel = new VoucherItemViewModel();
      orderItem.itemId = selectedVoucher.itemId;
      orderItem.quantity = 1;

      return orderItem;
    });
  }

  removeVoucherFromOrder(cinemaId: string, orderId: string, voucherNumber: string) {
    return this.voucherDataProvider.removeVoucherFromOrder(cinemaId, orderId, voucherNumber).pipe(
      concatMap(() => {
        return this.orderDataProvider.getOrder(cinemaId, orderId).pipe(
          map((order: OrderViewModel) => {
            this.orderStateService.setOrder(order);
            this.orderStateService.removeVoucher(voucherNumber);
            return order.screeningItems.find((it) => it.hasVoucher());
          })
        );
      })
    );
  }

  removeVoucherFromOrderItem(cinemaId, orderId, itemId, voucherNumber): Observable<ScreeningItemViewModel> {
    return this.voucherDataProvider.removeVoucherFromOrderItem(cinemaId, orderId, itemId, voucherNumber).pipe(
      concatMap((res) =>
        this.orderDataProvider.getOrder(cinemaId, orderId).pipe(
          map((order) => {
            this.orderStateService.setOrder(order);
            this.orderStateService.removeVoucher(voucherNumber);
            return order.screeningItems.find((it) => it.hasVoucher());
          })
        )
      )
    );
  }

  assignVoucherToOrderItem(cinemaId, orderId, itemId, voucherNumber, voucherName) {
    return this.voucherDataProvider.assignVoucherToOrderItem(cinemaId, orderId, itemId, voucherNumber).pipe(
      concatMap((voucher) =>
        this.orderDataProvider.getOrder(cinemaId, orderId).pipe(
          map((order) => {
            this.orderStateService.setOrder(order);
            this.orderStateService.setVoucher(voucherNumber, voucherName);
            return order.screeningItems.find((it) => it.hasVoucher());
          })
        )
      )
    );
  }

  assignVoucherToOrderViaApiModel(cinemaId: string, orderId: string, voucherNumber: string): Observable<OrderViewModel> {
    return this.putVoucherToOrder(cinemaId, orderId, voucherNumber).pipe(mergeMap(() => this.orderDataProvider.getOrder(cinemaId, orderId)));
  }

  assignVoucherToOrderAndSetOrder(cinemaId: string, orderId: string, voucherNumber: string, limitUsage?: number): Observable<OrderViewModel> {
    return this.putVoucherToOrder(cinemaId, orderId, voucherNumber, limitUsage).pipe(
      switchMap((d) => {
        return this.orderDataProvider.getOrder(cinemaId, orderId);
      }),
      tap((o) => {
        this.orderStateService.setOrder(o);
      })
    );
  }

  getVouchersToPurchase(cinemaId: string): Observable<VoucherPurchaseViewModel[]> {
    return this.voucherDataProvider.getVouchersToPurchase(cinemaId);
  }

  addVoucherToOrder(cinemaId: string, orderId: string, itemId: string, quantity: number) {
    return this.orderDataProvider.addVoucherToOrder(cinemaId, orderId, itemId, quantity).pipe(
      tap((order) => {
        this.orderStateService.setOrder(order);
      })
    );
  }

  public handleVoucherError(e?: any) {
    const error = this.errorHandlerService.getError(e);
    let notification: string;

    switch (error?.code) {
      default:
        notification = error.message;
        break;
    }

    this.notificationService.addNotification(new NotificationModel(notification, NotificationType.ALERT));
  }
}
