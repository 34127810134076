export enum WpOptionEnum {
  ENVELOPE_ITEM_PRICE = 'EnvelopeItemPrice',
  ENVELOPE_ITEM_ID = 'EnvelopeItemId',
  AGREEMENT_GROUP_ID_AGREEMENTS = 'AgreementGroupId-Agreements',
  VOUCHER_GROUP_ID_PASSES = 'VoucherGroupId-Passes',
  VOUCHER_GROUP_ID_FLAT_RATES = 'VoucherGroupId-FlatRates',
  VOUCHER_GROUP_ID_MEMBERSHIP = 'VoucherGroupId-Membership',
  DEFAULT_TICKET_ID = 'DefaultTicketID',
  INSURANCE_ID = 'InsuranceID',
  BRIGHTCOVE_ACCOUNT_ID = 'BrightcoveAccountID',
  BRIGHTCOVE_PLAYER_ID = 'BrightcovePlayerID',
  ORDER_AGREEMENT = 'OrderAgreement',
  CINEMA_DAY_OFFSET = 'CinemaDayOffset',
  BEST_SEATS_QUANTITY = 'BestSeatsQuantity',
  INTERNAL_PAYMENT_TYPE_ID_FOR_GIFTCARD = 'InternalPaymentTypeIdForGiftCard',
  INTERNAL_PAYMENT_TYPE_ID_FOR_PREPAID = 'InternalPaymentTypeIdForPerPaid',
  DISPLAY_MANDATORY_EXTRA_FEES = 'DisplayMandatoryExtraFees',
  RESERVATION_ALWAYS_VISIBLE = 'ReservationAlwaysVisible',
  DEFAULT_PREROLL_VIDEO = 'DefaultPrerollVideo',
  DEFAULT_PREROLL_SKIP_TIME = 'DefaultPrerollSkipTime',
  LOCATION_SWITCHER_MODE = 'LocationSwitcherMode',
  LOCATION_AUTOSELECTION = 'LocationAutoselection',
  HIDE_INACTIVE_SCREENINGS = 'HideInactiveScreenings',
  NO_SHOWS_ADDITIONAL_TEXT_MESSAGE = 'NoShowsAdditionalTextMessage',
  TICKETS_REFUND = 'TicketsRefund',
  TERMS_OF_USE = 'TermsOfUse',
  PRIVACY_POLICY = 'PrivacyPolicy',
  MYACCOUNT_SHOW_VOUCHER_REDEEM_BUTTON = 'MyAccountShowVoucherRedeemButton',
  MOVIE_DESCRIPTION_ALLOW_NEW_LINE = 'MovieDescriptionAllowNewLine',
  POSTER_DISPLAY_TRAILER_AVAILABLE_ICON = 'PosterDisplayTrailerAvailableIcon',
  LEGEND_BASED_ON_DISPLAYMODE = 'LegendBasedOnDisplayMode',
  GTM_EVENT_LIST = 'GoogleTagManagerEventList',
  ADDITIONAL_GENDER = 'AdditionalGender',
  GIFTCARDPURCHASE_ONLINECARDDESCRIPTION = 'GiftCardPurchaseOnlineCardDescription',
  GIFTCARDPURCHASE_PHYSICALCARDDESCRIPTION = 'GiftCardPurchasePhysicalCardDescription',
  USE_MULTIUSEVOUCHER_ON_SELECTED_AMOUNT_OF_TICKETS = 'UseMultiuseVoucherOnSelectedAmountOfTickets',
  RECAPTCHA_SITE_KEY = 'GoogleReCaptchaSiteKey',
  BASIC_MEMBERSHIP_CARD_TYPE_ID = 'BasicMembershipID',
  MEMBERSHIP_LINK_TO_DESCRIPTION = 'MembershipLinkToDescription',
  TICKET_RESERVATION_INFORMATION = 'ReserveTicketsText',
}
