import { Inject, Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { ENVIRONMENT_TOKEN } from '../injection.tokens';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  public param$: Observable<string>;
  public param = new ReplaySubject<string>(1);

  private env: any = null;
  private isPaymentSandboxEnabled = false;

  public constructor(@Inject(ENVIRONMENT_TOKEN) environment: any) {
    this.param$ = this.param.asObservable();
    this.env = environment;
  }

  get projectName(): string {
    const result: string = String(this.env.projectName);
    if (result === '') {
      throw new Error('Project name is not specified');
    }

    return result;
  }

  get projectTitle(): string {
    const result: string = String(this.env.metadata?.title);
    if (result === '') {
      throw new Error('Project title is not specified');
    }

    return result;
  }

  get paymentSandboxEnabled(): boolean {
    return this.isPaymentSandboxEnabled;
  }

  public getEnvironmentVariable(variableName: string): any {
    return this.env[variableName];
  }

  public enablePaymentSandbox(): void {
    this.isPaymentSandboxEnabled = true;
  }

  public isProject(...projectName: string[]): boolean {
    return projectName.indexOf(this.projectName) >= 0;
  }

  public getLanguage() {
    return this.env.constants.language.default;
  }

  public showMobileCountdown(): boolean {
    return window.innerWidth < 576;
  }
}
