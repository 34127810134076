import { Expose } from 'class-transformer';
import { CardTypeMembershipFeeApiModel } from './card-type-membership.api.model';

export class CardTypeMembershipDowngradeFeeApiModel extends CardTypeMembershipFeeApiModel {
  @Expose()
  downgradeToCardTypeId: string;

  @Expose()
  downgradeToCardTypeName: string;
}
