import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { cookieKey, storageDeprecatedKey, storageKey } from '../../app.const';
import { OrderHttpService } from '../http/order.http.service';
import { TokenStorageService } from 'libs/core/src/lib/service/token-storage.service';
import { CookieService } from 'ngx-cookie-service';
import { getRandomHash, getTopLevelDomain } from '../function/custom-function';

@Injectable({
  providedIn: 'root',
})
export class StateService {
  hashID = sessionStorage.hashID ? sessionStorage.hashID : (sessionStorage.hashID = getRandomHash());

  constructor(protected tokenStorageService: TokenStorageService, protected cookieService: CookieService) {}

  removeUserSessionStorage(): void {
    sessionStorage.removeItem(storageKey.lastScreeningId);
    this.removeReservation();
    sessionStorage.removeItem(storageKey.isEvent);
    sessionStorage.removeItem(storageKey.lastEventId);
  }

  clearAuthStorageAndCookies() {
    localStorage.removeItem(storageKey.token);
    this.cookieService.delete(cookieKey.external_user_token, '/', getTopLevelDomain(window.location.href));
    this.cookieService.delete(cookieKey.external_user_r_token, '/', getTopLevelDomain(window.location.href));
  }

  removeFromSessionStorage(): void {
    OrderHttpService.cacheBuster$.next();
    this.removeItem(storageKey.order);
    sessionStorage.removeItem(storageKey.personalEmail);
    sessionStorage.removeItem(storageKey.personalAgreements);
    sessionStorage.removeItem(storageKey.isExtraFeesSelected);

    this.removePaymentMethod();
    this.removeVouchers();

    this.removeDepricatedKeys();
  }

  removePaymentMethod() {
    sessionStorage.removeItem(storageKey.selectedPaymentMethod);
  }

  removeVouchers() {
    sessionStorage.removeItem(storageKey.vouchers);
  }

  removePersonalTaxId() {
    sessionStorage.removeItem(storageKey.personalTaxId);
  }

  removeMemberGetMemberPromotionId() {
    sessionStorage.removeItem(storageKey.mgmPromotionId);
  }

  removeEmbededPaymentUrl() {
    sessionStorage.removeItem(storageKey.embededPaymentUrl);
  }

  removeReservation() {
    this.removeItem(storageKey.reservationId);
    this.removeItem(storageKey.reservationCinemaId);
    this.removeItem(storageKey.reservationScreeningId);
    this.removeItem(storageKey.reservationScreeningTimeTo);
  }

  setReservation(reservationId, cinemaId, screeningId, timeTo: DateTime) {
    if (reservationId) {
      this.setItem(storageKey.reservationId, reservationId);
    }
    if (cinemaId) {
      this.setItem(storageKey.reservationCinemaId, cinemaId);
    }
    if (screeningId) {
      this.setItem(storageKey.reservationScreeningId, screeningId);
    }
    if (timeTo) {
      this.setItem(storageKey.reservationScreeningTimeTo, timeTo.toString());
    }
  }

  setItem(key: string, value: string) {
    sessionStorage.setItem(this.hashifyKey(key), value);
  }

  getItem(key: string) {
    return sessionStorage.getItem(this.hashifyKey(key));
  }

  getItemThenRemove(key: string) {
    const value = sessionStorage.getItem(this.hashifyKey(key));
    if (value) {
      this.removeItem(key);
    }

    return value;
  }

  removeItem(key: string | string[]) {
    if (typeof key === 'string') {
      sessionStorage.removeItem(this.hashifyKey(key));
    } else {
      key.forEach((k) => sessionStorage.removeItem(this.hashifyKey(k)));
    }
  }

  removeDepricatedKeys() {
    this.removeItem([storageDeprecatedKey.orderId]);
  }

  private hashifyKey(key: string) {
    return [
      storageKey.timeStampName,
      storageKey.lastEpochName,
      storageKey.isEvent,
      storageKey.lastEventId,

      storageKey.chosenLocation,
      storageKey.order,
      storageKey.lastScreeningId,
      storageKey.reservationId,
      storageKey.reservationCinemaId,
      storageKey.reservationScreeningId,

      storageDeprecatedKey.orderId,
      storageDeprecatedKey.chosenCinema,
      storageDeprecatedKey.chosenRegion,
      storageKey.flowType,
      storageKey.flowData,
    ].includes(key)
      ? `${key}_${this.hashID}`
      : key;
  }
}
