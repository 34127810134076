import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { appProjectName } from 'libs/core/src/app.const';
import { BasketDataProvider } from 'libs/core/src/lib/data-provider/basket.data-provider';
import { OrderDataProvider } from 'libs/core/src/lib/data-provider/order.data-provider';
import { UserDataProvider } from 'libs/core/src/lib/data-provider/user.data-provider';
import { LoaderEnum } from 'libs/core/src/lib/enum/loader.enum';
import { ProviderEnum } from 'libs/core/src/lib/enum/provider.enum';
import { OrderHttpService } from 'libs/core/src/lib/http/order.http.service';
import { OrderApiModel } from 'libs/core/src/lib/model/api-model/order/order.api.model';
import { LoadingStatus } from 'libs/core/src/lib/model/loading/loading-status.enum';
import { MessageModel, MessageType } from 'libs/core/src/lib/model/message.model';
import { BasketPageModel } from 'libs/core/src/lib/model/page/basket/basket.page.model';
import { CateringAggregationArticleViewModel } from 'libs/core/src/lib/model/catering/catering-aggregation.view.model';
import { PersonalModel } from 'libs/core/src/lib/model/personal.model';
import { OrderPaymentRequestModel } from 'libs/core/src/lib/model/request/order-payment.request.model';
import { PaymentProviderPayMethodRequestModel } from 'libs/core/src/lib/model/request/payment-provider-pay-method.request.model';
import { OrderViewModel } from 'libs/core/src/lib/model/view-model/order/order.view.model';
import { PaymentMethodViewModel } from 'libs/core/src/lib/model/view-model/order/payment-method/payment-method.view.model';
import { PaymentViewModel } from 'libs/core/src/lib/model/view-model/payment.view.model';
import { VoucherTypeModel } from 'libs/core/src/lib/model/voucher-type.model';
import { GoogleTagManagerService } from 'libs/core/src/lib/service/analytics-services/google-tag-manager.service';
import { AppService } from 'libs/core/src/lib/service/app.service';
import { BackgroundService } from 'libs/core/src/lib/service/background.service';
import { CateringService } from 'libs/core/src/lib/service/catering/catering.service';
import { CountdownComponentService } from 'libs/core/src/lib/service/countdown.service';
import { HeaderService } from 'libs/core/src/lib/service/header.service';
import { LoadingService } from 'libs/core/src/lib/service/loading.service';
import { MessageService } from 'libs/core/src/lib/service/message.service';
import { NavigationHelperService } from 'libs/core/src/lib/service/navigation/navigation-helper.service';
import { NavigationService } from 'libs/core/src/lib/service/navigation/navigation.service';
import { ResponseValidatorService } from 'libs/core/src/lib/service/validator/response-validator.service';
import { VoucherService } from 'libs/core/src/lib/service/voucher.service';
import { AuthStateService } from 'libs/core/src/lib/state/auth.state.service';
import { CateringStateService } from 'libs/core/src/lib/state/catering.state.service';
import { OrderStateService } from 'libs/core/src/lib/state/order.state.service';
import { StateService } from 'libs/core/src/lib/state/state.service';
import { ENVIRONMENT_TOKEN } from 'libs/core/src/public-api';
import {
  PaymentProviderEvent,
  DonePaymentProviderEvent,
  ErrorPaymentProviderEvent,
  WaitPaymentProviderEvent,
  WorkPaymentProviderEvent,
} from 'libs/shared/src/lib/component/payment/proxy/event/payment-provider.event';
import { PaymentPreInitModel } from 'libs/shared/src/lib/component/payment/proxy/model/payment-pre-init.model';
import { PaymentProxyComponent } from 'libs/shared/src/lib/component/payment/proxy/payment-proxy.component';
import { PaymentProviderStateService } from 'libs/shared/src/lib/component/payment/service/payment-provider-state.service';
import { ErrorType } from 'libs/shared/src/lib/page/message/model/error-type.model';
import { BlockPaymentModel } from 'libs/shared/src/lib/page/payment/model/block-payment-model';
import { BlockPaymentEnum } from 'libs/shared/src/lib/page/payment/model/block-payment.enum';
import { PaymentErrorCode } from 'libs/shared/src/lib/page/payment/model/payment-error-code.enum';
import { Observable, iif, lastValueFrom, of, switchMap, tap } from 'rxjs';

export interface PaymentPageComponentInterface {}

@UntilDestroy()
@Component({
  selector: 'app-page-payment',
  templateUrl: './payment.component.html',
})
export class OneilPaymentPageComponent {
  async canDeactivate() {
    const deletePayments$ = this.orderStateService.hasExternalPayments() ? this.orderStateService.clearExternalPayments(ProviderEnum.WORLDPAY) : of(null);
    const value = await lastValueFrom(deletePayments$);
    return value instanceof OrderViewModel || value === null;
  }

  loaderEnum: typeof LoaderEnum = LoaderEnum;

  public constructor(
    @Inject(ENVIRONMENT_TOKEN) protected environment: any,
    protected orderDataProvider: OrderDataProvider,
    protected userDataProvider: UserDataProvider,
    protected authStateService: AuthStateService,
    protected orderStateService: OrderStateService,
    protected router: Router,
    protected navigationHelperService: NavigationHelperService,
    protected route: ActivatedRoute,
    protected backgroundService: BackgroundService,
    protected messageService: MessageService,
    protected translateService: TranslateService,
    protected responseValidatorService: ResponseValidatorService,
    protected basketDataProvider: BasketDataProvider,
    protected cateringService: CateringService,
    protected voucherService: VoucherService,
    protected selectedPaymentTypeService: PaymentProviderStateService,
    protected appService: AppService,
    protected countdownComponentService: CountdownComponentService,
    protected cateringStateService: CateringStateService,
    protected headerService: HeaderService,
    protected googleTagManagerService: GoogleTagManagerService,
    protected loadingService: LoadingService,
    protected navigationService: NavigationService,
    protected orderHttpService: OrderHttpService,
    protected stateService: StateService
  ) {
    this.cateringStateService.state$.pipe(untilDestroyed(this)).subscribe((value) => {
      this.cateringSaleEnabled = value;
    });

    if (this.route.snapshot.queryParams['sandboxPayment'] && this.route.snapshot.queryParams['sandboxPayment'] === 'true') {
      this.appService.enablePaymentSandbox();
    }

    this.paymentChannel = environment.constants.paymentChannel;
    this.autoSelectedPaymentMethod = environment.constants.autoSelectPaymentMethod;
    this.allowedPaymentMethodList = environment.constants.allowedPaymentMethod;

    if (this.appService.paymentSandboxEnabled) {
      this.allowedPaymentMethodList.push('sandbox');
    }

    this.voucherSaleEnabled = environment.constants.voucherSaleEnabled;
    this.selectedPaymentTypeService.state$.subscribe((state) => {
      if (state) {
        this.onChangeSelectedPaymentMethod(this.paymentMethodList.find((y) => y.identifier === state.provider));
      }
    });
    this.countdownComponentService.start();
  }

  get isLoaded() {
    return this.loadingService.loadingStatus === LoadingStatus.success && this.paymentLoadingStatus === LoadingStatus.success;
  }

  @ViewChild(PaymentProxyComponent) public paymentProxyComponent: PaymentProxyComponent;
  @ViewChild('buttonElement') public submitButton: ElementRef<HTMLButtonElement>;
  @ViewChild('personalComponent') public personalForm: PaymentPageComponentInterface;
  @ViewChild('feeAgreementCheckbox') public _feeAgreementCheckbox: ElementRef;

  public feeAgreementCheckboxError = false;
  public formIsEditing = true;
  public paymentLoadingStatus = LoadingStatus.pending;
  /**
   * The list of available payment method for order
   */
  public paymentMethodList: Array<PaymentMethodViewModel> = new Array<PaymentMethodViewModel>();

  /**
   * The payment data received from payment provider after init
   */
  public paymentModel: PaymentViewModel = null;

  /**
   * Selected payment method
   */
  public selectedPaymentMethod: PaymentMethodViewModel = null;
  public order: OrderViewModel = null;
  public selectedCateringArticleCombinationList: Array<CateringAggregationArticleViewModel> = null;
  public selectedVoucherList: Array<VoucherTypeModel> = null;
  public basketPageModel: BasketPageModel = new BasketPageModel();
  public personalComponentFormErrors: Array<string> | null = null;
  public showPaymentBlock = false;
  public personalFormData: PersonalModel = null;
  public paymentErrorMessage: string = null;

  /**
   * The payment channel that should be used
   */
  private readonly paymentChannel: string | null = null;

  /**
   * The default payment method to auto select
   */
  private readonly autoSelectedPaymentMethod: string | null = null;

  /**
   * The list of allowed payment methods. If null the constraint is disable
   */
  private readonly allowedPaymentMethodList: Array<string> | null = null;

  private readonly voucherSaleEnabled: boolean = false;
  private cateringSaleEnabled = false;
  public providerEnum: typeof ProviderEnum = ProviderEnum;

  public ngOnInit() {
    this.backgroundService.changeDeleteOrderOnWindowUnloadState(false);

    this.loadOrderState();

    this.cateringStateService.checkAvailability();
    this.googleTagManagerService.addToCart();
    this.googleTagManagerService.beginCheckout();
  }

  public ngOnDestroy(): void {
    this.selectedPaymentTypeService.clearState();
  }

  public blockPayment(value: BlockPaymentModel): void {
    switch (value.id) {
      case BlockPaymentEnum.feeAgreement:
        this.feeAgreementCheckboxError = value.value;
        break;
      case BlockPaymentEnum.editPersonalData:
        this.formIsEditing = value.value;
        if (this._feeAgreementCheckbox?.nativeElement) {
          this._feeAgreementCheckbox.nativeElement.disabled = !value.value;
        }
        break;
    }
  }

  public payButtonClicked(): void {
    if (
      (this.appService.isProject(appProjectName.SCHULMAN, appProjectName.VIOLET_CROWN, appProjectName.LANDMARK) && this.formIsEditing) ||
      !this.selectedPaymentMethod.identifier
    ) {
      return;
    }

    if (this.canCompleteOrder()) {
      this.loadingService.showLoader(LoaderEnum.MAIN);
      this.orderDataProvider.closeBasket(this.order.cinemaId, this.order.id).subscribe({
        next: () => {},
        error: () => {},
        complete: () => {
          const nextRoute = this.navigationHelperService.getNextRouteFor(this.route.snapshot.data.pageIdentify);
          this.router.navigate([nextRoute], {
            queryParams: this.getQueryParams(),
          });
        },
      });
    } else {
      this.paymentProxyComponent.onInitPayment(this.selectedPaymentMethod).subscribe();
    }
  }

  public onPersonalFormEvent(personalData: PersonalModel): void {
    if (personalData) {
      this.personalFormData = personalData;

      const apiModel: OrderApiModel = this.order.toApiModel();
      apiModel.userEmail = this.personalFormData.email;
      apiModel.userFirstName = this.personalFormData.firstname;
      apiModel.userLastName = this.personalFormData.lastname;
      apiModel.userPhone = this.personalFormData.phone;
      apiModel.taxId = this.personalFormData.taxId;

      this.orderDataProvider.update(this.order.cinemaId, apiModel).subscribe();
      this.showPaymentBlock = true;
    }
  }

  public onChangeSelectedPaymentMethod(selectedPaymentMethod: PaymentMethodViewModel): void {
    this.selectedPaymentMethod = selectedPaymentMethod;

    if (this.appService.isProject(appProjectName.CINEPOLIS) && this.selectedPaymentMethod?.identifier === ProviderEnum.WORLDPAY) {
      setTimeout(() => {
        this.payButtonClicked();
      });
    }
  }

  public onPaymentProviderEvent(event: PaymentProviderEvent): void {
    if (event instanceof WaitPaymentProviderEvent) {
      this.loadingService.showLoader(LoaderEnum.MAIN);
      this.paymentLoadingStatus = LoadingStatus.pending;
    }

    if (event instanceof WorkPaymentProviderEvent) {
      this.loadingService.showLoader(LoaderEnum.MAIN);
      this.paymentLoadingStatus = LoadingStatus.pending;
    }

    if (event instanceof DonePaymentProviderEvent) {
      this.loadingService.hideLoader(LoaderEnum.MAIN);
      this.paymentLoadingStatus = LoadingStatus.success;
    }

    if (event instanceof ErrorPaymentProviderEvent) {
      this.loadingService.hideLoader(LoaderEnum.MAIN);
      this.paymentLoadingStatus = LoadingStatus.success;
      this.errorHandler(event.originError as HttpErrorResponse);
    }
  }

  private loadOrderState(): void {
    this.orderStateService.state$
      .pipe(
        tap((order) => {
          this.order = order;
        }),
        switchMap((order) =>
          iif(() => order?.status === 0 || this.paymentProxyComponent.paymentInitialized, of(order), this.clearExternalPaymentsObservable(order))
        ),
        untilDestroyed(this)
      )
      .subscribe((order) => {
        if (!order) {
          this.router.navigate(['error', '107']);
          return;
        }

        this.basketDataProvider
          .do(order, this.order.cinemaId)
          .pipe(
            tap((basketPageModel) => {
              this.basketPageModel = basketPageModel;
              this.headerService.setData(this.basketPageModel.screen);
            }),
            switchMap(() => this.orderDataProvider.getPaymentMethodList(this.order.cinemaId, order.id))
          )
          .subscribe({
            next: (paymentMethodList: Array<PaymentMethodViewModel>) => {
              if (order.status === 1) {
                return;
              }

              if (this.appService.isProject(appProjectName.CINEPOLIS)) {
                this.changeWorldPayName(paymentMethodList);
              }

              if (!this.paymentMethodList?.length) {
                this.paymentMethodList = this.filterPaymentMethodList(paymentMethodList);
                this.selectDefaultPaymentMethod();
              }

              if (
                this.autoSelectedPaymentMethod === null ||
                this.autoSelectedPaymentMethod === ProviderEnum.WORLDPAY ||
                this.autoSelectedPaymentMethod === ProviderEnum.FISERV
              ) {
                this.loadingService.hideLoader(LoaderEnum.MAIN);
              } else {
                const preInitPaymentEvent: PaymentPreInitModel = new PaymentPreInitModel();
                preInitPaymentEvent.continueUrl = this.getContinueUrl();

                this.initPayment(this.autoSelectedPaymentMethod, preInitPaymentEvent);
              }
            },
            error: (e) => this.errorHandler(e),
            complete: () => {},
          });
      });
  }

  changeWorldPayName(paymentMethodList: PaymentMethodViewModel[]) {
    if (paymentMethodList.filter((p) => p.identifier == ProviderEnum.WORLDPAY).length > 0) {
      paymentMethodList.filter((p) => p.identifier == ProviderEnum.WORLDPAY)[0].name = this.translateService.instant('payment.creditCard.title');
    }
  }

  public getContinueUrl(): string {
    return this.generateContinueUrl();
  }

  private initPayment(paymentProviderIdentifier: string, preInitPaymentEvent: PaymentPreInitModel): void {
    this.loadingService.showLoader(LoaderEnum.MAIN);

    const proxyOnPostInitPayment = (paymentModel, paymentProviderIdentifier) => {
      this.paymentProxyComponent.onPostInitPayment(paymentModel, paymentProviderIdentifier);
    };

    this.orderDataProvider
      .postPayment(this.createPaymentRequest(paymentProviderIdentifier, preInitPaymentEvent))
      .pipe(
        tap({
          next: (paymentModel: PaymentViewModel) => {
            proxyOnPostInitPayment(paymentModel, paymentProviderIdentifier);
          },
          error: (e) => {
            this.errorHandler(e);
          },
          complete: () => {},
        }),
        switchMap(() => {
          const order = this.orderStateService.getOrder();
          return this.orderDataProvider.getOrder(order?.cinemaId, order?.id);
        })
      )
      .subscribe({
        next: (o) => {
          this.orderStateService.setOrder(o);
        },
        error: () => {},
      });
  }

  private createPaymentRequest(paymentProviderIdentifier: string, preInitPaymentEvent: PaymentPreInitModel): OrderPaymentRequestModel {
    const orderPaymentRequest: OrderPaymentRequestModel = new OrderPaymentRequestModel();
    orderPaymentRequest.cinemaId = this.order.cinemaId;
    orderPaymentRequest.orderId = this.order.id;
    orderPaymentRequest.paymentChannel = this.paymentChannel;
    orderPaymentRequest.paymentProviderIdentifier = paymentProviderIdentifier;
    orderPaymentRequest.continueUrl = preInitPaymentEvent.continueUrl;
    orderPaymentRequest.paymentData = preInitPaymentEvent.paymentData;
    orderPaymentRequest.intPayMethodType = preInitPaymentEvent.intPayMethodType;
    orderPaymentRequest.intPayMethodValue = preInitPaymentEvent.intPayMethodValue;
    orderPaymentRequest.savePayment = preInitPaymentEvent.saveToken;

    return orderPaymentRequest;
  }

  protected generateContinueUrl(): string {
    let urlTree: string = '';
    if (this.autoSelectedPaymentMethod === ProviderEnum.WORLDPAY) {
      urlTree = this.router
        .createUrlTree(['p'], {
          queryParams: this.getQueryParams(),
        })
        .toString();
    } else {
      urlTree = this.router
        .createUrlTree([this.navigationHelperService.getNextRoute(this.route.snapshot)], {
          queryParams: this.getQueryParams(),
        })
        .toString();
    }

    return `${window.location.origin}/#${urlTree}`;
  }

  private getQueryParams() {
    return {
      orderId: this.order.id,
      cinemaId: this.order.cinemaId,
    };
  }

  private filterPaymentMethodList(paymentMethodList: Array<PaymentMethodViewModel>): Array<PaymentMethodViewModel> {
    if (this.allowedPaymentMethodList === null) {
      return paymentMethodList;
    }

    return paymentMethodList
      .filter((paymentMethod: PaymentMethodViewModel) => {
        if (paymentMethod.identifier) {
          return this.allowedPaymentMethodList.indexOf(paymentMethod.identifier.toLowerCase()) >= 0;
        }
      })
      .map((o) => {
        o.disabled = this.selectedPaymentMethod?.identifier === o.identifier;
        return o;
      })
      .sort((a, b) => {
        return a.identifier
          ? this.allowedPaymentMethodList.indexOf(a.identifier.toLowerCase()) < this.allowedPaymentMethodList.indexOf(b.identifier.toLowerCase())
            ? -1
            : 1
          : 1;
      });
  }

  private selectDefaultPaymentMethod(): void {
    if (this.appService.isProject(appProjectName.CINEPOLIS)) {
      return;
    }

    if (!this.selectedPaymentMethod && this.autoSelectedPaymentMethod) {
      this.selectedPaymentMethod = this.paymentMethodList.find((o) => o.identifier === this.autoSelectedPaymentMethod);
    }
  }

  private showError(code?: string): void {
    code = code ? code : '30005'; // code or default
    this.messageService.add(new MessageModel(MessageType.danger, this.translateService.instant(`errors.${code}`)));
  }

  /**
   * An callback which handles errors from requests
   */
  private errorHandler(err: HttpErrorResponse): void {
    const errStatus: any = err && err.status ? err.status : null;

    if (errStatus === 500) {
      this.navigationHelperService.redirectToError(ErrorType.unexpectedErrorOccurred);
    } else if (errStatus === 400) {
      const isHandled: boolean = this.handleValidationError(err);

      if (isHandled === false) {
        this.loadingService.hideLoader(LoaderEnum.MAIN);
        this.paymentLoadingStatus = LoadingStatus.success;
        return this.responseValidatorService.viewErrors(err);
      }
    } else if (errStatus === 404) {
      this.navigationHelperService.redirectToError(ErrorType.paymentAlreadyStarted);
    }

    if (!this.appService.isProject(appProjectName.SCHULMAN, appProjectName.VIOLET_CROWN, appProjectName.LANDMARK, appProjectName.ONEIL)) {
      this.showError();
    }

    this.responseValidatorService.viewErrors(err);
    this.loadingService.hideLoader(LoaderEnum.MAIN);
    this.paymentLoadingStatus = LoadingStatus.success;
  }

  /**
   * Handles an well described errors
   */
  private handleValidationError(err: HttpErrorResponse): boolean {
    let infoCode: string = null;

    if (err && err.error && err.error.error) {
      const e = err.error.error;
      if (e.info_code) {
        infoCode = e.info_code;
      } else if (e.code) {
        infoCode = e.code;
      }
    }

    if (infoCode && typeof infoCode !== 'string') {
      infoCode = (infoCode as any).toString();
    }

    let translationResult = false;
    const numericInfoCode = Number.parseInt(infoCode);

    if (numericInfoCode === PaymentErrorCode.missedBuyerEmailAddress) {
      this.goToPreviousRoute();
    }

    if (infoCode !== null) {
      const translationKey = `payment.errors.${this.selectedPaymentMethod?.identifier}.${infoCode}`;
      const errorContentTranslation: string = this.translateService.instant(translationKey);

      if (errorContentTranslation !== translationKey) {
        this.messageService.clear();

        if (!this.appService.isProject(appProjectName.SCHULMAN, appProjectName.VIOLET_CROWN, appProjectName.LANDMARK, appProjectName.ONEIL)) {
          this.messageService.add(new MessageModel(MessageType.danger, errorContentTranslation, infoCode));
        } else {
          this.paymentErrorMessage = errorContentTranslation;
        }

        translationResult = true;
      }
    }

    return translationResult;
  }

  private goToPreviousRoute(): void {
    this.router.navigate([this.navigationHelperService.getPreviousRoute(this.route.snapshot)]);
  }

  public onNavigationClick(event: string) {
    switch (event) {
      case 'previous':
        this.onPreviousAction();
        break;
      case 'next':
        this.submitButton.nativeElement.click();
        break;
    }
  }

  public onPreviousAction(previousRoute: boolean = true) {
    if (previousRoute) {
      this.router.navigate([this.navigationHelperService.getPreviousRoute(this.route.snapshot)]);
    }
  }

  public onAgreementClick(): void {
    this.feeAgreementCheckboxError = false;
  }

  public onClickedCloseModal(): void {
    this.paymentErrorMessage = null;
  }

  public onStepBackClick(): void {
    this.navigationService.back();
  }

  canCompleteOrder() {
    return this.order?.valueToPay === 0 && this.order?.status === 0;
  }

  clearExternalPayments() {
    this.clearExternalPaymentsObservable(this.order).subscribe();
  }

  clearExternalPaymentsObservable(order: OrderViewModel): Observable<OrderViewModel> {
    return this.orderHttpService.removeExternalPaymentMethod(new PaymentProviderPayMethodRequestModel(order.cinemaId, order.id, ProviderEnum.FISERV)).pipe(
      switchMap(() => this.orderDataProvider.getOrder(order.cinemaId, order.id)),
      tap({
        next: (o) => {
          this.orderStateService.setOrder(o);
          this.selectedPaymentTypeService.setState(this.selectedPaymentTypeService.getState());
          if (!o) {
            this.router.navigate(['error', '107']);
          }
        },
        error: (e) => {
          throw e;
        },
      }),
      untilDestroyed(this)
    );
  }
}
