<app-popup [isVisible]="true">
  <div class="row pb-4">
    <div class="col-12">
      <h4> <span class="font-weight-normal"> {{
        'refund.modal.success_first_line' |
        translate: {
          plural_text: 'pluralMapper.someOfAllTicket' | translate: { some: content.refundedTicketsCount, all: content.allTicketsCount } | translateSelector: content.refundedTicketsCount
        }
      }}</span></h4>
      <div class="question">
        <span class="font-weight-normal">{{ 'refund.modal.success_second_line' | translate }}</span>
      </div>
    </div>
  </div>
  <div class="row" footer>
    <div class="col-12 text-center">
      <button type="button" class="btn btn-next btn-back" (click)="close(true)">
        {{ 'refund.modal.button.back' | translate }}
      </button>
      <button type="button" class="btn btn-next" (click)="close()">
        {{ 'refund.modal.button.another_refund' | translate }}
      </button>
    </div>
  </div>
</app-popup>