import { DateTime, DateTimeFormatOptions } from 'luxon';
import { WeekdayService } from '../service/weekday.service';
import { SupportedDateTimeFormat } from './supported-datetime-format.enum';

export class BaseLocaleData {
  constructor(protected weekdayService: WeekdayService) {}

  customWeekdayFormat(value: DateTime, originFormat: string, replacer: string = 'cccc') {
    const index = originFormat?.indexOf(replacer);
    let v = index < 0 ? '' : this.weekdayService.getCustomDayName(value);

    if (!v.length) {
      return originFormat;
    }

    const s = originFormat.replace(replacer, '');
    return [value.toFormat(s.slice(0, index)), "'" + v + "'", value.toFormat(s.slice(index))].join('');
  }

  customWeekday(value: DateTime) {
    const customeDayName = this.weekdayService.getCustomDayName(value);
    return customeDayName === '' ? null : customeDayName;
  }

  convertFormatToDateTimeFormatOptions(format: string): DateTimeFormatOptions {
    switch (format) {
      case SupportedDateTimeFormat.DATE_SHORT:
        return DateTime.DATE_SHORT;
      // case 'DATE_MED':
      //   return DateTime.DATE_MED;
      // case 'DATE_FULL':
      //   return DateTime.DATE_FULL;
      // case 'DATE_HUGE':
      //   return DateTime.DATE_HUGE;
      case SupportedDateTimeFormat.TIME_SIMPLE:
        return DateTime.TIME_SIMPLE;
      // case 'TIME_WITH_SECONDS':
      //   return DateTime.TIME_WITH_SECONDS;
      // case 'DATETIME_SHORT':
      //   return DateTime.DATETIME_SHORT;
      // case 'DATETIME_MED':
      //   return DateTime.DATETIME_MED;
      // case 'DATETIME_FULL':
      //   return DateTime.DATETIME_FULL;
      // case 'DATETIME_HUGE':
      //   return DateTime.DATETIME_HUGE;
    }
  }
}
