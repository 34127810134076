import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import orderBy from 'lodash-es/orderBy';
import { storageKey } from 'libs/core/src/app.const';
import { BasketPageModel } from 'libs/core/src/lib/model/page/basket/basket.page.model';
import { IVoucher, OrderViewModel } from 'libs/core/src/lib/model/view-model/order/order.view.model';
import { ScreeningItemViewModel } from 'libs/core/src/lib/model/view-model/order/screening-item/screening-item.view.model';
import { SeatTranslatorService } from 'libs/core/src/lib/service/seat/seat-translator.service';
import { TotalizerService } from 'libs/core/src/lib/service/totalizer.service';
import { ENVIRONMENT_TOKEN, OrderStateService } from 'libs/core/src/public-api';
import { BasketFeeComponentInterface } from '../basket-fee/basket-fee.component';
import { ChangeTicketEvent } from './event/change-ticket.event';
import { TicketViewModel } from 'libs/core/src/lib/model/view-model/shared/ticket/ticket.view.model';
import { SeatViewModel } from 'libs/core/src/lib/model/view-model/screen/seat/seat.view.model';
import { StateService } from 'libs/core/src/lib/state/state.service';
import { BasketExtraFeeService } from '../basket-extra-fee.service';
import { ScreenViewModel } from 'libs/core/src/lib/model/view-model/screen/screen.view.model';
import { UserDataProvider } from 'libs/core/src/lib/data-provider/user.data-provider';
import { map, tap } from 'rxjs';
import { CardViewModel } from 'libs/core/src/lib/model/view-model/account-items/card/card.view.model';
import { AuthStateService } from 'libs/core/src/lib/state/auth.state.service';

@Component({
  template: '',
})
export abstract class BasketListComponent implements OnInit {
  @Input() public basketPageModel: BasketPageModel;
  @Input() public orderModel: OrderViewModel;
  @Input() public vouchers: string[];
  @Output() public ticketChangeEvent: EventEmitter<ChangeTicketEvent> = new EventEmitter<ChangeTicketEvent>();
  @Output() public deleteVoucherEvent = new EventEmitter<string>();
  @Output() public deleteCardEvent = new EventEmitter<string>();
  @ViewChild('basketFeeComponent', { static: true }) private basketFeeComponent: BasketFeeComponentInterface;
  @Output() public deleteSingleVoucherItemEvent = new EventEmitter();

  public options: Map<string, Array<TicketViewModel>>;
  public seats: { id: string; row: string; col: string; item: ScreeningItemViewModel; optionalTickets: TicketViewModel[] }[] = [];
  screenType = '';

  public total = 0;
  public selectedSeats = [];
  private skippingRomanDigitsInRows = false;
  private skippingRomanDigitsInCols = false;

  public orderVouchers: IVoucher[] = [];
  public orderCard: CardViewModel;

  constructor(
    @Inject(ENVIRONMENT_TOKEN) protected environment: any,
    protected totalizerService: TotalizerService,
    protected stateService: StateService,
    protected basketExtraFeeService: BasketExtraFeeService,
    protected orderStateService: OrderStateService,
    protected userDataProvider: UserDataProvider,
    protected authStateService: AuthStateService
  ) {}

  public ngOnInit() {
    this.totalizerService.verifyTicket$.subscribe((orderTickets) => {
      this.rebuildOptionalTickets(orderTickets);
    });

    this.orderStateService.state$.subscribe((order: OrderViewModel) => this.onOrderStateChanged(order));

    this.ticketChangeEvent.subscribe((d) => {
      this.basketExtraFeeService.ticketsChange();
    });
  }

  private onOrderStateChanged(order) {
    if (!order) {
      return;
    }

    this.orderModel = order;
    this.orderVouchers = order.getVouchers();

    if (this.authStateService.isLogged()) {
      this.userDataProvider
        .getAccountItemsViaApiModel()
        .pipe(
          map((items) => items.cards),
          tap((cards) => {
            if (order.cardId) {
              const filteredCards = cards.filter((c) => c.id === order.cardId);
              this.orderCard = filteredCards.length > 0 ? filteredCards[0] : null;
            } else {
              this.orderCard = null;
            }
          })
        )
        .subscribe();
    }

    const seats = this.basketPageModel?.screen?.seats;
    if (seats) {
      this.skippingRomanDigitsInRows = SeatTranslatorService.isNotRomanDigit(seats.map((elem) => elem.rowNumber));
      this.skippingRomanDigitsInCols = SeatTranslatorService.isNotRomanDigit(seats.map((elem) => elem.legendCol));

      this.setSelectedSeats(this.skippingRomanDigitsInRows, this.skippingRomanDigitsInCols, this.orderModel.screeningItems, this.basketPageModel.screen);
    } else {
      console.info('No seats found');
    }

    this.afterOrderStateChanged();
  }

  public abstract afterOrderStateChanged();
  public abstract rebuildOptionalTickets(orderTickets: TicketViewModel[]);

  public onTicketChange(ticketId, seatId) {
    this.stateService.setItem(storageKey.isExtraFeesSelected, 'false');
    this.ticketChangeEvent.emit(new ChangeTicketEvent(seatId, ticketId));
  }

  setSelectedSeats(skippingRomanDigitsInRows: boolean, skippingRomanDigitsInCols: boolean, screeningItems: ScreeningItemViewModel[], screen: ScreenViewModel) {
    let seats: SeatViewModel[] = [];

    screeningItems.forEach((item) => {
      const seat: SeatViewModel = screen.findSeatById(item.seatId);
      if (seat) {
        seat.translateRow = SeatTranslatorService.translateValue(seat.rowNumber, skippingRomanDigitsInRows);
        seat.translateCol = SeatTranslatorService.translateValue(seat.legendCol, skippingRomanDigitsInCols);
        seats.push(seat);
      }
    });

    this.selectedSeats = orderBy(seats, ['translateRow', 'translateCol']);
  }

  public getOrderItem(seatId): ScreeningItemViewModel {
    const screeningItems = this.orderModel?.screeningItems;
    return screeningItems ? screeningItems.find((c) => c.seatId === seatId) : null;
  }

  public deleteVoucher(number: string = null): void {
    this.deleteVoucherEvent.emit(number);
  }
  public deleteCard(number: string = null): void {
    this.deleteCardEvent.emit(number);
  }

  public onBasketFeeChanged() {
    this.orderStateService.changeSummaryStateEmit();
  }

  public onTicketSelect(ticket: TicketViewModel, item: ScreeningItemViewModel) {
    if (item.hasVoucher()) {
      this.deleteSingleVoucher(item);
    } else {
      this.onTicketChange(ticket.id, item.seatId);
    }
  }
  public deleteSingleVoucher(item: ScreeningItemViewModel) {
    this.deleteSingleVoucherItemEvent.emit(item);
  }

  public selectedTicketsHaveOptionalFees(): boolean {
    return this.basketPageModel && this.basketPageModel.tickets.some((x) => x.extraFees.some((y) => y.isOptional));
  }
}
