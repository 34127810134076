import { WordpressPostTypeEnum } from '@lib/core';

export class PostRequest {
  type: string = WordpressPostTypeEnum.POSTS;
  page?: number;
  perPage?: number;
  offset?: number;
  order?: string;
  orderby?: string;
  categories?: number[] | string;
}
