import { Component, Inject, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ENVIRONMENT_TOKEN, RedirectionService, StateService, TranslationService } from '@lib/core';

@Component({
  template: '',
})
export class AppComponent implements OnInit {
  constructor(
    @Inject(ENVIRONMENT_TOKEN) protected environment: any,
    protected translationService: TranslationService,
    protected title: Title,
    protected redirectionService: RedirectionService,
    protected stateService: StateService
  ) {
    this.makeRedirectionOnAppLaunch();
  }

  public ngOnInit(): void {
    this.title.setTitle(this.environment.metadata.title);
  }

  useLanguage(locale: string) {
    this.translationService.useLanguage(locale);
  }

  protected makeRedirectionOnAppLaunch(): void {
    let urlToRedirect: string | null = this.redirectionService.getRedirectionOnNextAppLaunch();
    this.redirectionService.removeRedirectionOnNextAppLaunch();
    if (!urlToRedirect) {
      return;
    }

    const queryParameters: URLSearchParams = new URLSearchParams(window.location.search);
    const urlParameters: string = queryParameters.toString();

    if (urlParameters && urlParameters.length > 0) {
      urlToRedirect = urlToRedirect + '&' + urlParameters;
    }

    window.location.href = urlToRedirect;
  }
}
