import { Component, OnInit, TemplateRef } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { LoaderEnum } from 'libs/core/src/lib/enum/loader.enum';
import { UserLoyaltyPopupTypeEnum } from 'libs/core/src/lib/model/enum/user-loyalty-popup-type';
import { CardPaymentService } from 'libs/core/src/lib/service/card-payment.service';
import { LoadingService } from 'libs/core/src/lib/service/loading.service';
import { IAccountItemsObject, LoyaltyService } from 'libs/core/src/lib/service/loyalty.service';
import { VoucherService } from 'libs/core/src/lib/service/voucher.service';
import { AuthStateService } from 'libs/core/src/lib/state/auth.state.service';
import { OrderStateService } from 'libs/core/src/lib/state/order.state.service';
import { IAccountItem } from '../../account-items/account-items.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { OrderViewModel } from 'libs/core/src/lib/model/view-model/order/order.view.model';
import { ErrorHandlerService } from 'libs/core/src/lib/service/error-handler/error-handler.service';

@UntilDestroy()
@Component({
  template: '',
})
export class LoyaltyPaymentListComponent implements OnInit {
  items: IAccountItemsObject;
  order: OrderViewModel;
  isLogged = false;
  userLoyaltyPopupType: UserLoyaltyPopupTypeEnum;
  isGiftCardCollapsed = true;
  isVoucherCollapsed = true;
  isPrepaidCollapsed = true;

  constructor(
    protected authStateService: AuthStateService,
    protected cardPaymentService: CardPaymentService,
    protected orderStateService: OrderStateService,
    protected voucherService: VoucherService,
    protected loyaltyService: LoyaltyService,
    protected translateService: TranslateService,
    protected loadingService: LoadingService,
    protected modalService: BsModalService,
    protected errorHandlerService: ErrorHandlerService
  ) {}

  ngOnInit() {
    this.orderStateService.state$.pipe(untilDestroyed(this)).subscribe((order) => {
      this.order = order;
    });

    this.loyaltyService
      .getAccountItems()
      .pipe(untilDestroyed(this))
      .subscribe((items) => {
        this.items = items;
      });

    this.authStateService
      .isLogged$()
      .pipe(untilDestroyed(this))
      .subscribe((s) => (this.isLogged = s));
  }

  useItem(item: IAccountItem) {
    this.loadingService.showLoader(LoaderEnum.LOYALTY);

    switch (item.modelType) {
      case 'voucher':
        this.useVoucher(item?.model?.number);
        break;
      case 'card':
        this.useCard(item?.model);
        break;
      default:
        break;
    }
  }

  useCard(card) {
    this.cardPaymentService.useCard(card?.id, card?.type).subscribe({
      next: (res) => {
        if (!res?.hasCardPayment(card?.id)) {
          this.handleCardError();
          return;
        }
      },
      error: (e) => {
        this.loadingService.hideLoader(LoaderEnum.LOYALTY);
        this.handleCardError(e);
      },
      complete: () => {
        this.loadingService.hideLoader(LoaderEnum.LOYALTY);
      },
    });
  }

  useCardByNumber(number: string) {
    this.loadingService.showLoader(LoaderEnum.LOYALTY);
    this.cardPaymentService.useCardByNumber(number).subscribe({
      next: () => {},
      error: (e) => {
        this.loadingService.hideLoader(LoaderEnum.LOYALTY);
        this.handleCardError(e);
      },
      complete: () => {
        this.loadingService.hideLoader(LoaderEnum.LOYALTY);
      },
    });
  }

  handleCardError(e?: any) {
    const error = this.errorHandlerService.getError(e);
    switch (error?.code) {
      case '123':
        this.loyaltyService.showError(UserLoyaltyPopupTypeEnum.CardError, this.translateService.instant('gift-card.input.error.not_found'));
        break;
      case '279':
        this.loyaltyService.showError(UserLoyaltyPopupTypeEnum.CardError, this.translateService.instant('gift-card.input.error.already_locked'));
        break;
      default:
        this.loyaltyService.showError(UserLoyaltyPopupTypeEnum.CardError, this.translateService.instant('gift-card.input.error.cannot_use'));
        break;
    }
  }

  useVoucher(number: string) {
    this.loadingService.showLoader(LoaderEnum.LOYALTY);

    if (this.order?.paymentMethods?.length > 0) {
      this.loadingService.hideLoader(LoaderEnum.LOYALTY);
      this.handleVoucherError({ code: '777' });
      return;
    }

    if (this.order?.cinemaId && this.order?.id) {
      this.voucherService.assignVoucherToOrderAndSetOrder(this.order.cinemaId, this.order.id, number).subscribe({
        error: (e) => {
          this.loadingService.hideLoader(LoaderEnum.LOYALTY);
          this.handleVoucherError(e);
        },
        complete: () => {
          this.loadingService.hideLoader(LoaderEnum.LOYALTY);
        },
      });
    }
  }

  handleVoucherError(e?: any) {
    const error = this.errorHandlerService.getError(e);
    switch (error?.code) {
      case '460':
        this.loyaltyService.showError(UserLoyaltyPopupTypeEnum.VoucherError, this.translateService.instant('voucher.input.error.not_found'));
        break;
      case '461':
        this.loyaltyService.showError(UserLoyaltyPopupTypeEnum.VoucherError, this.translateService.instant('voucher.input.error.already_used'));
        break;
      case '777':
        this.loyaltyService.showError(UserLoyaltyPopupTypeEnum.VoucherError, this.translateService.instant('voucher.input.error.loyalty_payment_exists'));
        break;
      default:
        this.loyaltyService.showError(UserLoyaltyPopupTypeEnum.VoucherError, this.translateService.instant('voucher.input.error.cannot_use'));
        break;
    }
  }

  public showLoginPopup(template: TemplateRef<any>) {
    this.modalService.show(template, { class: 'login-modal' });
  }
}
