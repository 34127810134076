import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-numeric-stepper',
  templateUrl: './numeric-stepper.component.html',
})
export class NumericStepperComponent {
  @Output() quantityEvent = new EventEmitter<number>();
  @Output() quantityChange = new EventEmitter<number>();
  @Input() maxQuantity = 99;
  @Input() minQuantity = 1;
  @Input() inputField = false;
  @Input() quantity = 1;
  @Input() step = 1;
  @Input() disabled = false;
  @Input() selectOnFocus = false;

  constructor() {}

  public onInputChanged(event): void {
    this.quantityEvent.emit(this.quantity);
    this.quantityChange.emit(this.quantity);
  }

  public addQuantity(): void {
    if (this.canBeIncrement()) {
      this.quantity += this.step;
      this.quantityEvent.emit(this.quantity);
      this.quantityChange.emit(this.quantity);
    }
  }

  public subQuantity(): void {
    if (this.canBeDecrement()) {
      this.quantity -= this.step;
      this.quantityEvent.emit(this.quantity);
      this.quantityChange.emit(this.quantity);
    }
  }

  public canBeDecrement(): boolean {
    return this.quantity - this.step >= this.minQuantity;
  }

  public canBeIncrement(): boolean {
    return this.quantity + this.step <= this.maxQuantity;
  }

  selectInput(event: FocusEvent): void {
    if (!this.selectOnFocus) {
      return;
    }
    const inputElement = event.target as HTMLInputElement;
    inputElement.select();
  }
}
