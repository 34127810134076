import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { ENVIRONMENT_TOKEN } from '../injection.tokens';
import { MessageModel } from '../model/message.model';

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  private state = new BehaviorSubject<MessageModel>(null);
  public state$ = this.state.asObservable();

  /**
   * The pages which are excluded to display messages
   */
  private excludedPages: Array<string> = new Array<string>();

  public constructor(@Inject(ENVIRONMENT_TOKEN) environment: any, private router: Router) {
    this.excludedPages =
      environment && environment['constants'] && environment.constants['excludeMessageOnPages']
        ? environment.constants['excludeMessageOnPages']
        : new Array<string>();
  }

  list() {
    return this.state.value;
  }

  add(message: MessageModel) {
    const routeIdentifier: string = this.getCurrentRoutePageIdentifier();
    if (routeIdentifier && this.isRouteExcluded(routeIdentifier)) {
      message.excluded = true;
      //return;
    }

    this.state.next(message);
  }

  clear() {
    this.state.next(null);
  }

  private getCurrentRoutePageIdentifier(): string | null {
    let route = this.router.routerState.root;
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route.snapshot.data['pageIdentify'] || undefined;
  }

  private isRouteExcluded(routeIdentifier: string): boolean {
    return this.excludedPages.indexOf(routeIdentifier) >= 0;
  }
}
