import { LegendItemType } from '../enum/legend-item-type.enum';

export class LegendModel {
  constructor(
    public classKey: string,
    public translationPath: string,
    public legendItemType?: LegendItemType,
    public color?: string,
    public displayMode: number = 2
  ) {}
}
