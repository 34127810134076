<div class="container">
  <div class="row">
    <div class="text-center col-12 p-0">
      <app-steps [step]="order?.isOnlyGiftCardOrder() ? 2 : 3"></app-steps>
    </div>
  </div>

  <div class="main-container">
    <div [smooth-in]="!loadingService.isLoading(loaderEnum.MAIN)">
      <ng-container *ngIf="basketPageModel">
        <div class="row">
          <div class="col-12 col-sm-6">
            <div class="mt-3 mb-4 my-sm-5">
              <app-book-movie-list [screen]="basketPageModel?.screen"
                                   [cinemaId]="order?.cinemaId"></app-book-movie-list>
            </div>
          </div>
          <div class="col-12 col-sm-6 my-2 my-sm-5 text-right order-first order-sm-last">
            <app-simple-countdown></app-simple-countdown>
          </div>
        </div>

        <div class="row rgap-1">
          <div class="col-md-6">
            <app-sidebar>
              <div sidebar-title>
                {{ 'basket.title' | translate }}
              </div>
              <div class="cart-sidebar" sidebar-body>
                <ng-container *ngIf="order && basketPageModel">
                  <app-order-order-summary [basketPageModel]="basketPageModel"
                                           buttonText="{{'personal.personal.form.submit'|translate}}"
                                           [selectedCateringArticleCombinationList]="selectedCateringArticleCombinationList"
                                           [order]="order" [submitButtonShow]="false">
                  </app-order-order-summary>
                </ng-container>
              </div>
            </app-sidebar>
          </div>

          <div class="col-md-6">
            <app-sidebar>
              <div sidebar-title>
                {{ 'personal.title' | translate }}
              </div>
              <div sidebar-body>
                <div class="row" *ngIf="order">
                  <div class="col-12">
                    <app-personal #personalComponent [order]="order" [formErrors]="personalComponentFormErrors"
                                  (onSubmitEvent)="onPersonalFormEvent($event)" [feeLabel]="_feeAgreementCheckbox">
                    </app-personal>
                  </div>
                </div>
              </div>
            </app-sidebar>
          </div>
        </div>

        <div class="row">
          <div class="col-12 mt-4">
            <app-screen-navigation (navigationEvent)="onNavigationClick($event)"
                                   [screeningId]="basketPageModel.screening?.id"
                                   [isNextButtonDisabled]="loadingService.isLoading(loaderEnum.NEXT_BUTTON)"
                                   [isLoadingData]="loadingService.isLoading(loaderEnum.NEXT_BUTTON)">
            </app-screen-navigation>
          </div>
        </div>
      </ng-container>
    </div>

  </div>
</div>

<!-- <app-popup [isVisible]="paymentErrorMessage">
  <div class="row pb-4">
    <div class="col-12">
      <div class="question" [innerHTML]="paymentErrorMessage"></div>
    </div>
  </div>
  <div class="row" footer>
    <div class="col-12 text-center">
      <input type="submit" class="btn btn-lg btn-primary" value="{{ 'screen.popup.close_button' | translate }}"
             (click)="onClickedCloseModal()" />
    </div>
  </div>
</app-popup> -->