import { Component, TemplateRef, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { LoaderEnum } from 'libs/core/src/lib/enum/loader.enum';
import { RedirectionService } from 'libs/core/src/lib/service/redirection-service';
import { AuthStateService } from 'libs/core/src/lib/state/auth.state.service';
import { StateService } from 'libs/core/src/lib/state/state.service';
import { environment } from '../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AppComponent, TranslationService } from '@lib/core';

@Component({
  selector: 'app-positivecinema',
  templateUrl: './app.component.html',
})
export class CustomAppComponent extends AppComponent {
  loaderEnum: typeof LoaderEnum = LoaderEnum;
  @ViewChild('loginPopupTemplate') loginModal: TemplateRef<any>;

  constructor(
    protected translationService: TranslationService,
    protected title: Title,
    protected redirectionService: RedirectionService,
    protected stateService: StateService,
    protected authStateService: AuthStateService,
    protected activatedRoute: ActivatedRoute,
    protected router: Router,
    protected modalService: BsModalService
  ) {
    super(environment, translationService, title, redirectionService, stateService);
  }

  public override ngOnInit(): void {
    this.authStateService.retrieveUserToken();

    this.router.events.subscribe(() => {
      this.title.setTitle(environment.metadata.title);
    });

    this.activatedRoute.queryParams.subscribe((params) => {
      if (params?.signin === 'true' && !this.authStateService.userIsLoggedAndTokenIsValid()) {
        this.modalService.show(this.loginModal, {});
      }
    });
  }
}
