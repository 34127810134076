import { IPaymentBaseModel } from 'libs/core/src/lib/model/interface/payment-base.model';

export class PaymentPreInitModel implements IPaymentBaseModel {
  public cinemaId: string;
  public orderId: string;

  public paymentProviderIdentifier: string | null = null;

  public continueUrl: string | null = null;
  public paymentData: { [key: string]: any } = null;
  public intPayMethodType: string | null = null;
  public intPayMethodValue: string | null = null;

  public abort = false;
  public saveToken: boolean | null = null;
}
