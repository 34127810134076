import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of, Subject } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { instanceToPlain, plainToInstance } from 'class-transformer';
import { Cacheable } from 'ts-cacheable';
import { OrderApiModel } from '../model/api-model/order/order.api.model';
import { OrderAliasApiModel } from '../model/api-model/order/order-alias.api.model';
import { CreateNewOrderRequest } from '../model/request/create-new-order-request.model';
import { OrderMembershipUpdateRequestModel } from '../model/request/order-membership-update.request.model';
import { OrderPaymentRequestModel } from '../model/request/order-payment.request.model';
import { PaymentProviderConfigRequestModel } from '../model/request/payment-provider-config.request.model';
import { PaymentConfigApiModel } from '../model/api-model/payment/config/payment.config.api.model';
import { PaymentProviderPayMethodRequestModel } from '../model/request/payment-provider-pay-method.request.model';
import { VirtualPassApiModel } from '../model/api-model/sales-document/virtual-pass/virtual-pass.api.model';
import { CateringApiModel } from '../model/api-model/order/catering.api.model';
import { PickupTimeApiModel } from '../model/api-model/pickup-time.api.model';
import { ENVIRONMENT_TOKEN } from '../injection.tokens';
import { PaymentApiModel } from '../model/api-model/payment.api.model';
import { PaymentMethodApiModel } from '../model/api-model/order/payment-method/payment-method.api.model';
import { PaymentProviderPayMethodApiModel } from '../model/api-model/payment-provider-pay-method.response.model';
import { NutritionalInfoModel } from '../model/catering/nutritional-info.response.model';
import { TableApiModel } from '../model/api-model/table/table.api.model';
import { SeatRequestRequestModel } from '../model/api-model/seat/seat.request.model';
import { CateringResponseModel } from '../model/catering/catering.response.model';
import { DateTime } from 'luxon';
import { ExecutionStrategy } from '../enum/execution-strategy.enum';
import { CreatePaymentRequestModel } from '../model/request/create-payment.request.model';

@Injectable({
  providedIn: 'root',
})
export class OrderHttpService {
  public static cacheBuster$ = new Subject<void>();
  public static cacheModifier$ = new Subject<any>();

  constructor(@Inject(ENVIRONMENT_TOKEN) protected environment: any, private http: HttpClient) {}

  public getOrder(cinemaId: string, orderId: string, executionStrategy: ExecutionStrategy): Observable<OrderApiModel> {
    if (executionStrategy === ExecutionStrategy.FORCE) {
      OrderHttpService.cacheBuster$.next();
    }

    return this.http.get<OrderApiModel>(`/cinema/${cinemaId}/order/${orderId}`);
  }

  public getOrderTransactionNumber(bookingId: string): Observable<OrderAliasApiModel> {
    return this.http.get<OrderAliasApiModel>(`/order/transactionNumber/${bookingId}`);
  }

  public create(cinemaId: string, sourceOrderId: string = null): Observable<OrderApiModel> {
    const createNewOrderRequest: CreateNewOrderRequest = {
      sourceOrderId: sourceOrderId,
    };

    return this.http.post<OrderApiModel>(`/cinema/${cinemaId}/order`, instanceToPlain(createNewOrderRequest)).pipe(
      tap(() => OrderHttpService.cacheBuster$.next()),
      map((res) => {
        return plainToInstance(OrderApiModel, res as Object, { strategy: 'excludeAll' });
      })
    );
  }

  public update(cinemaId: string, order: OrderApiModel) {
    console.log('update order by:', order);
    return this.http
      .put<OrderApiModel>(`/cinema/${cinemaId}/order/${order.id}`, instanceToPlain(order))
      .pipe(tap((res) => OrderHttpService.cacheModify(res.id, res)));
  }

  silentDelete(cinemaId: string, orderId: string, token: string | null = null): boolean {
    let apiUrl: string = this.environment.apiUrl;

    if (apiUrl.endsWith('/') === false) {
      apiUrl = apiUrl + '/';
    }

    const endpoint = `cinema/${cinemaId}/order/${orderId}/delete`;

    return navigator.sendBeacon(apiUrl + endpoint);
  }

  public updateAgreement(cinemaId: string, orderId: string, agreements: string[]) {
    return this.http.put(`/cinema/${cinemaId}/order/${orderId}/agreements`, agreements);
  }

  public delete(cinemaId: string, orderId: string) {
    return this.http.delete(`/cinema/${cinemaId}/order/${orderId}`).pipe(tap(() => OrderHttpService.cacheBuster$.next()));
  }

  public closeBasket(cinemaId: string, orderId: string, paymentConfirmed: boolean) {
    return this.http
      .post(`/cinema/${cinemaId}/order/${orderId}/close`, { paymentConfirmed: paymentConfirmed })
      .pipe(tap(() => OrderHttpService.cacheBuster$.next()));
  }

  public membershipUpdate(cinemaId: string, orderId: string, request: OrderMembershipUpdateRequestModel) {
    return this.http
      .patch(`/cinema/${cinemaId}/order/${orderId}/membershipUpdate`, instanceToPlain(request))
      .pipe(tap(() => OrderHttpService.cacheBuster$.next()));
  }

  public postPayment(orderPaymentRequest: OrderPaymentRequestModel): Observable<PaymentApiModel> {
    const body = new CreatePaymentRequestModel({
      channel: orderPaymentRequest.paymentChannel,
      continueUrl: orderPaymentRequest.continueUrl,
      intPayMethodType: orderPaymentRequest.intPayMethodType,
      intPayMethodValue: orderPaymentRequest.intPayMethodValue,
      saveToken: orderPaymentRequest.saveToken,
      paymentToken: orderPaymentRequest.paymentToken,
      expiry: orderPaymentRequest.expiry,
      regulationAccept: true,
    });

    return this.http.post<PaymentApiModel>(
      `/cinema/${orderPaymentRequest.cinemaId}/order/${orderPaymentRequest.orderId}/payment/${orderPaymentRequest.paymentProviderIdentifier}`,
      body,
      {
        headers: orderPaymentRequest.recaptchaResponse ? { 'X-Recaptcha-Response': orderPaymentRequest.recaptchaResponse } : {},
      }
    );
  }

  public getPaymentMethodList(cinemaId: string, orderId: string) {
    return this.http
      .get<Array<PaymentMethodApiModel>>(`/cinema/${cinemaId}/order/${orderId}/payment/method`)
      .pipe(map((res) => plainToInstance(PaymentMethodApiModel, res as Object[], { strategy: 'excludeAll' })));
  }

  public getVerify(cinemaId: string, orderId: string) {
    return this.http.get(`/cinema/${cinemaId}/order/${orderId}/verify`);
  }

  public getPaymentProviderConfig(request: PaymentProviderConfigRequestModel): Observable<PaymentConfigApiModel> {
    const options: any = {
      params: {},
    };

    if (request.paymentChannel !== null) {
      options.params.channel = request.paymentChannel;
    }

    return this.http
      .get<PaymentConfigApiModel>(`/cinema/${request.cinemaId}/order/${request.orderId}/payment/${request.paymentProviderIdentifier}/config`, options)
      .pipe(map((res) => plainToInstance(PaymentConfigApiModel, res as Object, { strategy: 'excludeAll' })));
  }

  @Cacheable({ maxAge: 3600000 })
  public getPaymentProviderPayMethodCollection(request: PaymentProviderPayMethodRequestModel): Observable<Array<PaymentProviderPayMethodApiModel>> {
    return this.http
      .get<Array<PaymentProviderPayMethodApiModel>>(`/cinema/${request.cinemaId}/order/${request.orderId}/payment/${request.paymentProvider}/methods`, {
        params: {
          channel: request.paymentChannel,
        },
      })
      .pipe(map((res) => plainToInstance(PaymentProviderPayMethodApiModel, res as Object[], { strategy: 'excludeAll' })));
  }

  public removeExternalPaymentMethod(request: PaymentProviderPayMethodRequestModel): Observable<any> {
    return this.http.delete(`/cinema/${request.cinemaId}/order/${request.orderId}/payment/${request.paymentProvider}`, {
      params: {
        channel: request.paymentChannel,
      },
    });
  }

  public getVirtualPass(
    cinemaId: string,
    salesDocumentId: string,
    virtualPassProvider: string,
    screeningId: string | undefined,
    mode: 'reservation' | 'ticket' | 'alltickets' | undefined
  ): Observable<VirtualPassApiModel[]> {
    const url = `/cinema/${cinemaId}/salesDocument/${salesDocumentId}/provider/${virtualPassProvider}`;

    let params = new HttpParams();
    if (mode) {
      params = params.set('mode', mode);
    }
    if (screeningId) {
      params = params.set('screeningId', screeningId);
    }

    return this.http.get<VirtualPassApiModel[]>(url, { params });
  }

  @Cacheable({ maxAge: 3600000 })
  public getCatering(cinemaId: string, orderId: string = null, screenGroupId: string = null): Observable<CateringApiModel> {
    let params = new HttpParams();

    if (screenGroupId !== null) {
      params = params.append('screenGroupId', screenGroupId);
    }

    const path = orderId ? `/cinema/${cinemaId}/order/${orderId}/fb` : `/cinema/${cinemaId}/fb`;

    return this.http.get<CateringApiModel>(path, { params: params });
  }

  @Cacheable({ maxAge: 3600000 })
  public getPickupTime(cinemaId: string): Observable<PickupTimeApiModel[]> {
    return this.http.get<PickupTimeApiModel[]>(`/cinema/${cinemaId}/fb/pickupTime`);
  }

  public patchPickupTime(pickupTimeId: string, orderId: string, cinemaId: string): Observable<boolean> {
    return this.http.patch<OrderApiModel>(`/cinema/${cinemaId}/order/${orderId}/fb/pickupTime/${pickupTimeId}`, null).pipe(
      tap(() => OrderHttpService.cacheBuster$.next()),
      map(() => true)
    );
  }

  public putPickupTime(cinemaId: string, orderId: string, pickupTime: DateTime) {
    return this.http.put(`/cinema/${cinemaId}/order/${orderId}/fb/pickupTime`, {
      pickupTime: `${pickupTime.toFormat('yyyy-LL-dd')}\T${pickupTime.toFormat('HH:mm:ss')}`,
    });
  }

  public static cacheModify(key: string, responseData: Object): void {
    OrderHttpService.cacheModifier$.next((data: any[]) => {
      const oldCacheRow = data.find((p) => p.parameters[1] === key);

      if (!oldCacheRow) {
        return;
      }

      Object.assign(oldCacheRow.response, {
        ...responseData,
      });

      return data;
    });
  }

  public removeItem(cinemaId: string, orderId: string, itemId: string): Observable<OrderApiModel> {
    return this.http.delete<OrderApiModel>(`/cinema/${cinemaId}/order/${orderId}/item/${itemId}`).pipe(
      tap((res) => OrderHttpService.cacheModify(res.id, res)),
      map((res) => plainToInstance(OrderApiModel, res as Object, { strategy: 'excludeAll' }))
    );
  }

  addVoucherToOrder(cinemaId: string, orderId: string, itemId: string, quantity: number): Observable<OrderApiModel> {
    return this.http.patch<OrderApiModel>(`/cinema/${cinemaId}/order/${orderId}/voucheritem`, instanceToPlain([{ itemId: itemId, quantity: quantity }]));
  }

  public putTable(cinemaId: string, orderId: string, tableId: string): Observable<TableApiModel> {
    return this.http.put<TableApiModel>(`/cinema/${cinemaId}/order/${orderId}/table/${tableId}`, null);
  }

  public patchOrderFbItemSeat(cinemaId: string, orderId: string, body: SeatRequestRequestModel[]) {
    return this.http.patch(`/cinema/${cinemaId}/order/${orderId}/fbitem/seat`, body);
  }

  @Cacheable({ maxAge: 3600000 })
  public getFbNutritionalInfo(cinemaId: string) {
    return this.http.get<NutritionalInfoModel[]>(`/cinema/${cinemaId}/fb/nutritionalinfo`);
  }

  @Cacheable({ maxAge: 3600000 })
  public getOrderFb(cinemaId: string, orderId: string = null, screenGroupId: string = null): Observable<CateringResponseModel> {
    let params = new HttpParams();
    //params = params.append('forceReload', 'true');

    if (screenGroupId !== null) {
      params = params.append('screenGroupId', screenGroupId);
    }

    const path = orderId ? `/cinema/${cinemaId}/order/${orderId}/fb` : `/cinema/${cinemaId}/fb`;

    return this.http.get<CateringResponseModel>(path, { params: params });
  }

  public getApplePaySession(cinemaId: string, orderId: string, paymentProviderIdentifier: string): Observable<any> {
    return this.http.get<any>(`/cinema/${cinemaId}/order/${orderId}/payment/${paymentProviderIdentifier}/ApplePaySession`);
  }
}
