import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'apps/oneil/src/environments/environment';
import { storageKey } from 'libs/core/src/app.const';
import { ChoiceSliderItem } from 'libs/core/src/lib/component/choice-slider/model/choice-slider-item';
import { CardDataProvider } from 'libs/core/src/lib/data-provider/card.data-provider';
import { LoaderEnum } from 'libs/core/src/lib/enum/loader.enum';
import { CardTypeViewModel } from 'libs/core/src/lib/model/view-model/card/card-type.view.model';
import { LoadingService } from 'libs/core/src/lib/service/loading.service';
import { OrderStateService } from 'libs/core/src/lib/state/order.state.service';
import { isNullOrEmpty } from 'libs/core/src/lib/function/custom-function';
import { cloneDeep } from 'lodash-es';
import { CinemaViewModel } from '@lib/core';
import { CinemaDataProvider } from 'libs/core/src/lib/data-provider/cinema.data-provider';

@Component({
  selector: 'app-gift-cards-page',
  templateUrl: './gift-cards-page.component.html',
})
export class OneilGiftCardsPageComponent implements OnInit {
  onlineGraphicItems: Map<string, Array<ChoiceSliderItem>> = new Map<string, Array<ChoiceSliderItem>>();
  physicalGraphicItems: Map<string, Array<ChoiceSliderItem>> = new Map<string, Array<ChoiceSliderItem>>();

  cards: CardTypeViewModel[] = [];
  onlineCards: CardTypeViewModel[] = [];
  physicalCards: CardTypeViewModel[] = [];

  noCinemaContext = false;

  cinemaList: CinemaViewModel[] = null;

  constructor(
    protected loadingService: LoadingService,
    protected orderStateService: OrderStateService,
    protected cardDataProvider: CardDataProvider,
    protected route: ActivatedRoute,
    protected cinemaDataProvider: CinemaDataProvider
  ) {}

  ngOnInit() {
    let cinemaId =
      this.route.snapshot.queryParamMap.get('cinemaId') ||
      this.orderStateService.getCinemaId() ||
      this.orderStateService.getItem(storageKey.chosenLocation) ||
      environment.constants?.defaultCinemaId;
    if (cinemaId) {
      this.initialPage(cinemaId);
    } else {
      this.displayCinemaSelector();
    }
  }

  checkCanDisplayInTabs() {
    return [this.onlineCards, this.physicalCards].filter((f) => !isNullOrEmpty(f)).length > 1;
  }

  onCinemaChange(cinema: CinemaViewModel) {
    this.showLoader();
    this.noCinemaContext = false;
    this.initialPage(cinema.id);
  }

  private initialPage(cinemaId: string) {
    this.cardDataProvider.types(cinemaId).subscribe((cards) => {
      this.onlineGraphicItems.clear();
      this.physicalGraphicItems.clear();

      this.cards = cards
        .filter((x) => x.types.find((type) => type.number === 32) && (x.batchList = x.batchList.filter((y) => y.canBeIssued)))
        .filter((x) => x.batchList.length > 0);

      for (const card of this.cards) {
        for (const batch of card.batchList) {
          const choiceSliderItem = new ChoiceSliderItem();
          choiceSliderItem.graphic = batch.graphic;
          choiceSliderItem.key = batch.id;
          choiceSliderItem.unlimited = batch.unlimited;

          batch.unlimited
            ? this.onlineGraphicItems.has(card.id)
              ? this.onlineGraphicItems.get(card.id).push(choiceSliderItem)
              : this.onlineGraphicItems.set(card.id, [choiceSliderItem])
            : this.physicalGraphicItems.has(card.id)
            ? this.physicalGraphicItems.get(card.id).push(choiceSliderItem)
            : this.physicalGraphicItems.set(card.id, [choiceSliderItem]);
        }
      }

      this.physicalCards = cloneDeep(this.cards)
        .map((x) => {
          x.batchList = x.batchList.filter((y) => y.unlimited === false && y.canBeIssued);
          return x;
        })
        .filter((z) => z.batchList.length > 0);

      this.onlineCards = cloneDeep(this.cards)
        .map((x) => {
          x.batchList = x.batchList.filter((y) => y.unlimited === true && y.canBeIssued);
          return x;
        })
        .filter((z) => z.batchList.length > 0);

      this.hideLoader();
    });
  }

  private displayCinemaSelector() {
    this.noCinemaContext = true;
    this.cinemaDataProvider.getCinemas().subscribe((cinemaList: CinemaViewModel[]) => {
      this.cinemaList = cinemaList;
      this.hideLoader();
    });
  }

  private showLoader() {
    this.loadingService.showLoader(LoaderEnum.MAIN);
  }

  private hideLoader() {
    this.loadingService.hideLoader(LoaderEnum.MAIN);
  }
}
