import { Component } from '@angular/core';
import { LoaderEnum } from 'libs/core/src/lib/enum/loader.enum';
import { LoadingService } from 'libs/core/src/lib/service/loading.service';
import { VoucherService } from 'libs/core/src/lib/service/voucher.service';
import { OrderStateService } from 'libs/core/src/lib/state/order.state.service';
import { OneilLoyaltyInputComponent } from '../../loyalty-input/loyalty-input.component';
import { ErrorHandlerService } from 'libs/core/src/lib/service/error-handler/error-handler.service';

@Component({
  selector: 'app-voucher-input',
  templateUrl: './voucher-input.component.html',
})
export class OneilVoucherInputComponent extends OneilLoyaltyInputComponent {
  constructor(
    protected loadingService: LoadingService,
    protected orderStateService: OrderStateService,
    protected voucherService: VoucherService,
    private errorHandlerService: ErrorHandlerService
  ) {
    super(loadingService, orderStateService);
  }

  submit() {
    if (!this.value || !this.order) {
      return;
    }

    if (this.onSubmit.observed) {
      this.onSubmit.emit(this.value);
      return;
    }

    this.errors = [];
    this.loadingService.showLoader(LoaderEnum.MAIN);

    this.voucherService.assignVoucherToOrderViaApiModel(this.order.cinemaId, this.order.id, this.value).subscribe({
      next: (res) => {
        this.orderStateService.setOrder(res);
        this.clear();
        this.loadingService.hideLoader(LoaderEnum.MAIN);
      },
      error: (e) => {
        const error = this.errorHandlerService.getError(e);
        if (error) {
          switch (error.code) {
            case '460':
              this.errors = ['voucher_not_found'];
              break;
            case '461':
              this.errors = ['voucher_already_used'];
              break;
          }
        }

        if (!this.errors || this.errors?.length === 0) {
          this.errors = ['cannot_use'];
        }
        this.loadingService.hideLoader(LoaderEnum.MAIN);
      },
    });
  }
}
