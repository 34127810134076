import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, filter, finalize, map, take, tap } from 'rxjs';
import { MyAccountOption } from '../model/cms/my-account-option';
import { MyAccountPageEnum } from '../enum/my-account-page.enum';
import { KeyValue } from '../helper/key-value';
import { WordpressHttpService } from '../http/wordpress.http.service';
import { myAccountOptionsConsts } from '../../app.const';
import { CmsHelper } from '../helper/cms.helper';

@Injectable({
  providedIn: 'root',
})
export class WebComponentOptionsService {
  public defaultOption = myAccountOptionsConsts.defaultOption;
  public availableOptions: MyAccountOption[] = myAccountOptionsConsts.availableOptions;

  private options: BehaviorSubject<MyAccountPageEnum[]> = new BehaviorSubject<MyAccountPageEnum[]>(null);
  public options$: Observable<MyAccountPageEnum[]>;

  private menus: BehaviorSubject<MyAccountPageEnum[]> = new BehaviorSubject<MyAccountPageEnum[]>(null);
  public menus$: Observable<MyAccountPageEnum[]>;

  public wpConfig: KeyValue<string>[] = [];
  private initialDataFetched$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private wordpressHttpService: WordpressHttpService, cmsHelper: CmsHelper) {
    if (!cmsHelper.canUseCms) {
      return;
    }

    this.options$ = this.options.asObservable();
    this.menus$ = this.menus.asObservable();
    this.wordpressHttpService
      .getConfig()
      .pipe(
        tap((config) => {
          this.wpConfig = config;
          this.availableOptions.forEach((option) => {
            option.useInMenu = this.getWpOption(option.page) == 1;
          });
          this.options.next(this.availableOptions.map((opt) => opt.page));
          this.menus.next(
            this.availableOptions
              .filter((o) => o.useInMenu)
              .sort((a, b) => a.order - b.order)
              .map((opt) => opt.page)
          );
        }),
        finalize(() => this.initialDataFetched$.next(true))
      )
      .subscribe();
  }

  initialDataFetched() {
    return this.initialDataFetched$.asObservable().pipe(
      filter((v) => !!v),
      take(1)
    );
  }

  public getWpOption$(key: string, defaultValue?: string, asFloat: boolean = false): Observable<string | number> {
    return this.initialDataFetched().pipe(map(() => this.getWpOption(key, defaultValue, asFloat)));
  }

  public getWpOption(key: string, defaultValue?: string, asFloat: boolean = false): string | number {
    const value = this.wpConfig.find((x) => x.key === key);

    if (value) {
      if (asFloat) {
        return Number.parseFloat(value.value) || Number.parseFloat(defaultValue);
      }

      return value.value.toLocaleString();
    }

    return value ? value.value : defaultValue;
  }
}
