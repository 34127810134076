import { DateTime } from 'luxon';
import { TransferDirection } from './enum/transfer-direction.enum';
import { CinemaViewModel } from './view-model/cinema/cinema.view.model';
import { RegionViewModel } from './view-model/region/region.view.model';
import { UserEventViewModel } from './view-model/user-history/event/user-event.view.model';
import { UserMoviePrintViewModel } from './view-model/user-history/movie-print/user-movie-print.view.model';
import { UserMovieViewModel } from './view-model/user-history/movie/user-movie.view.model';
import { UserScreenViewModel } from './view-model/user-history/screen/user-screen.view.model';
import { UserScreeningViewModel } from './view-model/user-history/screening/user-screening.view.model';
import { UserTicketViewModel } from './view-model/user-history/ticket/user-ticket.view.model';
import { getBooleanValue } from '@lib/core';

export class ScreeningWithTickets {
  public tickets: UserTicketViewModel[] = [];
  public screening: UserScreeningViewModel;
  public movie: UserMovieViewModel;
  public moviePrint: UserMoviePrintViewModel;
  public event: UserEventViewModel;
  public region: RegionViewModel;
  public cinema: CinemaViewModel;
  public screen: UserScreenViewModel;

  public orderId: string;

  private _bookingId: string;
  private _reservationId: string;

  public isEvent(): boolean {
    return this.event && !this.screening && !this.movie;
  }

  public isReservation(): boolean {
    return getBooleanValue(this.reservationId);
  }

  public hasIncomingTickets(): boolean {
    return this.tickets.some((o) => o.transferred === TransferDirection.IN);
  }

  public hasOutgoingTickets(): boolean {
    return this.tickets.some((o) => o.transferred === TransferDirection.OUT);
  }

  public hasOnlyIncomingTickets(): boolean {
    return this.tickets.every((o) => o.transferred === TransferDirection.IN);
  }

  public getPoster(): string {
    const posters = this.isEvent() ? this.event.posters : this.movie.posters;
    return posters?.length ? posters[0] : null;
  }

  get screeningTimeTo(): DateTime {
    return this.isEvent() ? this.event.eventTimeTo : this.screening.screeningTimeTo;
  }

  get screeningTimeFrom(): DateTime {
    return this.isEvent() ? this.event?.eventTimeFrom : this.screening?.screeningTimeFrom;
  }

  get screeningId() {
    return this.isEvent() ? this.event?.screeningId : this.screening?.id;
  }

  set reservationId(value: string) {
    this._reservationId = value;
  }

  get reservationId() {
    return this._reservationId ? this._reservationId : this.tickets?.length && !this.orderId ? this.tickets[0].reservationId : undefined;
  }

  set bookingId(value: string) {
    this._bookingId = value;
  }

  get bookingId() {
    return this._bookingId ? this._bookingId : this.tickets?.length ? this.tickets[0].bookingId : undefined;
  }
}
