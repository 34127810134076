import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BasketPageModel } from 'libs/core/src/lib/model/page/basket/basket.page.model';

@Component({
  template: '',
})
export class PersonalInformationComponent {
  @Input() basketPageModel: BasketPageModel;
  @Input() personalForm: FormGroup;
  @Input() formErrors: string[];
  @Input() formSubmitAttempt: boolean;
  @Input() isLogged: boolean;

  @Input() disabledTaxId: boolean;
  @Input() taxInformation: string;

  constructor() {}
}
