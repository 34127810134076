import { Expose } from 'class-transformer';

export class CardActiveDiscountApiModel {
  @Expose()
  name: string;

  @Expose()
  active: boolean;

  @Expose()
  registrationRequired: boolean;
}
