export class PaymentProviderEvent {
  constructor() {}
}

export class DonePaymentProviderEvent extends PaymentProviderEvent {}

export class ErrorPaymentProviderEvent extends PaymentProviderEvent {
  public originError: Error = null;

  constructor(originError: Error) {
    super();
    this.originError = originError;
  }
}

export class WaitPaymentProviderEvent extends PaymentProviderEvent {
  public seconds: number;

  constructor(seconds: number) {
    super();
    this.seconds = seconds;
  }
}

export class WorkPaymentProviderEvent extends PaymentProviderEvent {}

export class ErrorPaymentKeyProviderEvent extends PaymentProviderEvent {
  constructor(public key: string) {
    super();
  }
}
