<div class="container">
  <div class="main-container">

    <div class="col-12 error-message-box p-3 mt-4 text-center text-danger" [hidden]="errorCode === null">
      {{"errors." + errorCode | translate}}
    </div>

    <ng-container *ngIf="displayMode === 'register'">
      <ng-container *ngIf="registerForm">
        <ng-container [ngSwitch]="state">
          <ng-container *ngSwitchCase="stateEnum.Initial">
            <form [formGroup]="registerForm" (submit)="submitForm('registerForm')" id="register-form"
                  (reset)="resetForm('registerForm')">
              <div class="row mt-3 justify-content-center">
                <div class="col-12 col-md-6">
                  <app-sidebar>
                    <div sidebar-title>
                      {{'personal.register.title' | translate | uppercase }}
                    </div>
                    <div sidebar-body>
                      <div class="form-group required">
                        <div class="d-flex align-items-center">
                          <label for="firstName"
                                 class="form-control-description m-0">{{ "personal.register.firstName" | translate }}</label>
                          <div class="col-9 pr-0 ml-auto required">
                            <input type="text" formControlName="firstName" letterOnly trim autocomplete="disabled"
                                   class="w-100 form-control" placeholder="John" [attr.tabindex]="1">
                          </div>
                        </div>
                        <div class="help-block with-errors col-12 text-right"
                             *ngIf="fieldIsInvalid('firstName', 'registerForm')">
                          <p *ngIf="registerForm.get('firstName')?.errors?.required">
                            {{'errors.10001' | translate }}
                          </p>
                        </div>
                      </div>

                      <div class="form-group required">
                        <div class="d-flex align-items-center">
                          <label for="lastName"
                                 class="form-control-description m-0">{{ "personal.register.lastName" | translate }}</label>
                          <div class="col-9 pr-0 ml-auto required">
                            <input type="text" formControlName="lastName" letterOnly trim autocomplete="disabled"
                                   class="w-100 form-control" placeholder="Smith" [attr.tabindex]="2">
                          </div>
                        </div>
                        <div class="help-block with-errors col-12 text-right"
                             *ngIf="fieldIsInvalid('lastName', 'registerForm')">
                          <p *ngIf="registerForm.get('lastName')?.errors?.required">
                            {{'errors.10002' | translate }}
                          </p>
                        </div>
                      </div>

                      <div class="form-group required">
                        <div class="d-flex align-items-center">
                          <label for="email"
                                 class="form-control-description m-0">{{ "personal.register.email" | translate }}</label>
                          <div class="col-9 pr-0 ml-auto required">
                            <input type="text" formControlName="email" trim autocomplete="disabled"
                                   class="w-100 form-control" placeholder="example@example.com" [attr.tabindex]="3">
                          </div>
                        </div>
                        <div class="help-block with-errors col-12 text-right"
                             *ngIf="fieldIsInvalid('email', 'registerForm')">
                          <p *ngIf="registerForm.get('email')?.errors?.required">
                            {{'errors.10005' | translate }}
                          </p>
                          <p *ngIf="registerForm.get('email')?.errors?.pattern">
                            {{'errors.10102' | translate }}
                          </p>
                        </div>
                      </div>

                      <div class="form-group required">
                        <div class="d-flex align-items-center">
                          <label for="password"
                                 class="form-control-description m-0">{{ "personal.register.password" | translate }}</label>
                          <div class="col-9 pr-0 ml-auto required">
                            <input type="password" formControlName="password" maxlength="100"
                                   autocomplete="new-password" class="w-100 form-control" [attr.tabindex]="4">
                            <span class="check-icon" [hidden]="!passwordIsValid('registerForm')">
                              <i class="text-primary pc-icon-check-mark"></i>
                            </span>
                          </div>
                        </div>
                        <div class="help-block with-errors col-9 text-right ml-auto mr-0"
                             *ngIf="fieldIsInvalid('password', 'registerForm')">
                          <div appFormErrors="password" [errors]="registerFormErrors"></div>
                          <p *ngIf="registerForm.get('password')?.errors?.required">
                            {{'errors.10009' | translate }}
                          </p>
                          <p *ngIf="registerForm.get('password')?.errors?.pattern">
                            {{'errors.pattern.password' | translate }}
                          </p>
                          <p *ngIf="registerForm.get('password')?.errors?.invalid">
                            {{'errors.10017' | translate }}
                          </p>
                        </div>
                      </div>

                      <div class="form-group required">
                        <div class="d-flex align-items-center">
                          <label for="repeatPassword"
                                 class="form-control-description m-0">{{ "personal.register.repeatPassword" | translate }}</label>
                          <div class="col-9 pr-0 ml-auto required">
                            <input type="password" formControlName="repeatPassword" maxlength="100"
                                   autocomplete="disabled" class="w-100 form-control" [attr.tabindex]="5">
                            <span class="check-icon"
                                  [hidden]="registerForm.get('repeatPassword')?.errors?.notMatch || !registerForm.get('repeatPassword').value || registerForm.get('repeatPassword').value.length === 0">
                              <i class="text-primary pc-icon-check-mark"></i>
                            </span>
                          </div>
                        </div>
                        <div class="help-block with-errors col-12 text-right"
                             *ngIf="fieldIsInvalid('repeatPassword', 'registerForm')">
                          <p *ngIf="registerForm.get('repeatPassword')?.errors?.required">
                            {{'errors.10009' | translate }}
                          </p>
                          <p *ngIf="registerForm.get('repeatPassword')?.errors?.notMatch">
                            {{'errors.10006' | translate }}
                          </p>
                        </div>
                      </div>

                      <div class="form-group">
                        <div class="d-flex align-items-center">
                          <label for="birthday"
                                 class="form-control-description pl-0">{{ "user.user-my-profile.form.birthday" | translate }}</label>
                          <div class="col-9 pr-0 ml-auto">
                            <app-date formControlName="birthday" [disabledFutureDate]="true" [tabStart]="6"></app-date>
                          </div>
                        </div>
                        <div class="help-block with-errors col-12 text-right"
                             *ngIf="(registerForm.get('birthday').touched || registerForm.touched) && registerForm.get('birthday').invalid">
                          <p *ngIf="registerForm.get('birthday')?.errors?.invalidDate">
                            {{'errors.10105' | translate }}
                          </p>
                        </div>
                      </div>

                      <div class="col-12 pt-2" formArrayName="agreements"
                           *ngFor="let agreement of agreements; let i = index; first as isFirst"
                           [ngClass]="{'error': agreement.required && !agreement.valid, 'pt-3': isFirst}">
                        <div class="checkbox">
                          <label class="mb-0" for="{{ 'register_agreement_' + i }}">
                            <input type="checkbox" id="{{ 'register_agreement_' + i }}" [formControlName]="i"
                                   (click)="onAgreementClick(agreement, i)" [attr.tabindex]="9 + i">
                            <span class="cr"><i class="cr-icon fa fa-check"></i></span>
                            <span [innerHTML]="agreement.contents"></span><span
                                  *ngIf="agreement.required">&nbsp;*</span>
                          </label>
                        </div>
                        <div class="col-12" *ngIf="agreement.required && !agreement.valid">
                          <p>{{'errors.10021' | translate }}</p>
                        </div>
                      </div>
                    </div>
                    <div sidebar-footer>
                      <div class="d-flex p-3">
                        <button class="btn btn-default"
                                (click)="navigationService.back()">{{'personal.button.back' | translate }}</button>
                        <button type="submit"
                                class="btn btn-next ml-auto">{{'personal.button.registerFormSubmit' | translate }}</button>
                      </div>
                    </div>
                  </app-sidebar>
                </div>
              </div>
            </form>
          </ng-container>
          <ng-container *ngSwitchDefault>
            <div class="d-flex pt-3 justify-content-center"
                 *ngVar="(state===stateEnum.Success ? 'positive' : 'negative') let result">
              <div class="pr-0 text-white">
                <h3 class="font-weight-bold text-center">
                  {{'personal.register.finalState.' + result + '.title' | translate }}
                </h3>
                <h7>{{'personal.register.finalState.' + result + '.content' | translate }}</h7>
              </div>
            </div>
            <div class="row py-3 justify-content-center">
              <div class="d-flex">
                <ng-container [ngSwitch]="state">
                  <button class="btn btn-next ml-auto" *ngSwitchCase=stateEnum.Success
                          (click)="tryLoginAndGoForward()">{{'personal.button.confirm' | translate }}</button>
                  <button class="btn btn-next ml-auto" *ngSwitchDefault
                          (click)="stateBack()">{{'personal.button.back' | translate }}</button>
                </ng-container>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="displayMode === 'confirmRegistration' && state !== stateEnum.Initial">
      <div class="d-flex pt-3 justify-content-center"
           *ngVar="(state===stateEnum.Success ? 'positive' : 'negative') let result">
        <div class="pr-0 text-white text-center">
          <h3 class="font-weight-bold"> {{'personal.confirmRegistration.finalState.' + result + '.title' | translate }}
          </h3>
          <h7>{{'personal.confirmRegistration.finalState.' + result + '.content' | translate }}</h7>
        </div>
      </div>
      <div class="row py-3 justify-content-center">
        <div class="d-flex">
          <button class="btn btn-next ml-auto"
                  (click)="navigationService.goToHomePage()">{{'personal.button.goToMainPage' | translate }}</button>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="displayMode === 'resetPassword'">
      <ng-container [ngSwitch]="state">
        <ng-container *ngSwitchCase="stateEnum.Initial">
          <ng-container *ngIf="resetPasswordForm">
            <form [formGroup]="resetPasswordForm" (submit)="submitForm('resetPasswordForm')" id="resetPassword-form"
                  (reset)="resetForm('resetPasswordForm')">
              <div class="row mt-3 justify-content-center">
                <div class="col-12 col-md-6">
                  <app-sidebar>
                    <div sidebar-title>
                      {{'personal.resetPassword.title' | translate | uppercase }}
                    </div>
                    <div sidebar-body>
                      <h7>{{'personal.resetPassword.subtitle' | translate }}</h7>
                      <div class="form-group required">
                        <div class="d-flex align-items-center">
                          <label for="email"
                                 class="col-form-label pl-0">{{ "personal.resetPassword.email" | translate }}</label>
                          <div class="col-9 pr-0 ml-auto required">
                            <input type="text" formControlName="email" trim autocomplete="disabled"
                                   class="w-100 form-control">
                          </div>
                        </div>
                        <div class="help-block with-errors col-12 text-right"
                             *ngIf="fieldIsInvalid('email', 'resetPasswordForm')">
                          <p *ngIf="resetPasswordForm.get('email')?.errors?.required">
                            {{'errors.10005' | translate }}
                          </p>
                          <p *ngIf="resetPasswordForm.get('email')?.errors?.pattern">
                            {{'errors.10102' | translate }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div sidebar-footer>
                      <div class="d-flex p-3">
                        <button class="btn btn-default"
                                (click)="navigationService.back()">{{'personal.button.back' | translate }}</button>
                        <button type="submit" [disabled]="errorCode"
                                class="btn btn-next ml-auto">{{'personal.button.resetPasswordFormSubmit' | translate }}</button>
                      </div>
                    </div>
                  </app-sidebar>
                </div>
              </div>
            </form>
          </ng-container>
        </ng-container>
        <ng-container *ngSwitchDefault>
          <div class="d-flex pt-3 justify-content-center"
               *ngVar="(state===stateEnum.Success ? 'positive' : 'negative') let result">
            <div class="pr-0 text-white text-center">
              <h3 class="font-weight-bold"> {{'personal.resetPassword.finalState.' + result + '.title' | translate }}
              </h3>
              <h7>{{'personal.resetPassword.finalState.' + result + '.content' | translate }}</h7>
            </div>
          </div>
          <div class="row py-3 justify-content-center">
            <div class="d-flex">
              <button class="btn btn-next ml-auto"
                      (click)="navigationService.goToHomePage()">{{'personal.button.goToMainPage' | translate }}</button>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="displayMode === 'setPassword'">
      <ng-container [ngSwitch]="state">
        <ng-container *ngSwitchCase="stateEnum.Initial">
          <form [formGroup]="setPasswordForm" (submit)="submitForm('setPasswordForm')" id="setPassword-form"
                (reset)="resetForm('setPasswordForm')">
            <div class="row mt-3 justify-content-center">
              <div class="col-12 col-md-6">
                <app-sidebar>
                  <div sidebar-title>
                    {{'personal.setPassword.title' | translate | uppercase }}
                  </div>
                  <div sidebar-body>
                    <div class="form-group required">
                      <div class="d-flex align-items-center">
                        <label for="password"
                               class="col-form-label pl-0">{{'personal.setPassword.password' | translate }}</label>
                        <div class="col-9 pr-0 ml-auto required">
                          <input type="hidden" formControlName="token">
                          <input type="password" formControlName="password" maxlength="100" class="w-100 form-control">
                          <span class="check-icon" [hidden]="!passwordIsValid('setPasswordForm')">
                            <i class="text-primary pc-icon-check-mark"></i>
                          </span>
                          <span class="d-none d-sm-inline tooltip-icon"
                                tooltip="{{'personal.register.passwordTooltip' | translate }}" placement="left">
                            <i class="pc-icon-information-circle"></i>
                          </span>
                        </div>
                      </div>
                      <div class="help-block with-errors col-12 text-right"
                           *ngIf="fieldIsInvalid('password', 'setPasswordForm')">
                        <p *ngIf="setPasswordForm.get('password')?.errors?.required">
                          {{'errors.10009' | translate }}
                        </p>
                        <p *ngIf="setPasswordForm.get('password')?.errors?.pattern">
                          {{'errors.pattern.password' | translate }}
                        </p>
                      </div>
                    </div>
                    <div class="d-flex align-items-center">
                      <label for="repassword"
                             class="col-form-label pl-0">{{'personal.setPassword.repassword' | translate }}</label>
                      <div class="col-9 pr-0 ml-auto required">
                        <input type="password" formControlName="repassword" maxlength="100" class="w-100 form-control">
                        <span class="check-icon"
                              [hidden]="setPasswordForm.get('repassword')?.errors?.notMatch || !setPasswordForm.get('repassword').value || setPasswordForm.get('repassword').value.length === 0">
                          <i class="text-primary pc-icon-check-mark"></i>
                        </span>
                      </div>
                    </div>
                    <div class="help-block with-errors col-12 text-right"
                         *ngIf="fieldIsInvalid('repassword', 'setPasswordForm')">
                      <p *ngIf="setPasswordForm.get('repassword')?.errors?.required">
                        {{'errors.10009' | translate }}
                      </p>
                      <p *ngIf="setPasswordForm.get('repassword')?.errors?.notMatch">
                        {{'errors.10006' | translate }}
                      </p>
                    </div>
                  </div>
                  <div sidebar-footer>
                    <div class="d-flex p-3">
                      <button class="btn btn-default"
                              (click)="navigationService.back()">{{'personal.button.back' | translate }}</button>
                      <button type="submit" [disabled]="errorCode"
                              class="btn btn-next ml-auto">{{'personal.button.setPasswordFormSubmit' | translate }}</button>
                    </div>
                  </div>
                </app-sidebar>
              </div>
            </div>
          </form>
        </ng-container>
        <ng-container *ngSwitchDefault>
          <div class="d-flex pt-3 justify-content-center"
               *ngVar="(state === stateEnum.Success ? 'positive' : 'negative') let result">
            <div class="pr-0 text-white text-center">
              <h3 class="font-weight-bold"> {{'personal.setPassword.finalState.' + result + '.title' | translate }}
              </h3>
              <h7>{{'personal.setPassword.finalState.' + result + '.content' | translate }}</h7>
            </div>
          </div>
          <div class="row py-3 justify-content-center">
            <div class="d-flex">
              <button class="btn btn-next ml-auto"
                      (click)="setPasswordButtonClick()">{{ state === stateEnum.Success ? ('personal.button.ok' | translate) : ('personal.button.ok' | translate) }}</button>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</div>