<div class="row pay-method-container d-block">

  <div class="col-12 px-0 px-sm-3 d-flex flex-column">

    <ng-container *ngIf="availablePaymentMethods.card">
      <div class="row d-block card-pay-method-container">
        <div class="col-12 pay-method-panel f-roboto">
          <app-payment-method [paymentProvider]="paymentProviderIdentifier" [paymentType]="paymentMethod.CREDIT_CARD"
                              [selected]="selectedPayMethodTypeCustom === paymentMethod.CREDIT_CARD"
                              [paymentImgUrl]="addTimeSign(availablePaymentMethods.card.imageUrl)"
                              [paymentMethod]="availablePaymentMethods.card">
            <div class="panel-body card-container d-flex flex-column gap-05 justify-content-center"
                 *ngIf="selectedPayMethodTypeCustom === paymentMethod.CREDIT_CARD || selectedPayMethodTypeCustom === paymentMethod.CREDIT_CARD_TOKEN">

              <ng-container *ngIf="availablePaymentMethods.cardToken?.length">
                <div class="d-flex flex-column gap-1 mt-3 ">
                  <ng-container *ngFor="let payMethod of availablePaymentMethods.cardToken; index as i">
                    <ng-container [ngTemplateOutlet]="clickableCardTokenTemplate"
                                  [ngTemplateOutletContext]="{ payMethod: payMethod, index: i }"></ng-container>
                  </ng-container>
                </div>
              </ng-container>

              <div class="form-group card-form-group mb-0" [class.hidden]="selectedTokenCardMethod"
                   [formGroup]="cardWithTokenizeFormGroup">
                <div id="creditcardbox" [class.ready]="creditCardFormIsLoaded">
                </div>
                <ng-container *ngIf="authStateService.isLogged()">
                  <div class="checkbox ml-3">
                    <label for="saveToken">
                      <input type="checkbox" class="form-checkbox form-radio" id="saveToken"
                             formControlName="saveToken" />
                      <span
                            [className]="cardWithTokenizeFormGroup.get('saveToken').value ? 'checkbox-active cr' : 'cr'">
                        <!-- <i class="cr-icon fa fa-check"></i> -->
                      </span>

                      <span class="agreement-text" [innerHTML]="'payment.provider.p24.remember' | translate"></span>
                    </label>
                  </div>
                </ng-container>

              </div>
            </div>

          </app-payment-method>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="availablePaymentMethods.blik">
      <div class="row d-block link-pay-method-container">
        <div class="col-12 pay-method-panel f-roboto">
          <app-payment-method [paymentProvider]="paymentProviderIdentifier" [paymentType]="paymentMethod.BLIK"
                              [selected]="selectedPayMethodTypeCustom === paymentMethod.BLIK"
                              [paymentImgUrl]="addTimeSign(availablePaymentMethods.blik.imageUrl)"
                              [paymentMethod]="availablePaymentMethods.blik"></app-payment-method>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="availablePaymentMethods.blikT6">
      <div class="row d-block blick-pay-method-container">
        <div class="col-12 pay-method-panel f-roboto">
          <app-payment-method [paymentProvider]="paymentProviderIdentifier" [paymentType]="paymentMethod.BLIK_T6"
                              [selected]="selectedPayMethodTypeCustom === paymentMethod.BLIK_T6"
                              [paymentImgUrl]="addTimeSign(availablePaymentMethods.blikT6.imageUrl)"
                              [paymentMethod]="availablePaymentMethods.blikT6">
            <small label
                   *ngIf="availablePaymentMethods.blikToken && (!selectedMethod || selectedMethod?.type === paymentMethod.BLIK_TOKEN)">{{ 'payment.provider.p24.blik-pay-direct' | translate }}
            </small>
            <div class="panel-body blik-t6-container pt-3 d-flex flex-column gap-05"
                 *ngIf="selectedPayMethodTypeCustom === paymentMethod.BLIK_T6 || selectedPayMethodTypeCustom === paymentMethod.BLIK_TOKEN">

              <ng-container *ngIf="availablePaymentMethods.blikToken">
                <button class="btn btn-next" (click)="onToggleBlikMethod()">
                  {{ 'payment.provider.p24.use-' + (selectedMethod.type === paymentMethod.BLIK_TOKEN ? 'with' : 'without') + '-code' | translate }}
                </button>
              </ng-container>

              <div class="form-group mb-0" [formGroup]="blikFormGroup"
                   [class.hidden]="selectedMethod.type === paymentMethod.BLIK_TOKEN">
                <div class="d-flex flex-column">
                  <div class="mb-0 required row">
                    <input type="text" class="form-control text-center" id="blik_t6" data-charset="_X_ X_X"
                           placeholder="___ ___" formControlName="blikCode" placeholder="KOD BLIK" matInput
                           mask="000000" />
                    <ng-container *ngIf="(blikFormGroup.touched && blikFormGroup.dirty) || formSubmitAttempt">
                      <span *ngIf="!blikFormGroup.get('blikCode').valid && !blikFormGroup.get('blikCode')?.errors?.minLength === true"
                            class="invalid-feedback d-inline-block">BLIK musi mieć 6 znaków</span>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </app-payment-method>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="availablePaymentMethods.pbl?.length">
      <div class="row d-block link-pay-method-container">
        <div class="col-12 pay-method-panel f-roboto">
          <app-payment-method [paymentProvider]="paymentProviderIdentifier" [paymentType]="paymentMethod.PBL"
                              [paymentImgUrl]="getDefaultPaymentImageUrl"
                              [selected]="selectedPayMethodTypeCustom === paymentMethod.PBL">
            <div class="panel-body" *ngIf="selectedPayMethodTypeCustom === paymentMethod.PBL">

              <div class="d-flex mt-3" [formGroup]="form">
                <div class="checkbox">
                  <label for="regulationAccept">
                    <input type="checkbox" class="form-checkbox form-radio" id="regulationAccept"
                           formControlName="regulationAccept" />
                    <span [className]="form.get('regulationAccept').value ? 'checkbox-active cr' : 'cr'">
                      <!-- <i class="cr-icon fa fa-check"></i> -->
                    </span>
                    <span class="agreement-text"
                          [innerHTML]="'payment.provider.p24.rules-statement' | translate"></span>
                  </label>
                </div>
              </div>

              <div class="pay-by-link-container mt-3">
                <ng-container *ngFor="let payMethod of availablePaymentMethods.pbl; index as i">

                  <ng-container [ngTemplateOutlet]="clickablePblTemplate"
                                [ngTemplateOutletContext]="{ payMethod: payMethod, index: i }"></ng-container>

                </ng-container>
              </div>
            </div>
          </app-payment-method>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="availablePaymentMethods.gpay">
      <div class="row d-block google-pay-method-container">
        <div class="col-12 pay-method-panel f-roboto">
          <app-payment-method [paymentType]="paymentMethod.GPAY" [paymentMethod]="availablePaymentMethods.gpay"
                              [paymentProvider]="paymentProviderIdentifier"
                              [selected]="selectedPayMethodTypeCustom === paymentMethod.GPAY"
                              [paymentImgUrl]="addTimeSign(availablePaymentMethods.gpay.imageUrl)">
            <div class="panel-body" *ngIf="selectedPayMethodTypeCustom === paymentMethod.GPAY">
              <div class="gpay-container">
              </div>
            </div>
          </app-payment-method>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="availablePaymentMethods.apay">
      <div class="row d-block apple-pay-method-container">
        <div class="col-12 pay-method-panel f-roboto">
          <app-payment-method [paymentType]="paymentMethod.APAY" [paymentMethod]="availablePaymentMethods.apay"
                              [paymentProvider]="paymentProviderIdentifier"
                              [selected]="selectedPayMethodTypeCustom === paymentMethod.APAY"
                              [paymentImgUrl]="addTimeSign(availablePaymentMethods.apay.imageUrl)">
            <div class="panel-body" *ngIf="selectedPayMethodTypeCustom === paymentMethod.APAY">
              <div class="apay-container">
              </div>
            </div>
          </app-payment-method>
        </div>
      </div>
    </ng-container>

  </div>
</div>

<ng-template #clickablePblTemplate let-payMethod="payMethod">
  <div class="pay-by-link" [class.selected]="selectedMethod === payMethod" (click)="onClickPaymentMethod(payMethod)">
    <ng-container *ngIf="payMethod.imageUrl">
      <img [src]="addTimeSign(payMethod.imageUrl)" />
    </ng-container>
  </div>
</ng-template>

<ng-template #clickableCardTokenTemplate let-payMethod="payMethod">
  <div class="card" [class.selected]="selectedTokenCardMethod === payMethod"
       (click)="onClickTokenCardMethod(payMethod)">
    <img [src]="payMethod.imageUrl" />
    <p>{{ payMethod.cardBrand }}</p>
    <p>{{ payMethod.cardMask }}</p>
    <p>{{ payMethod.cardExpirationMonth }}/{{ payMethod.cardExpirationYear }}</p>
  </div>
</ng-template>

<ng-template #clickableBlikTokenTemplate let-payMethod="payMethod">
  <div class="blik" [class.selected]="selectedMethod === payMethod" (click)="onClickPaymentMethod(payMethod)">
    <p>{{ payMethod.cardBrand }}</p>
  </div>
</ng-template>