import { Expose, Type } from 'class-transformer';
import { CardTypeBatchApiModel } from './card-type-batch.api.model';
import { CardTypeGraphicTemplateApiModel } from './card-type-graphic-template.api.model';
import { CardTypeMembershipExtensionFeeApiModel } from './card-type-membership-extension-fee.api.model';
import { CardTypeMembershipUpgradeFeeApiModel } from './card-type-membership-upgrade-fee.api.model';
import { CardTypeTypeApiModel } from './card-type-type.api.model';
import { CardTypeValueApiModel } from './card-type-value.api.model';
import { CardActiveDiscountApiModel } from './card-active-discount.api.model';
import { CardFieldTemplateApiModel } from './card-field-template.api.model';
import { CardTypeMembershipDowngradeFeeApiModel } from './card-type-membership-downgrade-fee.api.model';

export class CardTypeApiModel {
  @Expose()
  id: string;

  @Expose()
  name: string;

  @Expose()
  description: string;

  @Expose()
  multipleUse: boolean;

  @Expose()
  rechargeable: boolean;

  @Expose()
  expirationDate: string;

  @Expose()
  expirationType: number;

  @Expose()
  daysFromIssueToExpiration: number;

  @Expose()
  flgEndOfMonth: boolean;

  @Expose()
  monthsFromIssueToExpiration: number;

  @Expose()
  autoNumber: boolean;

  @Expose()
  issueFee: number;

  @Expose()
  replacementFee: number;

  @Expose()
  maximumBalance: number;

  @Expose()
  minTopUpValue: number;

  @Expose()
  maxTopUpValue: number;

  @Expose()
  graphic: string;

  @Expose()
  canBeIssued: boolean;

  @Expose()
  flgNeedPayment: boolean;

  @Expose()
  membershipNewFee: number;

  @Expose()
  reservationsAllowed: boolean;

  @Expose()
  reservationsPickUpExpiryTime: number;

  @Expose()
  earlierAccessToScheduleTime: number;

  @Expose()
  cinemaList: string[];

  @Expose()
  @Type(() => CardTypeTypeApiModel)
  types: CardTypeTypeApiModel[];

  @Expose()
  @Type(() => CardTypeValueApiModel)
  valueList: CardTypeValueApiModel[];

  @Expose()
  @Type(() => CardActiveDiscountApiModel)
  discountList: CardActiveDiscountApiModel[];

  @Expose()
  @Type(() => CardTypeGraphicTemplateApiModel)
  graphicTemplateList: CardTypeGraphicTemplateApiModel[];

  @Expose()
  @Type(() => CardFieldTemplateApiModel)
  fieldTemplateList: CardFieldTemplateApiModel[];

  @Expose()
  @Type(() => CardTypeMembershipExtensionFeeApiModel)
  membershipExtensionFeeList: CardTypeMembershipExtensionFeeApiModel[];

  @Expose()
  @Type(() => CardTypeMembershipUpgradeFeeApiModel)
  membershipUpgradeFeeList: CardTypeMembershipUpgradeFeeApiModel[];

  @Expose()
  @Type(() => CardTypeMembershipDowngradeFeeApiModel)
  membershipDowngradeFeeList: CardTypeMembershipDowngradeFeeApiModel[];

  @Expose()
  @Type(() => CardTypeBatchApiModel)
  batchList: CardTypeBatchApiModel[];
}
