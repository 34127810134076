import { Injectable } from '@angular/core';
import { iif, Observable, of, switchMap, tap } from 'rxjs';
import { WebComponentOptionsService } from '../webcomponent-options.service';
import { CmsHelper } from '../../helper/cms.helper';
import { WpOptionEnum } from 'libs/webcomponent/src/lib/enum/wp-option.enum';
import { getRandomHash, loadScript } from '../../function/custom-function';

declare var grecaptcha: any;

@Injectable({
  providedIn: 'root',
})
export class RecaptchaService {
  constructor(protected webComponentOptionsService: WebComponentOptionsService, protected cmsHelper: CmsHelper) {}

  getRecaptchaToken(action: string): Observable<string> {
    if (this.cmsHelper.canUseCms) {
      const useReCaptcha = (key) =>
        new Observable<string>((observer) => {
          grecaptcha
            .execute(key, { action })
            .then((token: string) => {
              observer.next(token);
              observer.complete();
            })
            .catch((error: any) => {
              observer.error('Error getting reCAPTCHA token: ' + error);
            });
        });

      const skip = () => of(null);
      const exists = (value): boolean => {
        return value && value.length > 0;
      };

      return this.webComponentOptionsService
        .getWpOption$(WpOptionEnum.RECAPTCHA_SITE_KEY)
        .pipe(switchMap((reCaptchaSiteKey) => iif(() => exists(reCaptchaSiteKey), useReCaptcha(reCaptchaSiteKey), skip())));
    } else {
      return of(null);
    }
  }

  registerScript(siteKey: string, locale: string) {
    if (siteKey) {
      let url = `https://www.google.com/recaptcha/api.js?render=${siteKey}`;
      if (locale) {
        url += `&hl=${locale}`;
      }

      loadScript(`g_rcap_${getRandomHash()}`, url, null, false);
    } else {
      console.error('Error: No siteKey provided for reCAPTCHA');
    }
  }
}
