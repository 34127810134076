export enum KEY_CODE {
  BACKSPACE = 'Backspace',
  TAB = 'Tab',
  SHIFT = 'Shift',
  ARROW_RIGHT = 'ArrowRight',
  ARROW_LEFT = 'ArrowLeft',
  ARROW_DOWN = 'ArrowDown',
  ARROW_UP = 'ArrowUp',
  DELETE = 'Delete',
}
